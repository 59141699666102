import React from 'react'
import LoginForm from './LoginForm'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const { t } = useTranslation();
  return (
    <div className="page page_simple">
      <div className="entry">
        <div className="entry__wrapper">
          <div className="heading-h2 text-color-white entry__title">{t('Sign In')}</div>
          <div className="entry__top">
            <div className="entry__text">{t('Login to your account')}</div>
          </div>

          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;

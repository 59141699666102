import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

const SelectAsync = ({
  selectRef,
  value,
  onChange,
  loadOptions,
  isSearchable,
  isClearable,
  name,
  additional,
  defaultValue = '',
  className = 'basic-single',
  classNamePrefix = 'select',
  debounceTimeout = 1000,
  noOptionsMessage = 'No options',
  isMulti = false,
}) => {
  return (
    <AsyncPaginate
      selectRef={selectRef}
      defaultOptions
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      isSearchable={isSearchable}
      isClearable={isClearable}
      className={className}
      classNamePrefix={classNamePrefix}
      name={name}
      isMulti={isMulti}
      defaultValue={defaultValue}
      additional={additional}
      debounceTimeout={debounceTimeout}
      noOptionsMessage={() => noOptionsMessage}
    />
  );
};

export { SelectAsync as default };

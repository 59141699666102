import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import ModalPopupCustom from '../../../components/ModalPopup/ModalPopup';
import {
  ADD_ACCESS_LEVEL_DEVICE,
  LIST_ACC_LEVEL_DEVICES,
  LIST_DEVICES,
  DELETE_DEVICE,
} from '../../../queriesAndMutations';
import { RESET_DEVICE, SYNC_USERS_FROM_DEVICE } from '../../../queriesAndMutations/personMutations';
import { toaster } from '../../../utils';
import ResetButton from '../../../components/ResetButton';

const AddDeviceToAccessLevel = ({
  modalAddDeviceIsOpen,
  handleCloseDeviceModal,
  currentAccessLevelId,
  refetch: listAccLevelDevicesRefetch,
}) => {
  const [getListAccLevelDevices, { data: { listAccLevelDevices = [] } = {}, refetch, loading }] =
    useLazyQuery(LIST_ACC_LEVEL_DEVICES, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (currentAccessLevelId) {
      getListAccLevelDevices({
        variables: {
          page: 0,
          accessLevelId: currentAccessLevelId,
        },
      });
    }

    //eslint-disable-next-line
  }, [currentAccessLevelId]);

  const {
    data: { listDevices = [] } = {},
    loading: listDevicesLoading,
    refetch: listDevicesRefetch,
  } = useQuery(LIST_DEVICES, {
    page: 0,
  });

  const [addAccessLevelDevice, addAccessLevelDeviceStatus] = useMutation(ADD_ACCESS_LEVEL_DEVICE);
  const [syncUsersFromDevice, syncUsersFromDeviceStatus] = useMutation(SYNC_USERS_FROM_DEVICE);
  const [resetDevice, resetDeviceStatus] = useMutation(RESET_DEVICE);
  const [deleteDevice, deleteDeviceStatus] = useMutation(DELETE_DEVICE);

  const availableDevices =
    listDevices &&
    listDevices.filter(
      (device) =>
        !listAccLevelDevices.find(
          (accDevice) =>
            accDevice.deviceId === device.id && accDevice.accessLevelId === currentAccessLevelId
        )
    );

  const addedDevices =
    listDevices &&
    listDevices.filter((device) =>
      listAccLevelDevices.find(
        (accDevice) =>
          accDevice.deviceId === device.id && accDevice.accessLevelId === currentAccessLevelId
      )
    );

  const handleResetDevice = async (devSN) => {
    try {
      await resetDevice({
        variables: {
          devSN,
        },
      });

      refetch();
      listDevicesRefetch();
      listAccLevelDevicesRefetch();
      toaster.success('Reset successfully');
    } catch (e) {
      toaster.error('Something went wrong');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const addDevice = async (deviceId) => {
    try {
      await addAccessLevelDevice({
        variables: {
          deviceId,
          accessLevelId: currentAccessLevelId,
        },
      });

      refetch();
      listDevicesRefetch();
      listAccLevelDevicesRefetch();
      toaster.success('Added successfully');
    } catch (e) {
      // toaster.error("Something went wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleDeleteDevice = async (deviceId) => {
    try {
      await deleteDevice({
        variables: {
          input: {
            id: deviceId,
          },
        },
      })
        .then(({ data: { deleteDevice: success } }) => {
          if (success) {
            toaster.success('Device deleted');
          } else {
            toaster.error('Delete device failed');
          }
        })
        .catch((e) => {
          toaster.error('Delete device failed');
          console.log('e', e);
        });

      refetch();
      listDevicesRefetch();
      listAccLevelDevicesRefetch();
    } catch (e) {
      toaster.error('Delete device failed');
      console.log(e);
    }
  };

  return (
    <ModalPopupCustom
      modalClass={`js-popup-sysadmin popup-medium ${
        modalAddDeviceIsOpen ? 'animation visible' : ''
      }`}
      modalTitle="Devices List"
      onClickClose={handleCloseDeviceModal}
    >
      <div className="label">Added Devices</div>
      <div className="table__wrapper">
        {/* Table Content Header */}
        <div className="table__row">
          <div className="table__col">Device SN</div>
          <div className="table__col">Device Name</div>
          <div className="table__col">Device Type</div>
          <div className="table__col">IP Address</div>
          <div className="table__col">Vendor Name</div>
          <div className="table__col">Action</div>
        </div>

        {/* Table Content */}
        {addedDevices &&
          addedDevices.map((device, index) => (
            <div className="table__row" key={index}>
              <div className="table__col">
                <div className="table__label">Device SN</div>
                {device.devSN}
              </div>

              <div className="table__col">
                <div className="table__label">Device Name</div>
                {device.devName}
              </div>

              <div className="table__col">
                <div className="table__label">Device Type</div>
                {device.deviceType}
              </div>

              <div className="table__col">
                <div className="table__label">IP Address</div>
                {device.devIP}
              </div>

              <div className="table__col">
                <div className="table__label">Vendor Name</div>
                {device.vendorName}
              </div>
              <div className="table__col">
                <div className="table__label">Action</div>
                <div className="table__buttons">
                  <ResetButton
                    handleResetDevice={() => handleResetDevice(device.devSN)}
                    loading={resetDeviceStatus.loading}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      <br />
      <div className="label">New Devices</div>
      <br />
      <div className="table__wrapper">
        {/* Table Content Header */}
        <div className="table__row">
          <div className="table__col">Device SN</div>
          <div className="table__col">Device Name</div>
          <div className="table__col">Device Type</div>
          <div className="table__col">IP Address</div>
          <div className="table__col">Vendor Name</div>
          <div className="table__col">Action</div>
        </div>

        {/* Table Content */}
        {availableDevices &&
          availableDevices.map((device, index) => (
            <div className="table__row" key={index}>
              <div className="table__col">
                <div className="table__label">Device SN</div>
                {device.devSN}
              </div>

              <div className="table__col">
                <div className="table__label">Device Name</div>
                {device.devName}
              </div>

              <div className="table__col">
                <div className="table__label">Device Type</div>
                {device.deviceType}
              </div>

              <div className="table__col">
                <div className="table__label">IP Address</div>
                {device.devIP}
              </div>

              <div className="table__col">
                <div className="table__label">Vendor Name</div>
                {device.vendorName}
              </div>
              <div className="table__col">
                <div className="table__label">Action</div>

                <div className="table__buttons">
                  <button
                    className="button button-small"
                    onClick={() => addDevice(device.id)}
                    disabled={addAccessLevelDeviceStatus.loading}
                  >
                    Add
                  </button>{' '}
                  <button
                    onClick={() => handleDeleteDevice(device.id)}
                    title="Delete device name"
                    data-tip="Delete device name"
                    className="button button-small settings__button--delete"
                    disabled={deleteDeviceStatus.loading}
                  >
                    Delete
                  </button>
                  {/* <button
                  data-tip="Reset devices from app"
                  className="button button-small table__buttons--reset"
                  onClick={() => handleResetDevice(device.devSN)}
                  disabled={resetDeviceStatus.loading}
                >
                  Reset
                </button> */}
                </div>
              </div>
            </div>
          ))}
      </div>
    </ModalPopupCustom>
  );
};

export default AddDeviceToAccessLevel;

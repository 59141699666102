import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import ButtonTheme from '../../../components/ButtonXid/Button'
import Icon from '../../../components/Icons/Icon'
import ModalPopup from '../../../components/ModalPopup/ModalPopup'
import CheckboxField from '../../../components/CheckboxField/CheckboxField'
import InputField from '../../../components/InputField/InputField'

const AssignAccess = () => {
  // Modal Assign Users
  const [modalAssignUser, setModalAssignUser] = useState(false)
  const handleOpenAssignUserModal = () => {
    setModalAssignUser(true)
  }
  const handleCloseAssignUserModal = () => {
    setModalAssignUser(false)
  }

  const dropdownTrade = [
    { id: 0, label: 'All'},
    { id: 1, label: 'Not Assigned'},
    { id: 2, label: 'Site Management Team'},
    { id: 3, label: 'Site Clearance & Mobilisation (ERSS/Excavation)'},
    { id: 4, label: 'Machine Operator (ERSS/Excavation)'},
    { id: 5, label: 'Demolition Works (ERSS/Excavation)'},
    { id: 6, label: 'Ground Improvement Works (ERSS/Excavation)'},
    { id: 7, label: 'Ground Freezing (ERSS/Excavation)'},
    { id: 8, label: 'Retaining Structures (ERSS/Excavation)'},
    { id: 9, label: 'Vertical Elements (ERSS/Excavation)'},
    { id: 10, label: 'Under-pinning Works (ERSS/Excavation)'},
    { id: 11, label: 'Formwork Installation (ERSS/Excavation)'},
    { id: 12, label: 'Steel Reinforcement Fixing (ERSS/Excavation)'},
    { id: 13, label: 'Concrete Placement (ERSS/Excavation)'},
    { id: 14, label: 'Waterproofing (ERSS/Excavation)'},
    { id: 15, label: 'Temporary Strutting/Sheet piling/Propping Works (ERSS/Excavation)'},
    { id: 16, label: 'Shotcreting Works (ERSS/Excavation)'},
    { id: 17, label: 'Mining Works (ERSS/Excavation)'},
    { id: 18, label: 'Micro-tunnelling (ERSS/Excavation)'},
    { id: 19, label: 'Recharge Well/Passive Relief Well (ERSS/Excavation)'},
    { id: 20, label: 'Rock Blasting (ERSS/Excavation)'},
    { id: 21, label: 'Excavation Work (ERSS/Excavation)'},
    { id: 22, label: 'Others (ERSS/Excavation)'},
    { id: 23, label: 'Machine Operator (Permanent Structure Works)'},
    { id: 24, label: 'Piling Works (Permanent Structure Works)'},
    { id: 25, label: 'Formwork Installation (Permanent Structure Works)'},
    { id: 26, label: 'Steel Reinforcement Fixing (Permanent Structure Works)'},
    { id: 27, label: 'Concrete Placement (Permanent Structure Works)'},
    { id: 28, label: 'Pre-stressing work (Permanent Structure Works)'},
    { id: 29, label: 'Precast structural concrete component installation (Permanent Structure Works)'},
    { id: 30, label: 'Structural Steel Work (Permanent Structure Works)'},
    { id: 31, label: 'Waterproofing (Permanent Structure Works)'},
    { id: 32, label: 'Viaduct Segment Casting (Permanent Structure Works)'},
    { id: 33, label: 'Viaduct Segment Handling (Permanent Structure Works)'},
    { id: 34, label: 'Viaduct Segment Launching (Permanent Structure Works)'},
    { id: 35, label: 'Viaduct Deck Furnitures (Permanent Structure Works)'},
    { id: 36, label: 'Others (Permanent Structure Works)'},
    { id: 37, label: 'TBM Operator (Bored Tunnelling Works)'},
    { id: 38, label: 'Ring Builder/grouter (including surface ops.) (Bored Tunnelling Works)'},
    { id: 39, label: 'Segment and service handling (Bored Tunnelling Works)'},
    { id: 40, label: 'Locomotive driver (Bored Tunnelling Works)'},
    { id: 41, label: 'Bridge/Mobile crane operator (Bored Tunnelling Works)'},
    { id: 42, label: 'Rigger/signal worker (Bored Tunnelling Works)'},
    { id: 43, label: 'Segment gasket installer (Bored Tunnelling Works)'},
    { id: 44, label: 'Mechanical and electrical workers (Bored Tunnelling Works)'},
    { id: 45, label: 'Storemen and general workers (Bored Tunnelling Works)'},
    { id: 46, label: 'Others (Bored Tunnelling Works)'},
    { id: 47, label: 'Waterproofing (General Work)'},
    { id: 48, label: 'Fire Stopping (General Work)'},
    { id: 49, label: 'Sprayed mineral fire protection (General Work)'},
    { id: 50, label: 'Concrete Finishes and precast concrete panels (Structural Carcass Work)'},
    { id: 51, label: 'Brick and Block Construction (Structural Carcass Work)'},
    { id: 52, label: 'Stone Masonry (Structural Carcass Work)'},
    { id: 53, label: 'Roofing (Enclosure Work)'},
    { id: 54, label: 'External Cladding (Enclosure Work)'},
    { id: 55, label: 'Stone Cladding (Enclosure Work)'},
    { id: 56, label: 'Glazing (Enclosure Work)'},
    { id: 57, label: 'Doors and Hatches (Enclosure Work)'},
    { id: 58, label: 'Roller Shutters and Grilles (Enclosure Work)'},
    { id: 59, label: 'Door and Window Hardware (Enclosure Work)'},
    { id: 60, label: 'Louvres (Enclosure Work)'},
    { id: 61, label: 'Internal Cladding (Enclosure Work)'},
    { id: 62, label: 'Vitreous Enamel Panels (Enclosure Work)'},
    { id: 63, label: 'Partitions (Enclosure Work)'},
    { id: 64, label: 'Suspended Ceiling (Finishing Work)'},
    { id: 65, label: 'Cementitous Toppings (Finishing Work)'},
    { id: 66, label: 'Granite Flooring (Finishing Work)'},
    { id: 67, label: 'Tactile Paving (Finishing Work)'},
    { id: 68, label: 'Access Floor (Finishing Work)'},
    { id: 69, label: 'Plastering (Finishing Work)'},
    { id: 70, label: 'Tiling (Finishing Work)'},
    { id: 71, label: 'Resilient Finishes (Finishing Work)'},
    { id: 72, label: 'Gratings (Finishing Work)'},
    { id: 73, label: 'Painting (Finishing Work)'},
    { id: 74, label: 'Metal Fixtures (Finishing Work)'},
    { id: 75, label: 'Furniture and Fittings (Commissioning Work)'},
    { id: 76, label: 'Signs, advertising and displays (Commissioning Work)'},
    { id: 77, label: 'Others (Commissioning Work)'},
    { id: 78, label: 'Plumbing and Sanitary (M&E Works)'},
    { id: 79, label: 'Fire Prevention and Protection System (M&E Works)'},
    { id: 80, label: 'Electrical system (M&E Works)'},
    { id: 81, label: 'ACMV/HVAC system (M&E Works)'},
    { id: 82, label: 'Lift /Escalator (M&E Works)'},
    { id: 83, label: 'Earth Mat/ Touch Voltage Protection (M&E Works)'},
    { id: 84, label: 'Cable ducts/pits (M&E Works)'},
    { id: 85, label: 'Waste water treatment (M&E Works)'},
    { id: 86, label: 'Others (M&E Works)'},
    { id: 87, label: 'Machine Operator (General)'},
    { id: 88, label: 'Quality Assurance (General)'},
    { id: 89, label: 'Scaffold (General)'},
    { id: 90, label: 'Safety and Health (General)'},
    { id: 91, label: 'Others (General)'},
    { id: 92, label: 'Trial Trench/Utility Detection (External Works)'},
    { id: 93, label: 'Underground Obstruction (External Works)'},
    { id: 94, label: 'Utilities Diversion (External Works)'},
    { id: 95, label: 'Drain Diversion/Drainage works (External Works)'},
    { id: 96, label: 'Traffic Diversion (External Works)'},
    { id: 97, label: 'External Paving (External Works)'},
    { id: 98, label: 'Roads and Driveways (External Works)'},
    { id: 99, label: 'Bus Shelters, Linkways, Taxi Stands, Drop-off Bays,Road Ancillaries (External Works)'},
    { id: 100, label: 'Fencing (External Works)'},
    { id: 101, label: 'Landscape (External Works)'},
    { id: 102, label: 'Others (External Works)'}
  ]

  const dropdownCompany = [
    { id: 1, label: 'All' },
    { id: 2, label: 'NONE' },
    { id: 3, label: 'Xid Technologies' },
  ]

  const dropdownUser = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Test account' },
    { id: 3, label: 'Test new' },
    { id: 4, label: 'Test srini xid' }
  ]

  return (
    <>
      {/* Table Content */}
      <div className="table__wrapper">
        {/* Table Content Header */}
        <div className="table__row">
          <div className="table__col">S.No</div>
          <div className="table__col">Access Name</div>
          <div className="table__col">Assigned Users</div>
          <div className="table__col">Actions</div>
        </div>

        {/* Table Content Inner */}
        <div className="table__row">
          <div className="table__col">
            <div className="table__label">S.No</div>
            1
          </div>

          <div className="table__col">
            <div className="table__label">Access Name</div>
            Card Only
          </div>

          <div className="table__col">
            <div className="table__label">Assigned Users</div>
            -
          </div>

          <div className="table__col">
            <div className="table__label">Actions</div>

            <div className="table__control">
              <button
                className="table__button"
                data-tip="Assign Access"
                data-for="table-assign-access-1"
                onClick={handleOpenAssignUserModal}
              >
                <Icon className="user" name="user" />
              </button>
            </div>
            <ReactTooltip
              id="table-assign-access-1"
            />
          </div>
        </div>

        <div className="table__row">
          <div className="table__col">
            <div className="table__label">S.No</div>
            2
          </div>

          <div className="table__col">
            <div className="table__label">Access Name</div>
            Card + Finger
          </div>

          <div className="table__col">
            <div className="table__label">Assigned Users</div>
            -
          </div>

          <div className="table__col">
            <div className="table__label">Actions</div>

            <div className="table__control">
              <button
                className="table__button"
                data-tip="Assign Access"
                data-for="table-assign-access-2"
                onClick={handleOpenAssignUserModal}
              >
                <Icon className="user" name="user" />
              </button>
            </div>
            <ReactTooltip
              id="table-assign-access-2"
            />
          </div>
        </div>

        <div className="table__row">
          <div className="table__col">
            <div className="table__label">S.No</div>
            3
          </div>

          <div className="table__col">
            <div className="table__label">Access Name</div>
            Card + Pin
          </div>

          <div className="table__col">
            <div className="table__label">Assigned Users</div>
            -
          </div>

          <div className="table__col">
            <div className="table__label">Actions</div>

            <div className="table__control">
              <button
                className="table__button"
                data-tip="Assign Access"
                data-for="table-assign-access-3"
                onClick={handleOpenAssignUserModal}
              >
                <Icon className="user" name="user" />
              </button>
            </div>
            <ReactTooltip
              id="table-assign-access-3"
            />
          </div>
        </div>
      </div>

      {/* Table Footer */}
      <div className="table__foot">
        <ButtonTheme
          hasSpinnerLoading
          textLabel="Load More"
          classButton="button-small button-stroke has-loader"
        />
      </div>

      {/* Modal Assign Access */}
      <ModalPopup
        modalClass={`js-popup-sysadmin popup-wide ${modalAssignUser ? "animation visible" : ""}`}
        onClickClose={handleCloseAssignUserModal}
        modalFullSize={true}
      >
        <div className="h4 popup-wide__title">Search Users</div>

        <div className="settings__fieldset">
          <div className="field">
            <div className="field__label">
              Trade
            </div>

            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="trade"
                options={dropdownTrade}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">
              Company
            </div>

            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="company"
                options={dropdownCompany}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">
              User Name
            </div>

            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="username"
                options={dropdownUser}
              />
            </div>
          </div>

          <InputField
            propertyName="nocard"
            fieldName="text"
            fieldType="text"
            fieldLabel="Card No."
          />

          <button className="button button-small">Search</button>
          <div className="v-space-40">{' '}</div>
        </div>

        <div className="h4 popup-wide__title">Assign User to Card Only</div>
        {/* Table Content */}
        <div className="popup-wide__table">
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col is-vert-col">
                <CheckboxField />
              </div>
              <div className="table__col is-vert-col">Name</div>
              <div className="table__col is-vert-col">Card No.</div>
              <div className="table__col is-vert-col">Trade</div>
              <div className="table__col is-vert-col">Company</div>
              <div className="table__col is-vert-col≈">Current User Access</div>
            </div>

            {/* Table Content Inner */}
            <div className="table__row">
              <div className="table__col">
                <CheckboxField />
              </div>
              <div className="table__col">
                <div className="table__label">Name</div>
                TEST SRINI XID
              </div>
              <div className="table__col">
                <div className="table__label">Card No.</div>
                555
              </div>
              <div className="table__col">
                <div className="table__label">Trade</div>
                Site Management Team
              </div>
              <div className="table__col">
                <div className="table__label">Company</div>
                Xid Technologies
              </div>
              <div className="table__col">
                <div className="table__label">Current User Access</div>
                -
              </div>
            </div>

            <div className="table__row">
              <div className="table__col">
                <CheckboxField />
              </div>
              <div className="table__col">
                <div className="table__label">Name</div>
                Test Account
              </div>
              <div className="table__col">
                <div className="table__label">Card No.</div>
                2
              </div>
              <div className="table__col">
                <div className="table__label">Trade</div>
                Site Management Team
              </div>
              <div className="table__col">
                <div className="table__label">Company</div>
                -
              </div>
              <div className="table__col">
                <div className="table__label">Current User Access</div>
                -
              </div>
            </div>
            <div className="table__row">
              <div className="table__col">
                <CheckboxField />
              </div>
              <div className="table__col">
                <div className="table__label">Name</div>
                Test New
              </div>
              <div className="table__col">
                <div className="table__label">Card No.</div>
                999
              </div>
              <div className="table__col">
                <div className="table__label">Trade</div>
                Not Assigned
              </div>
              <div className="table__col">
                <div className="table__label">Company</div>
                -
              </div>
              <div className="table__col">
                <div className="table__label">Current User Access</div>
                -
              </div>
            </div>
          </div>

          {/* Table Footer */}
          <div className="table__foot has-buttons">
            <ButtonTheme
              textLabel="Back"
              classButton="button button-stroke button-small"
            />
            <ButtonTheme
              textLabel="Update"
              classButton="button button-small"
              hasChildrenIcon
            >
              <Icon className="arrow-right" name="arrow-right" />
            </ButtonTheme>
          </div>
        </div>
      </ModalPopup>
    </>
  )
}

export default AssignAccess

import React from 'react';
import InputField from '../../../components/InputField/InputField';
import { toaster } from '../../../utils';

const SiteInformation = ({ values, setValues }) => {
  // @TODO need to get from DB or environment variable
  const domain = 'xidtech.com';
  const hostname = window.location.hostname;
  const protocol = window.location.protocol;
  const tenantHost = hostname.replace(`.${domain}`, '');
  const async_url = `${protocol}//${tenantHost}-async.${domain}`;

  const handleClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(async_url).then(
      () => {
        toaster.success('Copied');
        /* Resolved - text copied to clipboard successfully */
      },
      () => {
        toaster.error('Action Failed');
        /* Rejected - text failed to copy to the clipboard */
      }
    );
  };

  return (
    <>
      <div className="settings__fieldset">
        <InputField
          propertyName="company_name"
          fieldName="company_name"
          fieldType="text"
          fieldLabel="Company Name"
          fieldPlaceholder="Insert Company Name..."
          state={values}
          setState={setValues}
        />
      </div>
      <div className="settings__fieldset">
        <InputField
          propertyName="async_url"
          fieldName="async_url"
          fieldType="text"
          fieldLabel="Async Url"
          fieldPlaceholder=""
          readOnly={true}
          state={{ async_url }}
          onClick={handleClick}
        />
      </div>
    </>
  );
};

export default SiteInformation;

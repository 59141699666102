import React from 'react';
import InputField from '../../../components/InputField/InputField';

const ProjectInformation = ({ values, setValues = () => {}, mode = 'edit' }) => {
  return (
    <>
      <div className="settings__project">
        <div className="settings__row ">
          {mode === 'edit' && (
            <InputField
              propertyName="id"
              fieldName="id"
              fieldType="text"
              fieldLabel="ID No."
              setState={setValues}
              state={values}
              readOnly={true}
            />
          )}
          <InputField
            propertyName="name"
            fieldName="name"
            fieldType="text"
            fieldLabel="Project Name"
            setState={setValues}
            state={values}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectInformation;

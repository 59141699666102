import React, { useState } from 'react'
import OutsideClickHandler from "react-outside-click-handler"

const DropdownCustom = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  label,
  isRequired,
  options,
  value,
  setValue,
  upBody,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };

  return (
    <div className={`field field-dropdown ${visible ? "is-active" : ""}`}>
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        {label && (
          <div className="field__label">
            {label}{" "}
            {isRequired && (
              <span className="text__red"> *</span>
            )}
          </div>
        )}
        <div className="field__wrap field__wrap-dropdown">
          <div
            className="dropdown__head"
            onClick={() => setVisible(!visible)}
          >
            <div className="dropdown__selection">{value}</div>
          </div>

          <div className={`dropdown__body ${upBody ? "up__body": ""}`}>
            {options.map((x, index) => (
              <div
                className={`dropdown__option ${x === value ? "selected" : ""}`}
                onClick={() => handleClick(x, index)}
                key={index}
              >
                {x}
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default DropdownCustom;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import Icon from '../../Icons/Icon';
import { useMe } from '../../../myHooks';
import { USER_RIGHT_TYPES } from '../../../constants/user';

const SETS = {
  main: [
    {
      title: 'Dashboard',
      url: '#',
      icon_name: 'home',
      id_tooltip: 'general-dashboard',
      hasChildren: true,
      children: [
        {
          title: 'Basic',
          url: '/dashboard-basic',
        },
        {
          title: 'Subcon',
          url: '/dashboard-subcon',
        },
        {
          title: 'Office',
          url: '/dashboard-office',
        },
        {
          title: 'LTA',
          url: '/dashboard-lta',
        },
        {
          title: 'MOM',
          url: '/dashboard-mom',
        },
      ],
    },
    {
      title: 'Users Management',
      url: '/users-management',
      icon_name: 'user',
      id_tooltip: 'general-users-management',
    },
    {
      title: 'Events Management',
      url: '/events-management',
      icon_name: 'ticket',
      id_tooltip: 'general-events-management',
    },
    // {
    //   title: 'Residents Management',
    //   url: '/residents-management',
    //   icon_name: 'grid',
    //   id_tooltip: 'general-residents-management',
    // },
    {
      title: 'Reports',
      url: '#',
      icon_name: 'donut-chart',
      id_tooltip: 'general-report',
      hasChildren: true,
      children: [
        {
          title: 'HDB Report',
          url: '/hdb-report',
        },
        {
          title: 'Time Attendance',
          url: '#',
          hasSubchildren: true,
          subchildren: [
            {
              title: 'Detailed Access',
              url: '/detailed-access',
            },
            // {
            //   title: 'Time of Arrival',
            //   url: '/time-of-arrival',
            // },
            // {
            //   title: 'Time of Depature',
            //   url: '/time-of-depature',
            // },
            {
              title: 'Daily Entry Exit Report',
              url: '/daily-entry-exit-report',
            },
            {
              title: 'Master List',
              url: '/master-list',
            },
            // {
            //   title: 'Lateness Report',
            //   url: '/lateness-report',
            // },
            // {
            //   title: 'Absent Report',
            //   url: '/absent-report',
            // },
            // {
            //   title: 'Overtime Report',
            //   url: '/overtime-report',
            // },
            // {
            //   title: 'Error Report',
            //   url: '/overtime-report',
            // },
            {
              title: 'Person Attendance Report',
              url: '/person-attendance-report',
            },
            {
              title: 'Date Attendance Report',
              url: '/date-attendance-report',
            },
          ],
        },
        {
          title: 'BCA Reports',
          url: '#',
          hasSubchildren: true,
          subchildren: [
            {
              title: 'EPSS Report',
              url: '/report-epss',
            },
            // {
            //   title: 'Door List',
            //   url: '/door-list',
            // },
            // {
            //   title: 'Passport Expiring Report',
            //   url: '/passport-expiring-report',
            // },
            {
              title: 'Work Permit Expiry Report',
              url: '/work-permit-expiry-report',
            },
            // {
            //   title: 'Daily worker present/absent',
            //   url: '/daily-worker-present',
            // },
            // {
            //   title: 'Working Hours Report',
            //   url: '/working-hours-report',
            // },
            // {
            //   title: 'Mancount Report (Subcon)',
            //   url: '/mancount-report-subcon',
            // },
            // {
            //   title: 'Mancount Report (Trade)',
            //   url: '/mancount-report-trade',
            // },
            // {
            //   title: 'Working Hours Report by Trade',
            //   url: '/working-hours-report-by-trade',
            // },
          ],
        },
        // {
        //   title: 'Attendance Reports',
        //   url: '/report-epss',
        // },
      ],
    },
    {
      title: 'Admin Management',
      url: '/admin-management',
      icon_name: 'profile-circle',
      id_tooltip: 'general-admin-management',
    },
    {
      title: 'Devices Management',
      url: '/devices-management',
      icon_name: 'tablet',
      id_tooltip: 'devices-management',
    },
    {
      title: 'Companies Management',
      url: '/companies-management',
      icon_name: 'store',
      id_tooltip: 'companies-management',
    },
    {
      title: 'Projects Management',
      url: '/projects-management',
      icon_name: 'store',
      id_tooltip: 'projects-management',
    },
    {
      title: 'Settings',
      url: '/settings',
      icon_name: 'setting',
      id_tooltip: 'menu-settings',
    },
    {
      title: 'Support',
      url: 'mailto:support@xidtechnologiespte.zendesk.com',
      icon_name: 'message',
      id_tooltip: 'general-support',
    },
  ],
  [USER_RIGHT_TYPES.COMMON]: [
    {
      title: 'Support',
      url: 'mailto:support@xidtechnologiespte.zendesk.com',
      icon_name: 'message',
      id_tooltip: 'general-support',
    },
  ],
};

const MenuNavigation = ({ handleSidebarActive, handleSidebarForce, handleSidebarForceActive }) => {
  const { data: { me: userData } = {} } = useMe();
  // Child level 2
  const [subChildMenuOpen, setChildSubMenuOpen] = useState(-1);
  const toggleChildMenu = (y) => setChildSubMenuOpen(subChildMenuOpen === y ? -1 : y);

  // Child level 1
  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x, setChildSubMenuOpen(-1));

  let mainMenu;
  if (userData.rights === USER_RIGHT_TYPES.COMMON) {
    mainMenu = SETS[userData.rights];
  } else {
    mainMenu = SETS.main;

    if (userData.rights !== USER_RIGHT_TYPES.GENERAL_ADMIN) {
      mainMenu = SETS.main.filter(
        (menu) => !['/admin-management', '/projects-management'].includes(menu.url)
      );
    }
  }

  // mainMenu = SETS.main;
  return (
    <div className="sidebar__menu">
      {mainMenu.map((item) => {
        if (item.hasChildren) {
          return (
            <div
              key={item.title}
              className={cn(
                'sidebar__item sidebar__item_dropdown sidebar__item_dropdown_one',
                subMenuOpen === item.title ? 'is-active wide' : ''
              )}
            >
              <button
                className="sidebar__head sidebar__head_one"
                onClick={() => {
                  toggleMenu(item.title);
                  handleSidebarForceActive();
                }}
                data-tip={item.title}
                data-for={item.id_tooltip}
                data-tooltip-place="right"
              >
                {item.icon_name && (
                  <Icon className={cn('icon', item.icon_name)} name={item.icon_name} />
                )}

                {item.title}

                <Icon className={cn('arrow-down', 'icon')} name="arrow-down" />
              </button>
              <ReactTooltip id={item.id_tooltip} className="sidebar-tooltip" />
              <div className={cn('sidebar__body sidebar__body_one')}>
                {/* Child Item */}
                {item.children.map((childItem) => {
                  if (!childItem) return <></>;

                  if (childItem.hasSubchildren) {
                    return (
                      <div
                        key={childItem.title}
                        className={cn(
                          'sidebar__item sidebar__item_dropdown sidebar__item_dropdown_two',
                          subChildMenuOpen === childItem.title ? 'is-active wide' : ''
                        )}
                      >
                        <span
                          className="sidebar__head sidebar__head_two"
                          onClick={() => {
                            toggleChildMenu(childItem.title);
                          }}
                        >
                          {childItem.title}

                          <Icon className={cn('arrow-down', 'icon')} name="arrow-down" />
                        </span>

                        <div className={cn('sidebar__body sidebar__body_two')}>
                          {/* Subchild Item */}
                          {childItem.subchildren.map((subChildItem) => {
                            return (
                              <div className="sidebar__link_wrap" key={subChildItem.title}>
                                <NavLink to={subChildItem.url} className="sidebar__link">
                                  {subChildItem.title}
                                </NavLink>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <NavLink
                      to={childItem.url}
                      key={childItem.title}
                      className="sidebar__link sidebar__link_one"
                    >
                      {childItem.title}
                    </NavLink>
                  );
                })}
              </div>
            </div>
          );
        }

        if (item.title === 'Support') {
          return (
            <React.Fragment key={item.title}>
              <a
                href={item.url}
                className={cn('sidebar__item')}
                data-tip={item.title}
                data-for={item.id_tooltip}
                data-tooltip-place="right"
              >
                {item.icon_name && (
                  <Icon className={cn('icon', item.icon_name)} name={item.icon_name} />
                )}
                {item.title}
              </a>

              <ReactTooltip id={item.id_tooltip} place="right" className="sidebar-tooltip" />
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={item.title}>
            <NavLink
              to={item.url}
              className={cn('sidebar__item')}
              data-tip={item.title}
              data-for={item.id_tooltip}
              data-tooltip-place="right"
              onClick={handleSidebarForce}
            >
              {item.icon_name && (
                <Icon className={cn('icon', item.icon_name)} name={item.icon_name} />
              )}

              {item.title}
            </NavLink>

            <ReactTooltip id={item.id_tooltip} place="right" className="sidebar-tooltip" />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default MenuNavigation;

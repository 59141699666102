import React from 'react'

const Texts = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Texts - Styles, Align, Weight, Size</h1>
      </div>

      <h4 className="ds__cards-title">Text Style</h4>
      <div className="ds__cards is-col-4">
        <div className="ds__card">
          <div className="ds__class is-purple">.text-style-italic</div>
          <div className="ds__card--inner">
            <div className="text-style-italic">
              This is some text inside of a div block.
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">.text-style-underlined</div>
          <div className="ds__card--inner">
            <div className="text-style-underlined">
              This is some text inside of a div block.
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">.text-style-nowrap</div>
          <div className="ds__card--inner">
            <div className="text-style-nowrap">
              This is some text inside of a div block.
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">.text-style-uppercase</div>
          <div className="ds__card--inner">
            <div className="text-style-uppercase">
              This is some text inside of a div block.
            </div>
          </div>
        </div>
      </div>

      <h4 className="ds__cards-title">Text Align</h4>
      <div className="ds__cards is-col-3">
        <div className="ds__card">
          <div className="ds__class is-purple">.text-align-left</div>
          <div className="ds__card--inner">
            <div className="text-align-left">
              This is some text inside of a div block.
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">.text-align-center</div>
          <div className="ds__card--inner">
            <div className="text-align-center">
              This is some text inside of a div block.
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">.text-align-right</div>
          <div className="ds__card--inner">
            <div className="text-align-right">
              This is some text inside of a div block.
            </div>
          </div>
        </div>
      </div>

      <h4 className="ds__cards-title">Text Weight</h4>
      <div className="ds__cards is-col-4">
        <div className="ds__card">
          <div className="ds__class is-purple">.text-weight-400</div>
          <div className="ds__card--inner">
            <div className="text-weight-400">
              This is some text inside of a div block.
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">.text-weight-500</div>
          <div className="ds__card--inner">
            <div className="text-weight-500">
              This is some text inside of a div block.
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">.text-weight-600</div>
          <div className="ds__card--inner">
            <div className="text-weight-600">
              This is some text inside of a div block.
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">.text-weight-700</div>
          <div className="ds__card--inner">
            <div className="text-weight-700">
              This is some text inside of a div block.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Texts

import React, { useState } from 'react';
import Icon from '../../Icons/Icon';
import LogoMinimal from '../../../images/logo-light.png';
import MenuNavigation from './MenuNavigation';
import { getFileUrl } from '../../../utils';
import { usePublicSettings } from '../../../myHooks';

const Sidebar = ({ handleSidebarInvisible, sidebarVisible }) => {
  // Handle Sidebar Active Deactive on Mobile
  const { data: { publicSettings = {} } = {} } = usePublicSettings();

  const [sidebarActive, setSidebarActive] = useState({
    isSidebarActive: false,
  });

  const handleSidebarActive = () => {
    setSidebarActive({ ...sidebarActive, isSidebarActive: !sidebarActive.isSidebarActive });
  };

  const handleSidebarForce = () => {
    setSidebarActive({ isSidebarActive: false });
  };

  const handleSidebarForceActive = () => {
    setSidebarActive({ isSidebarActive: true });
  };

  return (
    <>
      <div
        className={`sidebar ${sidebarActive.isSidebarActive ? 'is-active' : ''} ${
          sidebarVisible.isSidebarVisible ? 'visible' : ''
        }`}
      >
        <button
          className="sidebar__close"
          onClick={() => {
            // sidebarVisible();
            handleSidebarInvisible();
          }}
        >
          <Icon className="close" name="close" />{' '}
        </button>

        <a href="/" className="sidebar__logo">
          <img
            src={publicSettings.logo_path ? getFileUrl(publicSettings.logo_path) : LogoMinimal}
            alt="XID App"
          />
        </a>

        <div className="sidebar__toggle" onClick={handleSidebarForce}>
          <Icon className="close" name="close" />
        </div>

        <MenuNavigation
          handleSidebarActive={handleSidebarActive}
          handleSidebarForce={handleSidebarForce}
          handleSidebarForceActive={handleSidebarForceActive}
        />
      </div>
      <div
        className={`overlay sidebar-overlay ${sidebarActive.isSidebarActive ? 'is-active' : ''}`}
        onClick={handleSidebarForce}
      >
        {' '}
      </div>
    </>
  );
};

export default Sidebar;

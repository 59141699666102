import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import { makeStyles, CircularProgress } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useLazyQuery } from '@apollo/react-hooks';
import client from '../../apolloClient';
import ReactExport from 'react-export-excel';
import ReactTooltip from 'react-tooltip';
import { get, find } from 'lodash';
import Icon from '../../components/Icons/Icon';
import InputField from '../../components/InputField/InputField';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import { GET_PERSON_LIST, GET_TRADE_LIST } from '../../queriesAndMutations/personQuery';
import { GET_COMPANY_LIST, GET_PROJECT_LIST } from '../../queriesAndMutations';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import SpinnerLoading from '../../components/SpinnerLoading/SpinnerLoading';
import SelectAsync from '../../components/SelectAsync';
import { MASTER_LIST_EVENT_MODES } from '../../constants/report';
import { findByValue } from '../../utils';
import { useMe } from '../../myHooks';
import { USER_RIGHT_TYPES } from '../../constants/user';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const MasterListReport = () => {
  const { state } = useLocation();
  const masterMode = get(state, 'mode', MASTER_LIST_EVENT_MODES.ALL);
  const dateFrom = get(state, 'dateFrom', null);
  const dateTo = get(state, 'dateTo', null);
  const tradeRef = useRef(null);
  const nameRef = useRef(null);
  const companyRef = useRef(null);
  const [entryDate, setEntryDate] = useState(new Date());
  const [forceReloadSelect, setForceReloadSelect] = useState(false);
  const [getPersonList, { data: { getPersonList: data } = {}, refetch, loading }] = useLazyQuery(
    GET_PERSON_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [getTradeList, { data: { getTradeList: tradeList } = {} }] = useLazyQuery(GET_TRADE_LIST, {
    fetchPolicy: 'network-only',
  });
  const [
    getCompanyList,
    { data: { getCompanyList: companyListData } = {}, loading: loadingCompanyList },
  ] = useLazyQuery(GET_COMPANY_LIST, {
    fetchPolicy: 'network-only',
  });
  const [getProjectList, { data: { getProjectList: projectListData } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const [companyList, setCompanyList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [trades, setTrades] = useState([]);
  const [masterList, setMasterList] = useState([]);
  const [exportedMasterList, setExportedMasterList] = useState([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isShowDownload, setIsShowDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [exportedPage, setExportedPage] = useState(0);
  const classes = useStyles();

  const {
    data: { me: user },
  } = useMe();

  const INIT_FILTERS = {
    idCard: '',
    pin: '',
    userName: '',
    tradeId: '',
    companyId: '',
    projectId: get(state, 'projectId', null),
    dateFrom,
    dateTo,
  };
  const [filters, setFilters] = useState({ ...INIT_FILTERS });

  const formatFilter = (filters) => {
    return Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== '' || filters[key] === undefined || filters[key] === null) {
        if (
          (key === 'entryDate' || key === 'dateFrom' || key === 'dateTo') &&
          filters[key] !== null
        ) {
          acc[key] = moment(filters[key]).format('MM/DD/YYYY');
        } else {
          acc[key] = filters[key];
        }
      }
      return acc;
    }, {});
  };

  const handleOpenModalCSV = () => {
    setIsShowDownload(true);
  };

  const handleCloseModalCSV = () => {
    setIsShowDownload(false);
  };

  const onExportTransactionList = () => {
    setExportedMasterList([]);
    handleOpenModalCSV();
    setIsLoading(true);
    /*eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]*/
    for (let i = 0; i < totalPages; i++) {
      (function (index) {
        setTimeout(() => {
          getPersonList({
            variables: {
              page: index,
              pageSize: 100,
              ...formatFilter({ ...filters, typeMasterList: masterMode }),
            },
          });
          setExportedPage(index);
        }, 2000 * index); // Multiply by index to stagger the timeouts
      })(i);
    }
  };

  const onResetFilters = () => {
    setMasterList([]);
    refetch();
    setCanLoadMore(true);
    getPersonList({
      variables: {
        page: 0,
        ...formatFilter({
          ...INIT_FILTERS,
          typeMasterList: '',
          projectId: null,
          dateFrom: null,
          dateTo: null,
        }),
      },
    });
    if (tradeRef && tradeRef.current) {
      tradeRef.current.clearValue();
    }
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }
    if (companyRef && companyRef.current) {
      companyRef.current.clearValue();
    }

    setFilters({
      ...INIT_FILTERS,
      typeMasterList: '',
      projectId: null,
      dateFrom: null,
      dateTo: null,
    });
  };

  const onClickSearch = () => {
    setFilters({ ...filters, typeMasterList: '', dateFrom: null, dateTo: null });
    setMasterList([]);
    setCanLoadMore(true);
    if (page === 0) {
      getPersonList({
        variables: {
          page,
          ...formatFilter({ ...filters, typeMasterList: '', dateFrom: null, dateTo: null }),
        },
      });
    } else {
      setPage(1);
    }
  };

  const personListLoadOptions = async (search, loadedOptions, { page }) => {
    if (search) {
      const searchResult = find(loadedOptions, (option) => {
        if (
          option.data.label.toLowerCase().includes(search.toLowerCase()) ||
          option.data.value.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (searchResult) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page,
          },
        };
      }
    }
    const result = await client.query({
      query: GET_PERSON_LIST,
      variables: {
        page: search ? 0 : page,
        pageSize: 10,
        projectId: filters.projectId > 0 ? filters.projectId : null,
        userName: search,
      },
      fetchPolicy: 'network-only',
    });
    const newOptions = get(result, 'data.getPersonList.items', []).map((person) => ({
      value: person.userName,
      label: person.userName,
    }));
    const hasMore = get(result, 'data.getPersonList.totalPages', 0) > page;

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: search ? 1 : page + 1,
      },
    };
  };

  useEffect(() => {
    if (!trades || !trades.length) {
      getTradeList({
        variables: { page: 0 },
      });
    }

    if (!companyListData && getCompanyList) {
      getCompanyList({
        variables: {
          page: 0,
          keyWord: '',
        },
      });
    }

    if (!projectListData && getProjectList) {
      getProjectList();
    }

    if (!masterList || (Array.isArray(masterList) && !masterList.length)) {
      getPersonList({
        variables: {
          page: 0,
          typeMasterList: masterMode,
          projectId: filters.projectId,
          dateFrom,
          dateTo,
        },
      });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tradeList && Array.isArray(tradeList)) {
      const mappedTrades = tradeList.map((e) => {
        return {
          label: e.name,
          id: e.id,
        };
      });
      setTrades(mappedTrades);
    }
  }, [tradeList]);

  useEffect(() => {
    if (get(companyListData, 'items') && Array.isArray(companyListData.items)) {
      const mappedTrades = companyListData.items.map((company) => {
        return {
          value: company.id,
          label: company.companyName,
        };
      });
      setCompanyList(mappedTrades);
    }
  }, [companyListData]);

  useEffect(() => {
    if (projectListData) {
      setProjectList(
        projectListData.map((project) => ({ value: project.id, label: project.name }))
      );
    }
  }, [projectListData]);

  useEffect(() => {
    if (data && data.items && data.items.length) {
      if (!isShowDownload) {
        setMasterList([...masterList, ...data.items]);
      }
      setExportedMasterList([...exportedMasterList, ...data.items]);
    }

    if (data && data.items && data.items.length < 25) {
      setCanLoadMore(false);
    }

    if (data && data.totalPages) {
      setTotalPages(data.totalPages);
    }

    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (exportedPage === totalPages - 1) {
      setIsLoading(false);
    }
  }, [exportedPage, totalPages, exportedMasterList]);

  useEffect(() => {
    if (!loading && page) {
      getPersonList({
        variables: {
          page,
          ...formatFilter({ ...filters, typeMasterList: masterMode }),
        },
      });
    }

    if (data && (data.totalPages === 1 || page === data.totalPages - 1)) {
      setCanLoadMore(false);
    }

    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    setFilters({ ...filters, userName: '' });
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }

    setForceReloadSelect(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.projectId]);

  useEffect(() => {
    if (forceReloadSelect) {
      setTimeout(() => {
        setForceReloadSelect(false);
      }, 1000);
    }
  }, [forceReloadSelect]);

  return (
    <PageDashboard pageTitle="Master List Report">
      <ModalPopup
        modalClass={`js-popup-syadmin popup-small ${isShowDownload ? 'animation visible' : ''}`}
        modalTitle="Export To CSV"
        onClickClose={handleCloseModalCSV}
      >
        {isLoading ? (
          <div className={classes.circularProgressWrapper}>
            Please do not close this pop up.
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="settings__fieldset">
              <div className="title card__title">
                Your Excel is ready. Click here to download
                <ExcelFile
                  element={
                    <button
                      className="button-circle button-small"
                      data-tip="Export to Excel"
                      data-for="export-to-excel"
                    >
                      <Icon className="download" name="download" />{' '}
                    </button>
                  }
                >
                  <ExcelSheet data={exportedMasterList} name="Monthly-OnSite>">
                    <ExcelColumn label="User" value="userName" />
                    <ExcelColumn label="User PIN" value="pin" />
                    <ExcelColumn label="Card No." value="idCard" />
                    <ExcelColumn label="Trade" value="tradeName" />
                    <ExcelColumn label="Company" value="companyName" />
                  </ExcelSheet>
                </ExcelFile>
                <ReactTooltip id="export-to-excel" />
              </div>
            </div>
            <div className="popup__foot">
              <button className="js-popup-close popup__foot-cancel" onClick={handleCloseModalCSV}>
                Cancel
              </button>
            </div>
          </>
        )}
      </ModalPopup>
      <div className="report-management card">
        <div className="report-management__wrapper">
          <div className="report-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row">
                {/* <InputField
                  propertyName="userName"
                  fieldName="userName"
                  fieldType="text"
                  fieldLabel="Name"
                  state={filters}
                  setState={setFilters}
                /> */}

                <div className="field">
                  <div className="field__label">Name</div>

                  <div className="field__wrap">
                    {forceReloadSelect ? (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={true}
                        options={[]}
                      />
                    ) : (
                      <SelectAsync
                        selectRef={nameRef}
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue=""
                        name="userName"
                        onChange={(option) =>
                          setFilters({ ...filters, userName: get(option, 'value', '') })
                        }
                        loadOptions={personListLoadOptions}
                        additional={{
                          page: 0,
                        }}
                        noOptionsMessage="No users"
                      />
                    )}
                  </div>
                </div>

                {user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
                  <div className="field">
                    <div className="field__label">Project</div>

                    <div className="field__wrap">
                      <Select
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        name="projectId"
                        options={projectList}
                        value={findByValue(projectList, filters.projectId)}
                        onChange={(option) =>
                          setFilters({ ...filters, projectId: get(option, 'value', null) })
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="field">
                  <div className="field__label">Company</div>
                  <div className="field__wrap">
                    <Select
                      ref={companyRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      name="companyId"
                      options={companyList}
                      onChange={(option) =>
                        setFilters({ ...filters, companyId: option ? option.value : null })
                      }
                    />
                  </div>
                </div>
                <InputField
                  propertyName="pin"
                  fieldName="pin"
                  fieldType="text"
                  fieldLabel="User Id"
                  state={filters}
                  setState={setFilters}
                />
                <InputField
                  propertyName="idCard"
                  fieldName="idCard"
                  fieldType="text"
                  fieldLabel="Card No."
                  state={filters}
                  setState={setFilters}
                />

                <div className="field">
                  <div className="field__label">Trade</div>
                  <div className="field__wrap">
                    <Select
                      ref={tradeRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="tradeId"
                      id="tradeId"
                      options={trades}
                      onChange={(option) => {
                        if (option && option.id !== '') {
                          setFilters({ ...filters, tradeId: option.id });
                        } else {
                          setFilters({ ...filters, tradeId: '' });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="settings__btns is-end">
              <button
                className="button button-stroke button-small"
                onClick={onExportTransactionList}
              >
                Export To Excel
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-stroke button-small" onClick={onResetFilters}>
                Reset
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-small" onClick={onClickSearch}>
                Search
                <Icon className="icon-search" name="icon-search" />
              </button>
            </div>
          </div>

          <div className="v-space-40"> </div>
          <div className="v-space-40 ml-1">
            Page {get(data, 'pageIndex', 0) + 1} of {Math.max(get(data, 'totalPages', 1), 1)} |
            Found total {get(data, 'totalCount', 0)} records
          </div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S.No</div>
              <div className="table__col">Name</div>
              <div className="table__col">User PIN</div>
              <div className="table__col">Card No.</div>
              {/* <div className="table__col">Entry Date</div> */}
              {/* <div className="table__col">Exit Date</div> */}
              <div className="table__col">Trade</div>
              <div className="table__col">Company</div>
              {/* <div className="table__col">Actions</div> */}
            </div>

            {/* Table Content Body */}
            {masterList.map((trans, index) => (
              <div key={trans.id} className="table__row">
                <div className="table__col">
                  <div className="table__label">S.No</div>
                  {index + 1}
                </div>

                <div className="table__col">
                  <div className="table__label">Name</div>
                  {trans.userName ? trans.userName : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">User ID</div>
                  {trans.pin ? trans.pin : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Card No.</div>
                  {trans.idCard ? trans.idCard : ''}
                </div>

                {/* <div className="table__col">
                  <div className="table__label">Entry Date</div>
                  {trans && trans.entryDate
                    ? moment(trans.entryDate).utc(0).format('YYYY-MM-DD HH:mm:ss')
                    : 'Unknown Time'}
                </div> */}

                {/* <div className="table__col">
                  <div className="table__label">Exit Date</div>
                  {trans && trans.exitDate
                    ? moment(trans.exitDate).utc(0).format('YYYY-MM-DD HH:mm:ss')
                    : 'Unknown Time'}
                </div> */}

                <div className="table__col">
                  <div className="table__label">Trade</div>
                  {trans.tradeName ? trans.tradeName : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Company</div>
                  {trans.companyName ? trans.companyName : ''}
                </div>

                {/* <div className="table__col">
                  <div className="table__label">Actions</div>
                </div> */}
              </div>
            ))}
            {masterList && Array.isArray(masterList) && masterList.length ? (
              <></>
            ) : (
              <div className="table__row empty">
                <div className="table__col">No Records Available To Show</div>
              </div>
            )}
          </div>
          {canLoadMore && (
            <div className="table__foot text-center">
              <button
                type="button"
                className="button button-stroke has-loader"
                onClick={() => setPage(page + 1)}
              >
                {loading && <SpinnerLoading />}
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </PageDashboard>
  );
};

export default MasterListReport;

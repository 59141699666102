/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import 'react-datepicker/dist/react-datepicker.css';
// import DatePicker from 'react-datepicker';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import Icon from '../../components/Icons/Icon';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import InputField from '../../components/InputField/InputField';
// import CheckboxField from '../../components/CheckboxField/CheckboxField';
// import ButtonTheme from '../../components/ButtonXid/Button';
import DropdownCustom from '../../components/DropdownCustom/DropdownCustom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_USER, ACC_LEVEL_LIST, GET_PROJECT_LIST } from '../../queriesAndMutations';
import { USER_RIGHT_LISTS, USER_RIGHT_TYPES } from '../../constants/user';
import { toaster, findByLabel, findByValue } from '../../utils';
import { useMe } from '../../myHooks';

// InnerComponents
import AdminUsers from './Users/Users';
import AdminPublicHolidays from './PublicHolidays/PublicHolidays';
import AdminAssignAccess from './AssignAccess/AssignAccess';
import AdminRoles from './Roles/Roles';
import AdminDropdowns from './Dropdowns/Dropdowns';
import AdminShift from './Shifts/Shifts';

const AdminManagement = () => {
  const { data: { me: user } = {} } = useMe();
  const USER_INIT = {
    email: '',
    rights: USER_RIGHT_LISTS[0].label,
    password: '',
    password_confirm: '',
    project_id: '',
  };

  const adminManagementNavigation = [
    'Admin Users',
    // 'Roles',
    // 'Dropdown',
    // 'Shifts',
    // 'Public Holidays',
    // 'Assign Access',
  ];

  // Holidays Active
  const holidaysActiveDropdown = [
    { id: 1, label: 'Yes' },
    { id: 2, label: 'No' },
  ];

  const [activeAdminManagementNavigation, setActiveManagementNavigation] = useState(
    adminManagementNavigation[0]
  );
  const [startShiftDate, setStartShiftDate] = useState(new Date());
  const [endShiftDate, setEndShiftDate] = useState(new Date());
  const [startHolidayDate, setStartHolidayDate] = useState(new Date()); // Holiday Starts
  const [endHolidayDate, setEndHolidayDate] = useState(new Date()); // Holidays End
  const [modalAddNewShift, setModalNewShift] = useState(false);
  const [modalAddPublicHoliday, setModalPublicHoliday] = useState(false);
  const [modalAddDropdown, setModalAddDropdown] = useState(false);
  const [modalAddAdminUsers, setModalAddAdminUsers] = useState(false);
  const [newUser, setNewUser] = useState(USER_INIT);
  const [triggedRefetchUserList, setTriggedRefetchUserList] = useState(0);
  const ROLE_LISTS = [
    ...USER_RIGHT_LISTS,
    ...(user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN
      ? [
          {
            id: 'GENERAL_ADMIN',
            label: USER_RIGHT_TYPES.GENERAL_ADMIN,
          },
        ]
      : []),
  ];

  // const { data: { accLevelList = [] } = {}, loading: loadingAccLevelList } = useQuery(
  //   ACC_LEVEL_LIST,
  //   {
  //     fetchPolicy: 'cache-and-network',
  //   }
  // );
  // const accessLevelOptions = accLevelList
  //   ? accLevelList.map((accLevel) => ({ value: accLevel.id, label: accLevel.name }))
  //   : [];
  const { data: { getProjectList: projectList = [] } = {}, loading: loadingProjectList } = useQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const projectOptions = projectList
    ? projectList.map((project) => ({ value: project.id, label: project.name }))
    : [];

  // Modal Add Admin Users
  const [createUser] = useMutation(CREATE_USER);
  const handleOpenModalAddAdminUsers = () => {
    setModalAddAdminUsers(true);
  };

  const handleCloseModalAddAdminUsers = () => {
    setNewUser(USER_INIT);
    setModalAddAdminUsers(false);
  };
  // const availableAdminUser = [
  //   { id: 1, label: 'OCBC' },
  //   { id: 2, label: 'Admin' },
  // ];

  // Modal Add Dropdown
  const handleOpenModalAddDropdown = () => {
    setModalAddDropdown(true);
  };
  const handleCloseModalAddDropdown = () => {
    setModalAddDropdown(false);
  };

  // Modal Add Public Holiday
  const handleOpenModalAddPublicHoliday = () => {
    setModalPublicHoliday(true);
  };

  const handleCloseModalPublicHoliday = () => {
    setModalPublicHoliday(false);
  };

  // Modal Add New Shift
  const handleOpenModalAddNewShift = () => {
    setModalNewShift(true);
  };
  const handleCloseModalAddNewShift = () => {
    setModalNewShift(false);
  };

  const handleSaveUser = () => {
    createUser({
      variables: {
        input: {
          ...newUser,
        },
      },
    })
      .then(({ data: { createUser: success } }) => {
        if (success) {
          toaster.success('Created user success.');
          setModalAddAdminUsers(false);
          setNewUser(USER_INIT);
        } else {
          toaster.error('Created user failed.');
        }
        setTriggedRefetchUserList(triggedRefetchUserList + 1);
      })
      .catch((error) => {
        console.log(error);
        toaster.error('Created user failed.');
      });
  };

  return (
    <PageDashboard pageTitle="Admin Management">
      <div className="admin-management card">
        {/* Card Head */}
        <div className="card__head is-spacebetween">
          <div className="title card__title">
            {activeAdminManagementNavigation === adminManagementNavigation[0] && (
              <>
                Admin Users
                <button
                  className="button-circle button-small"
                  onClick={handleOpenModalAddAdminUsers}
                  data-tip="Add New Admin"
                  data-for="add-new-admin"
                >
                  <Icon className="plus" name="plus" />
                </button>
                <ReactTooltip id="add-new-admin" />
              </>
            )}
            {activeAdminManagementNavigation === adminManagementNavigation[1] && (
              <>
                Roles Management
                <button className="button-circle button-small" onClick={handleOpenModalAddDropdown}>
                  <Icon className="plus" name="plus" />
                </button>
              </>
            )}
            {activeAdminManagementNavigation === adminManagementNavigation[2] && (
              <>
                Dropdowns
                <button className="button-circle button-small" onClick={handleOpenModalAddDropdown}>
                  <Icon className="plus" name="plus" />
                </button>
              </>
            )}
            {activeAdminManagementNavigation === adminManagementNavigation[3] && (
              <>
                Shifts
                <button className="button-circle button-small" onClick={handleOpenModalAddNewShift}>
                  <Icon className="plus" name="plus" />
                </button>
              </>
            )}
            {activeAdminManagementNavigation === adminManagementNavigation[4] && (
              <>
                Public Holidays
                <button
                  className="button-circle button-small"
                  onClick={handleOpenModalAddPublicHoliday}
                >
                  <Icon className="plus" name="plus" />
                </button>
              </>
            )}
            {activeAdminManagementNavigation === adminManagementNavigation[5] && 'Assign Access'}
          </div>

          <div className="card__nav">
            {adminManagementNavigation.map((x, index) => (
              <React.Fragment key={index}>
                <button
                  className={`card__link ${
                    x === activeAdminManagementNavigation ? 'is-active' : ''
                  }`}
                  onClick={() => setActiveManagementNavigation(x)}
                >
                  {x}
                </button>
              </React.Fragment>
            ))}

            <DropdownCustom
              isRequired
              value={activeAdminManagementNavigation}
              setValue={setActiveManagementNavigation}
              options={adminManagementNavigation}
            />
          </div>
        </div>

        {/* Content */}
        <div className="admin-management__wrapper">
          {activeAdminManagementNavigation === adminManagementNavigation[0] && (
            <AdminUsers triggerRefetch={triggedRefetchUserList} />
          )}
          {activeAdminManagementNavigation === adminManagementNavigation[1] && <AdminRoles />}
          {activeAdminManagementNavigation === adminManagementNavigation[2] && <AdminDropdowns />}
          {activeAdminManagementNavigation === adminManagementNavigation[3] && <AdminShift />}
          {activeAdminManagementNavigation === adminManagementNavigation[4] && (
            <AdminPublicHolidays />
          )}
          {activeAdminManagementNavigation === adminManagementNavigation[5] && (
            <AdminAssignAccess />
          )}
          {/* {activeAdminManagementNavigation === adminManagementNavigation[6] && <AdminAddDropdown />} */}
        </div>

        {/* Modal Add Admin Users */}
        <ModalPopup
          modalClass={`js-popup-sysadmin popup-small ${
            modalAddAdminUsers ? 'animation visible' : ''
          }`}
          modalTitle="Add Admin User"
          onClickClose={handleCloseModalAddAdminUsers}
        >
          <div className="settings__fieldset">
            {/* <InputField
              propertyName="username"
              fieldName="username"
              fieldType="text"
              state="username"
              fieldLabel="Username"
            /> */}

            <InputField
              propertyName="email"
              fieldName="email"
              fieldType="email"
              fieldLabel="Email"
              state={newUser}
              setState={setNewUser}
            />

            <InputField
              propertyName="password"
              fieldName="password"
              fieldType="password"
              fieldLabel="Password"
              state={newUser}
              setState={setNewUser}
            />

            <InputField
              propertyName="password_confirm"
              fieldName="password_confirm"
              fieldType="password"
              fieldLabel="Password Confirm"
              state={newUser}
              setState={setNewUser}
            />

            <div className="field">
              <div className="field__label">Role</div>

              <div className="field__wrap">
                <Select
                  isSearchable
                  isClearable
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={findByLabel(ROLE_LISTS, newUser.rights) || ROLE_LISTS[0]}
                  name="rights"
                  options={ROLE_LISTS}
                  value={findByLabel(ROLE_LISTS, newUser.rights)}
                  onChange={(option) => setNewUser({ ...newUser, rights: option.label })}
                />
              </div>
            </div>

            {/* {newUser.rights === USER_RIGHT_TYPES.ADMIN && (
              <div className="field">
                <div className="field__label">Access Level</div>

                <div className="field__wrap">
                  <Select
                    isSearchable
                    isClearable
                    className="basic-single"
                    classNamePrefix="select"
                    cacheOptions
                    defaultOptions
                    name="accessLevelIds"
                    isLoading={loadingProjectList}
                    isDisabled={loadingProjectList}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'grey' : 'red',
                      }),
                      multiValue: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#1a1a1a',
                      }),
                      multiValueLabel: (baseStyles) => ({
                        ...baseStyles,
                        color: '#ffffff',
                        fontSize: '100%',
                        lineHeight: '85%',
                      }),
                      multiValueRemove: (baseStyles) => ({
                        ...baseStyles,
                        padding: '4px',
                        borderRadius: '50%',
                      }),
                    }}
                    value={
                      newUser.project_id
                        ? findByValue(projectOptions, newUser.project_id)
                        : undefined
                    }
                    defaultValue={
                      newUser.project_id
                        ? findByValue(projectOptions, newUser.project_id)
                        : undefined
                    }
                    onChange={(option) => setNewUser({ ...newUser, project_id: option.value })}
                    options={projectOptions}
                  />
                </div>
              </div>
            )} */}
            {(newUser.rights === USER_RIGHT_TYPES.ADMIN ||
              newUser.rights === USER_RIGHT_TYPES.GENERAL_ADMIN) && (
              <div className="field">
                <div className="field__label">Project</div>

                <div className="field__wrap">
                  <Select
                    isSearchable
                    isClearable
                    className="basic-single"
                    classNamePrefix="select"
                    cacheOptions
                    defaultOptions
                    name="projectId"
                    isLoading={loadingProjectList}
                    isDisabled={loadingProjectList}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'grey' : 'red',
                      }),
                      multiValue: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: '#1a1a1a',
                      }),
                      multiValueLabel: (baseStyles) => ({
                        ...baseStyles,
                        color: '#ffffff',
                        fontSize: '100%',
                        lineHeight: '85%',
                      }),
                      multiValueRemove: (baseStyles) => ({
                        ...baseStyles,
                        padding: '4px',
                        borderRadius: '50%',
                      }),
                    }}
                    value={
                      newUser.project_id
                        ? findByValue(projectOptions, newUser.project_id)
                        : undefined
                    }
                    defaultValue={
                      newUser.project_id
                        ? findByValue(projectOptions, newUser.project_id)
                        : undefined
                    }
                    onChange={(option) => setNewUser({ ...newUser, project_id: option.value })}
                    options={projectOptions}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="popup__foot">
            <button
              className="js-popup-close popup__foot-cancel"
              onClick={handleCloseModalAddAdminUsers}
            >
              Cancel
            </button>
            <button className="button button-small" onClick={handleSaveUser}>
              Save
              <Icon className="arrow-right" name="arrow-right" />
            </button>
          </div>
        </ModalPopup>

        {/* Modal Add Dropdown */}
        <ModalPopup
          modalClass={`js-popup-syadmin popup-small ${modalAddDropdown ? 'animation visible' : ''}`}
          modalTitle="Add Dropdown"
          onClickClose={handleCloseModalAddDropdown}
        >
          <div className="settings__fieldset">
            <InputField
              propertyName="dropdownlist"
              fieldName="dropdownlist"
              fieldType="text"
              disabled={true}
              state="Dropdown Content"
              fieldLabel="Dropdown List"
            />

            <InputField
              propertyName="displayName"
              fieldName="displayName"
              fieldType="text"
              fieldLabel="Display Name"
            />
          </div>
          <div className="popup__foot">
            <button
              className="js-popup-close popup__foot-cancel"
              onClick={handleCloseModalAddDropdown}
            >
              Cancel
            </button>
            <button className="button button-small">
              Save
              <Icon className="arrow-right" name="arrow-right" />
            </button>
          </div>
        </ModalPopup>

        {/* Modal Add Public Holiday */}
        {/* <ModalPopup
          modalClass={`js-popup-sysadmin popup-small ${
            modalAddPublicHoliday ? 'animation visible' : ''
          }`}
          modalTitle="Add Public Holiday"
          onClickClose={handleCloseModalPublicHoliday}
        >
          <div className="settings__fieldset">
            <InputField
              propertyName="holidayname"
              fieldName="holidayname"
              fieldType="text"
              fieldLabel="Holiday Name"
            />

            <div className="field">
              <div className="field__label">Holidays Starts</div>
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={startHolidayDate}
                  onChange={(date) => setStartHolidayDate(date)}
                />
              </div>
            </div>

            <div className="field">
              <div className="field__label">Holidays End</div>
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={endHolidayDate}
                  onChange={(date) => setEndHolidayDate(date)}
                />
              </div>
            </div>

            <div className="field">
              <div className="field__label">Holidays Active</div>
              <div className="field__wrap">
                <Select
                  isSearchable
                  isClearable
                  className="basic-single"
                  classNamePrefix="select"
                  name="role"
                  options={holidaysActiveDropdown}
                />
              </div>
            </div>
          </div>
          <div className="popup__foot">
            <button
              className="js-popup-close popup__foot-cancel"
              onClick={() => setModalPublicHoliday(false)}
            >
              {' '}
              Cancel{' '}
            </button>
            <button className="button button-small">
              Save
              <Icon className="arrow-right" name="arrow-right" />
            </button>
          </div>
        </ModalPopup> */}

        {/* Modal Add New Shift */}
        {/* <ModalPopup
          modalClass={`js-popup-sysadmin popup-wide ${modalAddNewShift ? 'animation visible' : ''}`}
          onClickClose={handleCloseModalAddNewShift}
          modalFullSize={true}
        >
          <div className="heading-h4 popup-wide__title">Add Shift</div>

          <div className="settings__fieldset">
            <InputField
              propertyName="shiftname"
              fieldName="shiftname"
              fieldType="text"
              fieldLabel="Shift Name"
            />

            <InputField
              propertyName="shiftcode"
              fieldName="text"
              fieldType="text"
              fieldLabel="Shift Code"
            />
          </div>
          <div className="v-space-40"> </div>

          <div className="admin-shift__wrapper">
            <div className="admin-shift__table">
              <div className="admin-shift__head">
                <div className="admin-shift__col flex">Day</div>

                <div className="admin-shift__col">
                  Working Hours
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">Start</div>
                    <div className="admin-shift__subcol">End</div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  Lunch/Dinner Hours
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">Start</div>
                    <div className="admin-shift__subcol">End</div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  Over Time
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol">OT 1.0</div>
                    <div className="admin-shift__subcol">OT 1.5</div>
                    <div className="admin-shift__subcol">OT 2.0</div>
                    <div className="admin-shift__subcol">OT 3.0</div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">Monday</div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.5</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 2.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 3.0</div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">Tuesday</div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.5</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 2.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 3.0</div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">Wednesday</div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.5</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 2.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 3.0</div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">Thursday</div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.5</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 2.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 3.0</div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">Friday</div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.5</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 2.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 3.0</div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">Saturday</div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.5</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 2.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 3.0</div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">Sunday</div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.5</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 2.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 3.0</div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">Public Holiday</div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">Start</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">End</div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 1.5</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 2.0</div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">OT 3.0</div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="v-space-40"> </div>

          <div className="h4 popup-wide__title">Additional Information</div>
          <div className="settings__fieldset">
            <InputField
              propertyName="latenessgraceperiod"
              fieldName="latenessgraceperiod"
              fieldType="text"
              fieldLabel="Lateness Grace Period"
              fieldHelperText="(In Mins)"
            />

            <InputField
              propertyName="mintoworktogetovertime"
              fieldName="mintoworktogetovertime"
              fieldType="text"
              fieldLabel="Min to Work get Overtime"
              fieldHelperText="(In Mins)"
            />
          </div>

          <div className="v-space-40"> </div>
          <div className="admin-management__foot has-buttons">
            <ButtonTheme textLabel="Cancel" classButton="button button-stroke button-small" />
            <ButtonTheme textLabel="Save" classButton="button button-small" hasChildrenIcon>
              <Icon className="arrow-right" name="arrow-right" />
            </ButtonTheme>
          </div>
        </ModalPopup> */}
      </div>
    </PageDashboard>
  );
};

export default AdminManagement;

import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import Icon from '../../../components/Icons/Icon'
import InputField from '../../../components/InputField/InputField'
import ModalPopup from '../../../components/ModalPopup/ModalPopup'

const Dropdowns = () => {
  const dropdownLists = [
    { id: 1, label: 'Entry Point List' },
    { id: 2, label: 'Sponsor' },
    { id: 3, label: 'Skill Levels' },
    { id: 4, label: 'WP Category' },
    { id: 5, label: 'User Role List' },
    { id: 6, label: 'Designation List' },
  ]

  // Modal Edit
  const [modalEditDropdowns, setModalEditDropdowns] = useState(false)
  const handleOpenEditModal = () => {
    setModalEditDropdowns(true)
  }
  const handleCloseEditModal = () => {
    setModalEditDropdowns(false)
  }

  return (
    <>
      <div className="admin-management__fieldset">
        <div className="settings__fieldset">
          <div className="field field-flex">
            <div className="field__label">Dropdown List</div>

            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="role"
                options={dropdownLists}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="admin-management__list settings__list">
        <div className="settings__line">
          <div className="settings__label">
            Device-1 ( OSG7030067030800008 )
          </div>

          <div className="settings__right">
            <div className="settings__control">
              <button
                className="settings__control-button"
                data-tip="hide"
                data-for="admin-hide-1"
              >
                <Icon className="photos" name="photos" />
              </button>
              <button
                className="settings__control-button"
                data-tip="Edit"
                data-for="admin-edit-1"
                onClick={handleOpenEditModal}
              >
                <Icon className="edit" name="edit" />
              </button>
            </div>
            <ReactTooltip
              id="admin-hide-1"
            />
            <ReactTooltip
              id="admin-edit-1"
            />
          </div>
        </div>

        <div className="settings__line">
          <div className="settings__label">
            Handheld ( handheld )
          </div>

          <div className="settings__right">
            <div className="settings__control">
              <button
                className="settings__control-button"
                data-tip="hide"
                data-for="admin-hide-2"
              >
                <Icon className="photos" name="photos" />
              </button>
              <button
                className="settings__control-button"
                data-tip="Edit"
                data-for="admin-edit-2"
                onClick={handleCloseEditModal}
              >
                <Icon className="edit" name="edit" />
              </button>
            </div>
            <ReactTooltip
              id="admin-hide-2"
            />
            <ReactTooltip
              id="admin-edit-2"
            />
          </div>
        </div>

        <div className="settings__line">
          <div className="settings__label">
            Door 10 ( 10 )
          </div>

          <div className="settings__right">
            <div className="settings__control">
              <button
                className="settings__control-button"
                data-tip="show"
                data-for="admin-show-3"
              >
                <Icon className="lightning" name="lightning" />
              </button>
              <button
                className="settings__control-button"
                data-tip="Edit"
                data-for="admin-edit-3"
                onClick={handleCloseEditModal}
              >
                <Icon className="edit" name="edit" />
              </button>
            </div>
            <ReactTooltip
              id="admin-show-3"
            />
            <ReactTooltip
              id="admin-show-3"
            />
          </div>
        </div>

      </div>

      <ModalPopup
        modalClass={`js-popup-sysadmin popup-small ${modalEditDropdowns ? "animation visible" :""}`}
        modalTitle="Edit Dropdown"
        onClickClose={handleCloseEditModal}
      >
        <div className="settings__fieldset">
          <InputField
            propertyName="dropdownlist"
            fieldName="dropdownlist"
            fieldType="text"
            disabled={true}
            state="Dropdown Content"
            fieldLabel='Dropdown List'
          />

          <InputField
            propertyName="displayName"
            fieldName="displayname"
            fieldType="text"
            fieldLabel="Display Name"
          />
        </div>

        <div className="popup__foot">
          <button>Cancel</button>
          <button className="button button-small">
            Save
            <Icon className="arrow-right" name="arrow-right" />
          </button>
        </div>

      </ModalPopup>
    </>
  )
}

export default Dropdowns

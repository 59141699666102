import React from 'react';
import cn from 'classnames';
import Icon from '../Icons/Icon';

const InputField = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  hasIcon,
  iconName = '',
  isRequired,
  fieldLabel = '',
  fieldType = 'text',
  fieldName = '',
  fieldPlaceholder = '',
  fieldHelperText,
  disabled,
  readOnly = false,
  error = [],
  loading = false,
  state,
  setState,
  onError,
  onClick = () => {},
} = {}) => {
  const handleChange = (event) => {
    let newValue = event.target.value;
    // if (formatData) {
    //   newValue = formatData(newValue)
    // }
    if (fieldType === 'textNumber' && !/^[1-9]\d*$/.test(newValue)) {
      if (newValue) {
        newValue = parseInt(newValue);
        if (newValue === 0) {
          newValue = '';
        }
        newValue = newValue.toString();
      }
    }

    if (setState) {
      setState({ ...state, [propertyName]: newValue });
    }
  };

  const validateKey = (event) => {
    if (event.key !== 'Backspace') {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  return (
    <div className={cn('field', { field_icon: hasIcon })} key={propertyName}>
      {/* Label */}
      {fieldLabel && (
        <div className="field__label">
          {fieldLabel} {isRequired && <span className="text-red"> *</span>}
        </div>
      )}

      {/* Input */}
      <div className="field__wrap">
        {fieldType === 'textarea' ? (
          <textarea
            value={state[propertyName] || ''}
            onChange={handleChange}
            name={fieldName}
            placeholder={fieldPlaceholder}
            disabled={disabled || loading}
          />
        ) : fieldType === 'textNumber' ? (
          <input
            className="field__input"
            type="text"
            min="0"
            step="1"
            name={fieldName}
            onChange={handleChange}
            onKeyDown={validateKey}
            placeholder={fieldPlaceholder}
            disabled={disabled || loading}
            value={state && fieldName ? state[fieldName] : ''}
            readOnly={readOnly || loading}
            onClick={onClick}
          />
        ) : (
          <input
            className="field__input"
            type={fieldType || 'text'}
            name={fieldName}
            onChange={handleChange}
            placeholder={fieldPlaceholder}
            disabled={disabled || loading}
            value={state && fieldName ? state[fieldName] : ''}
            readOnly={readOnly || loading}
            onClick={onClick}
          />
        )}

        {hasIcon && (
          <div className="field__icon">
            <Icon className={iconName} name={iconName} />
          </div>
        )}

        {fieldHelperText && <div className="field__helper">{fieldHelperText}</div>}
      </div>
    </div>
  );
};

export { InputField as default };

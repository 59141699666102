import React, { useState } from 'react'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import Icon from '../../components/Icons/Icon'
import InputField from '../../components/InputField/InputField'
import PageDashboard from '../../components/PageDashboard/PageDashboard'

const TimeOfArrival = () => {
  const availableName = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Test Account' },
    { id: 3, label: 'Test new' },
    { id: 4, label: 'Test srini xid'}
  ]

  const availableType = [
    { id: 1, label: 'All' },
    { id: 2, label: 'In' },
    { id: 3, label: 'Out' }
  ]

  const availabelResult = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Accept' },
    { id: 3, label: 'Reject' },
    { id: 4, label: 'Expired' }
  ]

  const availableCompany = [
    { id: 1, label: 'All' },
    { id: 2, label: 'None' },
    { id: 3, label: 'Xid Technologies'}
  ]

  // Access Date
  const [accessDateFrom, setAccessDateFrom] = useState(new Date())
  const [accessDateTo, setAccessDateTo] = useState(new Date())

  return (
    <PageDashboard pageTitle="Time Of Arrival">
      <div className="report-management card">
        <div className="report-management__wrapper">
          <div className="report-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row is-col-3">
                <div className="field">
                  <div className="field__label">Name</div>

                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="entry"
                      options={availableName}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Type</div>
                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="entry"
                      options={availableType}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Result</div>
                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="entry"
                      options={availabelResult}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Date From</div>
                  <div className="field__wrap">
                    <DatePicker
                      showIcon
                      selected={accessDateFrom}
                      onChange={(date) => setAccessDateFrom(date)}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Date To</div>
                  <div className="field__wrap">
                    <DatePicker
                      showIcon
                      selected={accessDateTo}
                      onChange={(date) => setAccessDateTo(date)}
                    />
                  </div>
                </div>

                <InputField
                  propertyName="userid"
                  fieldName="userid"
                  fieldType="text"
                  fieldLabel='User ID'
                />
              </div>

              <div className="settings__btns is-end">
                <button className="button button-stroke button-small">
                  Reset
                  <Icon className="repeat" name="repeat" />
                </button>
                <button className="button button-small">
                  Search
                  <Icon className="icon-search" name="icon-search" />
                </button>
              </div>
            </div>
          </div>

          <div className="v-space-40">{' '}</div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S.No</div>
              <div className="table__col">Name</div>
              <div className="table__col">Access Type</div>
              <div className="table__col">Access Result</div>
              <div className="table__col">Access Date</div>
              <div className="table__col">Company</div>
              <div className="table__col">User ID</div>
              <div className="table__col">Actions</div>
            </div>

            {/* Table Content Body */}
            <div className="table__row empty">
              <div className="table__col">
                No Records Available To Show
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageDashboard>
  )
}

export default TimeOfArrival

import React from 'react';

const ResetButton = React.memo(function ResetButton({ handleResetDevice, loading }) {
  return (
    <button
      data-tip="Delete devices from app"
      title="Delete device from access level"
      className="button button-small table__buttons--reset-outline"
      onClick={handleResetDevice}
      disabled={loading}
    >
      Delete
    </button>
  );
});

export default ResetButton;

import React from 'react'
import PageDashboard from '../../components/PageDashboard/PageDashboard'

const OvertimeReport = () => {
  return (
    <PageDashboard pageTitle="Overtime Report">
      {' '}
    </PageDashboard>
  )
}

export default OvertimeReport

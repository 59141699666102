// import { useQuery } from '@apollo/react-hooks';
import React, { useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// import Select from 'react-select';
import Icon from '../../../components/Icons/Icon';
import InputField from '../../../components/InputField/InputField';
// import { ACC_LEVEL_LIST } from '../../../queriesAndMutations';
import finger from '../../../images/finger1.jpg';
import defaultAvatar from '../../../images/user.png';
import './styles.scss';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_USER_FACE, DELETE_USER_FINGERPRINT } from '../../../queriesAndMutations';
import { toaster } from '../../../utils';

const PersonalInformation = ({ values, setValues, setFileField, mode }) => {
  // const { data: { accLevelList = [] } = {}, loading } = useQuery(ACC_LEVEL_LIST, {
  //   fetchPolicy: 'network-only',
  // });
  // const [accessLevelValues, setAccessLevelValues] = useState('');
  const inputFile = useRef(null);

  // const onChangeAccessLevels = React.useCallback(
  //   (options) => {
  //     setValues({ ...values, accessLevelIds: options.map((item) => item.value) });
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [values]
  // );

  // const accessLevelOptions = accLevelList
  //   ? accLevelList.map((accLevel) => ({ value: accLevel.id, label: accLevel.name }))
  //   : [];

  // React.useEffect(() => {
  //   if (values.accessLevelIds && accLevelList && accLevelList.length > 0) {
  //     const accessLevels = values.accessLevelIds.map((id) => {
  //       const levelData = accLevelList.find((level) => level.id === id);
  //       if (levelData) {
  //         return {
  //           value: levelData.id,
  //           label: levelData.name,
  //         };
  //       }

  //       return {};
  //     });

  //     setAccessLevelValues(accessLevels);
  //   }
  // }, [values.accessLevelIds, accLevelList]);
  const [deleteUserFace, { loading: faceLoading, error: faceError }] =
    useMutation(DELETE_USER_FACE);
  const triggerDeleteUserFace = () => {
    deleteUserFace({
      variables: {
        input: {
          id: values?.id,
        },
      },
    })
      .then((response) => {
        if (response.data && response.data.deleteUserFace) {
          toaster.success('Successfully removing user face.');
          setTimeout(() => {
            window.location.reload();
          }, [1000]);
        }
      })
      .catch((e) => console.log(e));
  };

  const [deleteUserFingerprint, { loading: fingerLoading, error: fingerError }] =
    useMutation(DELETE_USER_FINGERPRINT);
  const triggerDeleteUserFingerprint = () => {
    deleteUserFingerprint({
      variables: {
        input: {
          id: values?.id,
          supplier: 0,
        },
      },
    })
      .then((response) => {
        if (response.data && response.data.deleteUserFingerprint) {
          toaster.success('Successfully removing user fingerprint.');
          setTimeout(() => {
            window.location.reload();
          }, [1000]);
        }
      })
      .catch((e) => console.log(e));
  };

  const triggerDeleteUserFingerprintHIK = () => {
    deleteUserFingerprint({
      variables: {
        input: {
          id: values?.id,
          supplier: 1,
        },
      },
    })
      .then((response) => {
        if (response.data && response.data.deleteUserFingerprint) {
          toaster.success('Successfully removing user fingerprint.');
          setTimeout(() => {
            window.location.reload();
          }, [1000]);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      {/* {mode !== 'view' && (
        <div className="settings__profile">
          <div className="settings__file">
            <input
              type="file"
              ref={inputFile}
              onChange={setFileField('personPhoto')}
              style={{ display: 'none' }}
            />
            {values.personPhoto && values.personPhoto.name}
            {!values.personPhoto && (
              <button
                className="button settings__button"
                type="button"
                onClick={() => inputFile.current.click()}
              >
                <Icon className="add" name="add" /> <span>Upload new picture</span>
              </button>
            )}
          </div>
          <button
            className="button-stroke settings__button"
            onClick={() => {
              inputFile.current.value = null;
              setValues({ ...values, personPhoto: '' });
            }}
          >
            Remove
          </button>
        </div>
      )} */}

      {mode === 'view' && (
        <>
          <div className="settings__fieldset flex flex-row" style={{ gap: '12px' }}>
            <img
              alt="avatar"
              src={
                values.photoBase64 ? `data:image/png;base64,${values.photoBase64}` : defaultAvatar
              }
              width={200}
            />
            {values.photoBase64 ? (
              <div className="flex justify-center items-center">
                <button className="button button-small" onClick={triggerDeleteUserFace}>
                  DELETE
                </button>
              </div>
            ) : (
              <></>
            )}
            <div className="settings__fieldset settings__profile">
              {values.fingerBase64 ? (
                <div className="finger-container">
                  <div>ZK finger</div>
                  <img alt="finger" src={finger} width={100} />
                </div>
              ) : (
                ''
              )}
            </div>
            {values.fingerBase64 ? (
              <div className="flex justify-center items-center">
                <button className="button button-small" onClick={triggerDeleteUserFingerprint}>
                  DELETE
                </button>
              </div>
            ) : (
              <></>
            )}
            <div className="settings__fieldset settings__profile">
              {values.fingerHIKBase64 ? (
                <div className="finger-container">
                  <div>HIK finger</div>
                  <img alt="finger" src={finger} width={100} />
                </div>
              ) : (
                ''
              )}
            </div>
            {values.fingerHIKBase64 ? (
              <div className="flex justify-center items-center">
                <button className="button button-small" onClick={triggerDeleteUserFingerprintHIK}>
                  DELETE
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
      <div className="settings__fieldset">
        <div className="settings__row ">
          <InputField
            propertyName="userName"
            fieldName="userName"
            fieldType="text"
            fieldLabel="User Name*"
            setState={setValues}
            state={values}
          />

          <InputField
            propertyName="idCard"
            fieldName="idCard"
            fieldType="textNumber"
            fieldLabel="Card No."
            setState={setValues}
            state={values}
          />

          <InputField
            ref={inputFile}
            propertyName="pin"
            fieldName="pin"
            fieldType="textNumber"
            fieldLabel="User ID*"
            readOnly={mode === 'view'}
            disabled={mode === 'view'}
            setState={setValues}
            state={values}
          />

          {/* <div className="field">
            <div className="field__label">Access Type</div>

            <div className="field__wrap">
              <Select
                isMulti
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                cacheOptions
                defaultOptions
                name="accessLevelIds"
                isLoading={loading}
                isDisabled={loading}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? 'grey' : 'red',
                  }),
                  multiValue: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: '#1a1a1a',
                  }),
                  multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    color: '#ffffff',
                    fontSize: '100%',
                    lineHeight: '85%',
                  }),
                  multiValueRemove: (baseStyles) => ({
                    ...baseStyles,
                    padding: '4px',
                    borderRadius: '50%',
                  }),
                }}
                value={accessLevelValues}
                defaultValue={accessLevelValues}
                onChange={onChangeAccessLevels}
                options={accessLevelOptions}
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;

import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import Icon from '../../../components/Icons/Icon'
import SpinnerLoading from '../../../components/SpinnerLoading/SpinnerLoading'
import CheckboxField from '../../../components/CheckboxField/CheckboxField'

const DSTables = () => {
  const [startShiftDate, setStartShiftDate] = useState(new Date())
  const [endShiftDate, setEndShiftDate] = useState(new Date())

  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Tables</h1>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Table Default</div>

        <div className="ds__card-inner">
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">Table Head 1</div>
              <div className="table__col">Table Head 2</div>
              <div className="table__col">Action Control</div>
            </div>

            {/* Table Content Inner */}
            <div className="table__row">

              <div className="table__col">
                <div className="table__label">Table Head 1</div>
                Content 1
              </div>

              <div className="table__col">
                <div className="table__label">Table Head 2</div>
                Content 2
              </div>
              <div className="table__col">
                <div className="table__label">Action Control</div>
                <div className="table__control">
                  <button
                    className="table__button"
                    data-tip="Delete"
                    data-for="table-admin-users-1"
                  >
                    <Icon className="trash" name="trash" />
                  </button>
                </div>
                <ReactTooltip
                  id="table-admin-users-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Table Empty</div>

        <div className="ds__card-inner">
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col text-align-center">Table Head 1</div>
              <div className="table__col text-align-center">Table Head 2</div>
              <div className="table__col text-align-center">Action Control</div>
            </div>

            {/* Table Content Inner */}
            <div className="table__row empty">
              <div className="table__col">
                No records
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Table Foot</div>

        <div className="ds__card-inner">
          <div className="table__wrapper">{' '}</div>
          <div className="table__foot text-center">
            <button type="button" className="button button-stroke has-loader">
              <SpinnerLoading />
              Load More
            </button>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Table Shift</div>

        <div className="ds__card-inner">
          <div className="admin-shift__wrapper">
            <div className="admin-shift__table">
              <div className="admin-shift__head">
                <div className="admin-shift__col flex">
                  Day
                </div>

                <div className="admin-shift__col">
                  Working Hours
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      Start
                    </div>
                    <div className="admin-shift__subcol">
                      End
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  Lunch/Dinner Hours
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      Start
                    </div>
                    <div className="admin-shift__subcol">
                      End
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  Over Time

                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol">
                      OT 1.0
                    </div>
                    <div className="admin-shift__subcol">
                      OT 1.5
                    </div>
                    <div className="admin-shift__subcol">
                      OT 2.0
                    </div>
                    <div className="admin-shift__subcol">
                      OT 3.0
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">
                  Monday
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.5
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 2.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 3.0
                      </div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">
                  Tuesday
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.5
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 2.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 3.0
                      </div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">
                  Wednesday
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.5
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 2.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 3.0
                      </div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">
                  Thursday
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.5
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 2.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 3.0
                      </div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">
                  Friday
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.5
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 2.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 3.0
                      </div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">
                  Saturday
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.5
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 2.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 3.0
                      </div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">
                  Sunday
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.5
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 2.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 3.0
                      </div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>

              <div className="admin-shift__row">
                <div className="admin-shift__col flex head">
                  Public Holiday
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={endShiftDate}
                            onChange={(date) => setEndShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols">
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          Start
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="admin-shift__subcol">
                      <div className="field">
                        <div className="field__label">
                          End
                        </div>
                        <div className="field__wrap">
                          <DatePicker
                            selected={startShiftDate}
                            onChange={(date) => setStartShiftDate(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-shift__col">
                  <div className="admin-shift__subcols cols-4">
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 1.5
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 2.0
                      </div>
                      <CheckboxField />
                    </div>
                    <div className="admin-shift__subcol flex">
                      <div className="field__label">
                        OT 3.0
                      </div>
                      <CheckboxField />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Table Add Device</div>

        <div className="ds__card-inner">
          <div className="add-device__wrapper">
            <div className="add-device__table">
              <div className="add-device__row is-head">
                <div className="add-device__col">
                  S.No.
                </div>
                <div className="add-device__col">
                  Device Name
                </div>
                <div className="add-device__col">
                  Function
                </div>
                <div className="add-device__col">
                  Serial No
                </div>
                <div className="add-device__col">
                  Status
                </div>
                <div className="add-device__col">
                  IP Address
                </div>
                <div className="add-device__col">
                  Update
                </div>
              </div>

              <div className="add-device__row">
                <div className="add-device__col">
                  <div className="add-device__label">
                    S.No.
                  </div>

                  1
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Device Name
                  </div>

                  BLK 5 Lane 1 IN Master
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Function
                  </div>

                  Master
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Serial No.
                  </div>
                  AJD6173560016
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Status
                  </div>

                  <div className="status-success">Working</div>
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    IP Address
                  </div>

                  196.98.220.01.099
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Update
                  </div>
                  <div className="add-device__date">
                    25th May, 2023
                  </div>
                </div>
              </div>

              <div className="add-device__row is-head">
                <div className="add-device__col">
                  {' '}
                </div>
                <div className="add-device__col">
                  Slave Group Out
                </div>
                <div className="add-device__col">
                  Function
                </div>
                <div className="add-device__col">
                  Serial No
                </div>
                <div className="add-device__col">
                  Status
                </div>
                <div className="add-device__col">
                  IP Address
                </div>
                <div className="add-device__col">
                  Update
                </div>
              </div>

              <div className="add-device__row">
                <div className="add-device__col">
                  <div className="add-device__label">
                    S.No.
                  </div>

                  2
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Device Name
                  </div>

                  BLK 5 Lane 1 Out
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Function
                  </div>

                  Slave (Out)
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Serial No.
                  </div>
                  BRJ7182360002
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Status
                  </div>

                  <div className="status-success">Working</div>
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    IP Address
                  </div>

                  192.168.1.68
                </div>

                <div className="add-device__col">
                  <div className="add-device__label">
                    Update
                  </div>
                  <div className="add-device__date">
                    25th May, 2023
                  </div>
                </div>
              </div>
            </div>

            <div className="table__wrapper">{' '}</div>
            <div className="table__foot text-center">
              <button type="button" className="button button-stroke has-loader">
                <SpinnerLoading />
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DSTables

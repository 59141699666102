import cn from 'classnames';
import React, { useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useParams } from 'react-router-dom';
import PageDashboard from '../../components/PageDashboard/PageDashboard';

// Inner Components
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { GET_PROJECT, UPDATE_PROJECT, DELETE_PROJECT } from '../../queriesAndMutations';
import { toaster } from '../../utils';
import ProjectInformation from '../ProjectsManagement/components/ProjectInformation';
import ProjectEPSSSettingInformation from '../ProjectsManagement/components/ProjectEPSSSettingInformation';
import AccessLevel from '../ProjectsManagement/components/AccessLevel';
import { INIT_EDIT_PROJECT_VALUES } from '../../constants/project';

const ProjectView = () => {
  const [values, setValues] = useState(INIT_EDIT_PROJECT_VALUES);
  const scrollToProject = useRef(null);
  const scrollToEPSSSetting = useRef(null);
  const scrollToAccessLevel = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [deleteProject] = useMutation(DELETE_PROJECT);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [getProject, { data: { getProject: data } = {}, refetch, loading }] = useLazyQuery(
    GET_PROJECT,
    {
      fetchPolicy: 'network-only',
    }
  );

  const navigation = [
    {
      title: 'Project',
      action: () => scrollToProject.current.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      title: 'EPSS Settings',
      action: () => scrollToEPSSSetting.current.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      title: 'Access Level',
      action: () => scrollToAccessLevel.current.scrollIntoView({ behavior: 'smooth' }),
    },
  ];

  const handleDeleteProject = React.useCallback(() => {
    deleteProject({
      variables: {
        input: {
          id: parseInt(id),
        },
      },
    })
      .then(({ data: { deleteProject: success } }) => {
        if (success) {
          toaster.success('Project deleted');
          history.push('/projects-management');
        }
      })
      .catch((e) => {
        console.error(e);
        toaster.error('Delete project failed, please try again later');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleUpdateProject = React.useCallback(() => {
    const _values = { ...values };
    Object.keys(_values).forEach((key) => {
      const value = _values[key];
      if (value === '' || value === null) {
        delete _values[key];
      }
    });
    updateProject({
      variables: {
        input: {
          ..._values,
        },
      },
    })
      .then(({ data: { updateProject: success } }) => {
        if (success) {
          toaster.success('Project updated');
          history.push('/projects-management');
        }
      })
      .catch((e) => {
        console.log('e', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  React.useEffect(() => {
    getProject({
      variables: {
        id: parseInt(id),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    if (data && data.id) {
      setValues({
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  return (
    <PageDashboard pageTitle="View Project">
      <div className="settings">
        <div className="settings__menu">
          {navigation.map((x, index) => (
            <button
              className={cn('settings__link', {
                active: true,
              })}
              key={index}
              onClick={x.action}
            >
              {x.title}
            </button>
          ))}
        </div>

        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToProject}>
                {' '}
              </div>

              <div className="title settings__title text-color-white">Information</div>
              <ProjectInformation mode="edit" values={values} setValues={setValues} />
            </div>

            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToEPSSSetting}>
                {' '}
              </div>

              <div className="title settings__title text-color-white">EPSS Settings</div>
              <ProjectEPSSSettingInformation values={values} setValues={setValues} />
            </div>

            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToAccessLevel}>
                {' '}
              </div>
              <div className="title settings__title text-color-white">Access Level</div>
              <AccessLevel values={values} setValues={setValues} />
            </div>
          </div>
          <button
            onClick={handleDeleteProject}
            className="button button-small settings__button--delete"
          >
            Delete
          </button>{' '}
          <button onClick={handleUpdateProject} className="button button-small settings__button">
            Save
          </button>
        </div>
      </div>
    </PageDashboard>
  );
};

export default ProjectView;

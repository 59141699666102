import gql from 'graphql-tag';

export const GET_COMPANY_LIST = gql`
  query getCompanyList($page: Int, $keyWord: String) {
    getCompanyList(page: $page, keyWord: $keyWord)
  }
`;

export const GET_COMPANY = gql`
  query getCompany($id: Int) {
    getCompany(id: $id)
  }
`;

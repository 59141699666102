import React from 'react'
import Icon from '../../../components/Icons/Icon'

const DSSettings = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Settings</h1>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Setting Line</div>
        <div className="ds__card-inner">
          <div className="settings__line">
            <div className="settings__label">
              Device-1 ( OSG7030067030800008 )
            </div>

            <div className="settings__right">
              <button
                className="settings__control-button"
              >
                <Icon className="photos" name="photos" />
              </button>
            </div>
          </div>

          <div className="settings__line">
            <div className="settings__label">
              Device-1 ( OSG7030067030800008 )
            </div>

            <div className="settings__right">
              <button
                className="settings__control-button"
              >
                <Icon className="photos" name="photos" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Setting Row</div>

        <div className="ds__card-inner">
          <div className="settings__row">
            <div className="field text-color-white">content 1</div>
            <div className="field text-color-white">content 2</div>
          </div>

          <div className="settings__row is-col-3">
            <div className="field text-color-white">content 1</div>
            <div className="field text-color-white">content 2</div>
            <div className="field text-color-white">content 3</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DSSettings

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { get, uniq } from 'lodash';
import './styles.scss';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import { DASHBOARD } from '../../queriesAndMutations/personQuery';
import { useMe } from '../../myHooks';
import { USER_RIGHT_TYPES } from '../../constants/user';
import { MASTER_LIST_EVENT_MODES } from '../../constants/report';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const INIT_FILTERS = {
  dateFrom: new Date(),
  dateTo: new Date(),
};

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Home = () => {
  const history = useHistory();
  const {
    loading: userLoading,
    data: { me: user },
    error,
  } = useMe();
  const classes = useStyles();

  const [filters, setFilter] = React.useState(INIT_FILTERS);
  const [getDashboard, { data: { getDashboard: data } = {}, loading }] = useLazyQuery(DASHBOARD);

  const countTotalEmployees = (projectData = []) => {
    let total = 0;
    // let accessLevels = [];
    projectData
      // .sort(
      //   (itemA, itemB) =>
      //     get(itemA, 'project.access_level_ids', []).length -
      //     get(itemB, 'project.access_level_ids', []).length
      // )
      .forEach((item) => {
        // const countCurrentAccessLevels = accessLevels.length;
        // accessLevels = uniq(accessLevels.concat(get(item, 'project.access_level_ids', [])));

        // if (accessLevels.length > countCurrentAccessLevels) {
        //   total += get(item, 'data.userDevCount', 0);
        // }
        total += get(item, 'data.userDevCount', 0);
      });

    return total;
  };

  const handleClickOnEmployees = (projectId, mode = MASTER_LIST_EVENT_MODES.ALL) => {
    const objectFilter = {};
    if (filters && filters.dateFrom) {
      objectFilter.dateFrom = moment(filters.dateFrom).format('YYYY-MM-DD');
    }

    if (filters && filters.dateTo) {
      objectFilter.dateTo = moment(filters.dateTo).format('YYYY-MM-DD');
    }

    history.push({
      pathname: '/master-list',
      state: {
        projectId,
        mode,
        ...objectFilter,
      },
    });
  };

  useEffect(() => {
    const objectFilter = {};
    if (filters && filters.dateFrom) {
      objectFilter.dateFrom = moment(filters.dateFrom).format('YYYY-MM-DD');
    }

    if (filters && filters.dateTo) {
      objectFilter.dateTo = moment(filters.dateTo).format('YYYY-MM-DD');
    }

    getDashboard({
      variables: {
        ...objectFilter,
      },
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const objectFilter = {};
    if (filters && filters.dateFrom) {
      objectFilter.dateFrom = moment(filters.dateFrom).format('YYYY-MM-DD');
    }

    if (filters && filters.dateTo) {
      objectFilter.dateTo = moment(filters.dateTo).format('YYYY-MM-DD');
    }

    getDashboard({
      variables: {
        ...objectFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return userLoading ? (
    ''
  ) : (
    <PageDashboard pageTitle={user.rights === USER_RIGHT_TYPES.COMMON ? '' : 'Dashboard'}>
      {user.rights !== USER_RIGHT_TYPES.COMMON && (
        <div className="dashboard-container">
          <div className="dashboard__total-employees">
            <h4>
              Total employees: {data && Array.isArray(data) ? countTotalEmployees([...data]) : 0}
            </h4>
          </div>
          <div className="dashboard__filter">
            {/* <div className="field">
              <div className="field__label">Date From</div>
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={filters.dateFrom}
                  onChange={(date) => setFilter({ ...filters, dateFrom: date })}
                />
              </div>
            </div> */}
            <div style={{ marginLeft: '12px' }} className="field right">
              <div className="field__label">Date</div>
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={filters.dateTo}
                  onChange={(date) => setFilter({ ...filters, dateFrom: date, dateTo: date })}
                />
              </div>
            </div>

            <div style={{ marginLeft: '12px' }}>
              <div style={{ color: '#000' }} className="field__label">
                Reset
              </div>
              <button onClick={() => setFilter(INIT_FILTERS)} className="button button-small">
                Reset
              </button>
            </div>
          </div>
          {loading && (
            <div className={classes.circularProgressWrapper}>
              <CircularProgress />
            </div>
          )}
          {data &&
            !loading &&
            Array.isArray(data) &&
            data.map((projectData) => (
              <div className="project-container">
                <h3>{get(projectData, 'project.name')}</h3>
                <div className="card stat">
                  <CardSummary
                    count={get(projectData, 'data.userDevCount', 0)}
                    title="Total Users"
                    from={filters.dateFrom}
                    to={filters.dateTo}
                    onClick={() =>
                      handleClickOnEmployees(
                        get(projectData, 'project.id'),
                        MASTER_LIST_EVENT_MODES.ALL
                      )
                    }
                  />
                  <CardSummary
                    count={get(projectData, 'data.checkInCount', 0)}
                    title="Checked In"
                    from={filters.dateFrom}
                    to={filters.dateTo}
                    onClick={() =>
                      handleClickOnEmployees(
                        get(projectData, 'project.id'),
                        MASTER_LIST_EVENT_MODES.CHECKIN
                      )
                    }
                  />
                  <CardSummary
                    count={get(projectData, 'data.absentCount', 0)}
                    title="Absent"
                    from={filters.dateFrom}
                    to={filters.dateTo}
                    onClick={() =>
                      handleClickOnEmployees(
                        get(projectData, 'project.id'),
                        MASTER_LIST_EVENT_MODES.ABSENT
                      )
                    }
                  />
                  {/* <CardSummary count={get(project, 'data.absentCount', 0)} title='Absent' from={filters.dateFrom} to={filters.dateTo} /> */}
                </div>
              </div>
            ))}
        </div>
      )}
    </PageDashboard>
  );
};

export default Home;

const CardSummary = ({ backgroundColor, count, title, from, to, onClick = () => {}, icon }) => {
  const renderInfoTime = () => {
    let result = '';
    if (from) {
      result = `From ${moment(from).format('DD MMM YYYY')}`;

      if (to) {
        result += ` to ${moment(to).format('DD MMM YYYY')}`;
      }
    } else {
      if (to) {
        result = `Until ${moment(to).format('DD MMM YYYY')}`;
      }
    }

    return result ? <div className="stat__box-date">{result}</div> : null;
  };

  return (
    <div
      className="stat__box"
      style={{
        ...(backgroundColor ? { backgroundColor } : {}),
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <div className="stat__box-heading">
        <div className="heading-h2 stat__box-num">{Math.max(count || 0, 0)}</div>
      </div>
      <div className="stat__box-content">
        <div className="heading-h5 stat__box-text">{title}</div>
        {/* {renderInfoTime()} */}
      </div>
    </div>
  );
};

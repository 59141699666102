import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import ProtectedZone from './components/ProtectedZone';
import Theme from './components/Theme';
import { USER_RIGHT_TYPES } from './constants/user';
import ReportAbsent from './pages/AbsentReport/AbsentReport';
import AdminManagement from './pages/AdminManagement/AdminManagement';
import ReportDailyEntryExitReport from './pages/DailyEntryExitReport/DailyEntryExitReport';
import ReportDailyWorkerPresent from './pages/DailyWorkerPresent/DailyWorkerPresent';
import DashboardBasic from './pages/DashboardBasic/DashboardBasic';
import DashboardLTA from './pages/DashboardLTA/DashboardLTA';
import DashboardMOM from './pages/DashboardMOM/DashboardMOM';
import DashboardOffice from './pages/DashboardOffice/DashboardOffice';
import DashboardSubcon from './pages/DashboardSubcon/DashboardSubcon';
import DevicesManagement from './pages/DevicesManagement';
import ReportDoorList from './pages/DoorList/DoorList';
import EPSSReports from './pages/EPSSReports/EPSSReports';
import EventManagement from './pages/EventsManagement/EventsManagement';
import Home from './pages/Home/Home';
import ReportLateness from './pages/LatenessReport/LatenessReport';
import Login from './pages/Login';
import ReportMancountSubcon from './pages/MancountReport/MancountReport';
import ReportMancountTrade from './pages/MancountReportTrade/MancountReportTrade';
import MasterList from './pages/MasterList/MasterList';
import HDBReport from './pages/HDBReport/HDBReport';
import ReportOvertime from './pages/OvertimeReport/OvertimeReport';
import ReportPassportExpiring from './pages/PassportExpiringReport/PassportExpiringReport';
import Register from './pages/Register';
import RegisterConfirm from './pages/RegisterConfirm';
import ReportDetailedAccess from './pages/ReportDetailedAccess/ReportDetailedAccess';
import TimeAttendanceReports from './pages/ReportsTimeAttendance/ReportTimeAttendance';
import ResidentsManagement from './pages/ResidentsManagement/ResidentsManagement';
import ReportOfArrival from './pages/TimeOfArrival/TimeOfArrival';
import ReportTimeOfDepature from './pages/TimeOfDepature/TimeOfDepature';
import AddUser from './pages/UsersAdd/UsersAdd';
import UserManagement from './pages/UsersManagement/UsersManagement';
import UserView from './pages/UsersView/UsersView';
import ReportWorkPermitExpiry from './pages/WorkPermitExpiryReport/WorkPermitExpiryReport';
import ReportWorkingHours from './pages/WorkingHoursReport/WorkingHoursReport';
import ReportWorkingHoursTrade from './pages/WorkingHoursReportTrade/WorkingHoursReportTrade';
import CompaniesManagement from './pages/CompaniesManagement/CompaniesManagement';
import CompanyView from './pages/CompanyView/CompanyView';
import CompanyAdd from './pages/CompanyAdd/CompanyAdd';
import ProjectsManagement from './pages/ProjectsManagement';
import ProjectAdd from './pages/ProjectAdd';
import ProjectView from './pages/ProjectView';

import Settings from './pages/Settings/Settings';
import DesignSystem from './pages/DesignSystem/DesignSystem';
import AdminRoute from './components/AdminRoute';
import PersonAttendanceReport from './pages/PersonAttendanceReport/PersonAttendanceReport';
import TimeAttendanceReport from './pages/TimeAttendanceReport/TimeAttendanceReport';

export default (apolloClient) => (
  <Theme>
    <Switch>
      <AuthRoute exact path="/login" render={() => <Login />} />
      <AuthRoute exact path="/register" render={() => <Register />} />

      <AuthRoute exact path="/register/confirm" render={() => <RegisterConfirm />} />

      {/* <AdminRoute exact path="/devices-management" render={() => <DevicesManagement />} /> */}

      <ProtectedZone minRights={USER_RIGHT_TYPES.COMMON}>
        <Route exact path="/" render={() => <Home />} />

        <ProtectedZone minRights={USER_RIGHT_TYPES.ADMIN} urlToRedirect="/">
          <Route exact path="/report-epss" render={() => <EPSSReports />} />
          {/* Still dummy content */}
          <Route exact path="/report-time-attendance" render={() => <TimeAttendanceReports />} />

          <Route exact path="/events-management" render={() => <EventManagement />} />

          <Route exact path="/devices-management" render={() => <DevicesManagement />} />

          <Route exact path="/companies-management" render={() => <CompaniesManagement />} />

          <Route exact path="/company-view/:id" render={() => <CompanyView />} />

          <Route exact path="/add-company" render={() => <CompanyAdd />} />

          {/* Still dummy content */}
          {/* <Route exact path="/residents-management" render={() => <ResidentsManagement />} /> */}

          <Route exact path="/users-management" render={() => <UserManagement />} />

          <Route exact path="/user-view/:id" render={() => <UserView />} />

          <Route exact path="/add-user" render={() => <AddUser />} />

          {/* <Route exact path="/dashboard-basic" render={() => <DashboardBasic />} /> */}
          <Route exact path="/dashboard-basic" render={() => <Home />} />

          {/* Still dummy content */}
          <Route exact path="/dashboard-subcon" render={() => <DashboardSubcon />} />

          {/* Still dummy content */}
          <Route exact path="/dashboard-office" render={() => <DashboardOffice />} />

          {/* Still dummy content */}
          <Route exact path="/dashboard-lta" render={() => <DashboardLTA />} />

          {/* Still dummy content */}
          <Route exact path="/dashboard-mom" render={() => <DashboardMOM />} />

          <Route exact path="/detailed-access" render={() => <ReportDetailedAccess />} />

          {/* Still dummy content */}
          <Route exact path="/time-of-depature" render={() => <ReportTimeOfDepature />} />

          {/* Still dummy content */}
          <Route exact path="/time-of-arrival" render={() => <ReportOfArrival />} />

          <Route
            exact
            path="/daily-entry-exit-report"
            render={() => <ReportDailyEntryExitReport />}
          />

          <Route exact path="/master-list" render={() => <MasterList />} />
          {/* Still dummy content */}
          <Route exact path="/lateness-report" render={() => <ReportLateness />} />
          {/* Still dummy content */}
          <Route exact path="/absent-report" render={() => <ReportAbsent />} />
          {/* Still dummy content */}
          <Route exact path="/door-list" render={() => <ReportDoorList />} />
          {/* Still dummy content */}
          <Route exact path="/overtime-report" render={() => <ReportOvertime />} />
          {/* Still dummy content */}
          <Route exact path="/passport-expiring-report" render={() => <ReportPassportExpiring />} />
          <Route exact path="/person-attendance-report" render={() => <PersonAttendanceReport />} />
          <Route exact path="/date-attendance-report" render={() => <TimeAttendanceReport />} />
          <Route
            exact
            path="/work-permit-expiry-report"
            render={() => <ReportWorkPermitExpiry />}
          />

          <Route exact path="/hdb-report" render={() => <HDBReport />} />

          {/* Still dummy content */}
          <Route exact path="/daily-worker-present" render={() => <ReportDailyWorkerPresent />} />
          {/* Still dummy content */}
          <Route exact path="/working-hours-report" render={() => <ReportWorkingHours />} />
          {/* Still dummy content */}
          <Route exact path="/mancount-report-subcon" render={() => <ReportMancountSubcon />} />
          {/* Still dummy content */}
          <Route exact path="/mancount-report-trade" render={() => <ReportMancountTrade />} />
          {/* Still dummy content */}
          <Route
            exact
            path="/working-hours-report-by-trade"
            render={() => <ReportWorkingHoursTrade />}
          />

          <Route exact path="/settings" render={() => <Settings />} />
          <Route exact path="/xyz-design-system" render={() => <DesignSystem />} />

          <ProtectedZone minRights={USER_RIGHT_TYPES.GENERAL_ADMIN} urlToRedirect="/">
            <Route exact path="/admin-management" render={() => <AdminManagement />} />
            <Route exact path="/projects-management" render={() => <ProjectsManagement />} />
            <Route exact path="/add-project" render={() => <ProjectAdd />} />
            <Route exact path="/project-view/:id" render={() => <ProjectView />} />
          </ProtectedZone>
        </ProtectedZone>
      </ProtectedZone>
    </Switch>
  </Theme>
);

import React from 'react';
import RegisterConfirmComponent from './RegisterConfirmComponent';

const Register = () => {
  return (
    <>
      <div className="entry entry_loginregister">
        <div className="entry__wrapper entry__wrapper-kyc">
          <RegisterConfirmComponent />
        </div>
      </div>
    </>
  );
};

export default Register;

import gql from 'graphql-tag';

export const GET_USER_LIST = gql`
  query getUserList($page: Int) {
    getUserList(page: $page)
  }
`;

export const GET_USER = gql`
  query getUser($id: Int) {
    getUser(id: $id)
  }
`;

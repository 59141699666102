import React, { useState } from 'react'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import SlideToggle from 'react-slide-toggle'
import Icon from '../../../components/Icons/Icon'
import DropdownCustom from '../../../components/DropdownCustom/DropdownCustom'

const DSCards = () => {
  const optionDropdownCustom = [
    "Link 1",
    "Link 2",
  ]

  const [activeOptionDropdownCustom, setActiveOptionDropdownCustom] = useState(optionDropdownCustom[0]);
  const [startDateSearch, setStartDateSearch] = useState(new Date());
  const [endDateSearch, setEndDateSearch] = useState(new Date());

  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Cards</h1>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Card Head With Circle Button & Tooltip</div>
        <div className="ds__card-inner">
          <div className="card">
            <div className="card__head">
              <div className="title card__title">
                Card Head
                <button
                  className="button-circle button-small"
                  data-tip="Card Title Tooltip"
                  data-for="card-title-tooltip"
                >
                  <Icon className="plus" name="plus" />
                </button>
                <ReactTooltip
                  id="card-title-tooltip"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Card Head With Nav Link</div>
        <div className="ds__card-inner">
          <div className="card">
            <div className="card__head is-spacebetween">
              <div className="title card__title">
                Card Head With Nav
              </div>

              <div className="card__nav">
                <button className="card__link is-active">
                  Link 1
                </button>
                <button className="card__link">
                  Link 2
                </button>

                <DropdownCustom
                  isRequired
                  value={activeOptionDropdownCustom}
                  setValue={setActiveOptionDropdownCustom}
                  options={optionDropdownCustom}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Card Head With Buttons</div>
        <div className="ds__card-inner">
          <div className="card">
            <div className="card__head is-spacebetween">
              <div className="title card__title">
                Card Head With Button
              </div>

              <div className="card__nav has-buttons">
                <button className="button button-small">
                  Button 1
                </button>
                <button className="button button-small">
                  Button 2
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Card Head With Control</div>
        <div className="ds__card-inner">
          <div className="card">
            <div className="card__head is-spacebetween">
              <div className="title card__title">
                Card Head With Control
              </div>

              <div className="card__nav has-control">
                <div className="field">
                  <div className="field__wrap">
                    <DatePicker
                      showIcon
                      selected={startDateSearch}
                      onChange={(date) => setStartDateSearch(date)}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__wrap">
                    <DatePicker
                      showIcon
                      selected={endDateSearch}
                      onChange={(date) => setEndDateSearch(date)}
                    />
                  </div>
                </div>

                <button
                  className="button"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Card Head With Accordion Collapse</div>
        <div className="ds__card-inner">
          <SlideToggle
            collapsed
            render={({
              toggle,
              setCollapsibleElement,
              toggleState,
            }) => (
              <div className='card card__collapse'>
                <div className="card__head">
                  <div className="title card__title">
                    Card Head With Collapse
                  </div>
                  <button
                    type="button"
                    className={
                      classNames(
                        'card__collapse-icon',
                        (toggleState || "").toLowerCase()
                      )
                    }
                    onClick={toggle}
                  >
                    <Icon className="arrow-down" name="arrow-down" size="24" />
                  </button>
                </div>

                <div className="card__collapse-content" ref={setCollapsibleElement}>
                  <div className="card__collapse-inner">
                    <h3 className="heading-h3 text-color-white">Content Collapse</h3>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </>
  )
}

export default DSCards

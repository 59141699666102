import React from 'react'
import ReactTooltip from 'react-tooltip'
import Icon from '../../components/Icons/Icon'
import PageDashboard from '../../components/PageDashboard/PageDashboard'
import ButtonTheme from '../../components/ButtonXid/Button'

const DoorList = () => {
  return (
    <PageDashboard pageTitle="Door List">
      <div className="report-management card">
        <div className="card__head is-spacebetween">
          {/* Card Title */}
          <div className="title card__title">
            Door List / Occupancy List
            <button
              className="button-circle button-small"
              data-tip="Export to Excel"
              data-for="export-to-excel"
            >
              <Icon className="download" name="download" />
            </button>

            <ReactTooltip id="export-to-excel"/>
          </div>
        </div>

        {/* Content */}
        <div className="report-management__wrapper">
          <div className="table__wrapper">
            {/* Report */}
            <div className="table__row">
              <div className="table__col">BLk</div>
              <div className="table__col">Unit No</div>
              <div className="table__col">Name</div>
              <div className="table__col">Work Permit No.</div>
              <div className="table__col">Wp Cat.</div>
              <div className="table__col">WP Exp. Date</div>
              <div className="table__col">Employer</div>
            </div>

            <div className="table__row">
              <div className="table__col">
                <div className="table__label">Blk</div>
                -
              </div>
              <div className="table__col">
                <div className="table__label">Unit No.</div>
                -
              </div>
              <div className="table__col">
                <div className="table__label">Name</div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#">
                  TEST SRINI XID
                </a>
              </div>
              <div className="table__col">
                <div className="table__label">Work Permit No.</div>
                0 236590
              </div>
              <div className="table__col">
                <div className="table__label">WP Cat.</div>
                -
              </div>
              <div className="table__col">
                <div className="table__label">WP Exp. Date</div>
                2018-01-21 23:59:00
              </div>
              <div className="table__col">
                <div className="table__label">Employer</div>
                -
              </div>
            </div>

            <div className="table__row">
              <div className="table__col">
                <div className="table__label">Blk</div>
                -
              </div>
              <div className="table__col">
                <div className="table__label">Unit No.</div>
                -
              </div>
              <div className="table__col">
                <div className="table__label">Name</div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#">Test Account</a>
              </div>
              <div className="table__col">
                <div className="table__label">Work Permit No.</div>
                0 236589
              </div>
              <div className="table__col">
                <div className="table__label">WP Cat.</div>
                -
              </div>
              <div className="table__col">
                <div className="table__label">WP Exp. Date</div>
                2018-11-21 23:59:00
              </div>
              <div className="table__col">
                <div className="table__label">Employer</div>
                -
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="table__foot">
            <ButtonTheme
              hasSpinnerLoading
              textLabel="Load More"
              classButton="button-small button-stroke has-loader"
            />
          </div>
        </div>
      </div>
    </PageDashboard>
  )
}

export default DoorList

import gql from 'graphql-tag';

export const CREATE_PROJECT = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input)
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input)
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input)
  }
`;

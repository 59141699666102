import gql from 'graphql-tag';

// eslint-disable-next-line
export const DELETE_DEVICE = gql`
  mutation deleteDevice($input: DeleteDeviceInput!) {
    deleteDevice(input: $input)
  }
`;
export const DELETE_USER_FACE = gql`
  mutation deleteUserFace($input: DeleteUserFaceInput!) {
    deleteUserFace(input: $input)
  }
`;

export const DELETE_USER_FINGERPRINT = gql`
  mutation deleteUserFingerprint($input: DeleteUserFingerprintInput!) {
    deleteUserFingerprint(input: $input)
  }
`;

export const ADD_NEW_HIK_DEVICE = gql`
  mutation addNewHikDevice($input: AddNewHikDeviceInput!) {
    addNewHikDevice(input: $input)
  }
`;

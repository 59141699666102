import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { USER_RIGHT_TYPES } from "../constants/user";
import { useMe } from "../myHooks";

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));

const AdminRoute = (props) => {
  const {
    loading,
    data: { me: user },
  } = useMe();
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.circularProgressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (user.rights !== USER_RIGHT_TYPES.ADMIN && user.rights !== USER_RIGHT_TYPES.GENERAL_ADMIN) {
    return <Redirect to="/" />;
  }
  return <Route {...props} />;
};

export default AdminRoute;

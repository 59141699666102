import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { get, find } from 'lodash';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import client from '../../apolloClient';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import ButtonTheme from '../../components/ButtonXid/Button';
import Icon from '../../components/Icons/Icon';
import InputField from '../../components/InputField/InputField';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import SelectAsync from '../../components/SelectAsync';
import {
  GET_ACCESS_TRANSACTION_LIST,
  GET_COMPANY_LIST,
  LIST_DEVICES,
  GET_PERSON_LIST,
  GET_PROJECT_LIST,
} from '../../queriesAndMutations';
import { useMe } from '../../myHooks';
import { USER_RIGHT_TYPES } from '../../constants/user';

const INIT_FILTERS = {
  dateFrom: null,
  dateTo: null,
  pin: '',
  devSN: '',
  eventLog: '',
  verifyType: '',
  inOutStatus: '',
  cardNo: '',
  userName: '',
  companyId: '',
  projectId: null,
};

const InOutFilterOption = [
  {
    id: 0,
    label: 'In',
  },
  {
    id: 1,
    label: 'Out',
  },
];

// const VerifyTypeFilterOption = [
//   { id: 1, label: 'Finger' },
//   { id: 2, label: 'Pin' },
//   { id: 3, label: 'Password' },
//   { id: 4, label: 'CardNumber' },
//   { id: 5, label: 'Finger or CardNumber' },
//   { id: 6, label: 'Finger or card' },
//   { id: 7, label: 'Card Number or' },
//   { id: 8, label: 'Pin and finger' },
//   { id: 9, label: 'Finger and password' },
//   { id: 10, label: 'Card number and finger' },
//   { id: 11, label: 'Card and password' },
//   { id: 12, label: 'Finger and password and card' },
//   { id: 13, label: 'Pin and finger and password' },
//   { id: 14, label: 'Pin and finger or card and finger' },
//   { id: 15, label: 'Face' },
//   { id: 16, label: 'Face and finger' },
//   { id: 17, label: 'Face and password' },
//   { id: 18, label: 'Face and card' },
//   { id: 19, label: 'Face and finger and card' },
//   { id: 20, label: 'Face and finger and password' },
//   { id: 21, label: 'Fvein' },
//   { id: 22, label: 'Fvein and password' },
//   { id: 23, label: 'Fvein and card' },
//   { id: 24, label: 'Fvein and password and card' },
//   { id: 200, label: 'Other' },
// ];

// const EventLogFilterOption = [
//   { id: 0, label: 'Success' },
//   { id: 1, label: 'Normal open Verify' },
//   { id: 2, label: 'First Personnel Open' },
//   { id: 3, label: 'Multi Personnel Open' },
//   { id: 4, label: 'Super password Open' },
//   { id: 5, label: 'Normal Open' },
//   { id: 6, label: 'Link event' },
//   { id: 7, label: 'Cancel alarm' },
//   { id: 8, label: 'Remote open door' },
//   { id: 9, label: 'Remote close door' },
//   { id: 10, label: 'Cancel NO' },
//   { id: 11, label: 'Start NO' },
//   { id: 12, label: 'Remote open auxiliary' },
//   { id: 13, label: 'Remote close auxiliary' },
//   { id: 14, label: 'Open with finger' },
//   { id: 15, label: 'Open with multi finger' },
//   { id: 16, label: 'Door keep open fp' },
//   { id: 17, label: 'Card and FP' },
//   { id: 18, label: 'First FP' },
//   { id: 19, label: 'First Card and FP' },
//   { id: 20, label: 'Interval error' },
//   { id: 21, label: 'Sleep door error' },
//   { id: 22, label: 'Illege time' },
//   { id: 23, label: 'Access Denied' },
//   { id: 24, label: 'Antipassback' },
//   { id: 25, label: 'Multi lock lingate' },
//   { id: 26, label: 'Multi card wait' },
//   { id: 27, label: 'Verified Failed' },
//   { id: 28, label: 'Door contact overtime' },
//   { id: 29, label: 'Card overtime' },
//   { id: 30, label: 'Password error' },
//   { id: 31, label: 'Fp interval error' },
//   { id: 32, label: 'Multi fp wait' },
//   { id: 33, label: 'Fp overtime' },
//   { id: 34, label: 'Unknown Finger' },
//   { id: 35, label: 'FP Overtime' },
//   { id: 36, label: 'Button overtime' },
//   { id: 37, label: 'Keep open error' },
//   { id: 38, label: 'Loss card' },
//   { id: 39, label: 'Blacklist' },
//   { id: 40, label: 'Multi finger failed' },
//   { id: 41, label: 'Verify type failed' },
//   { id: 42, label: 'Wiegand error' },
//   { id: 43, label: 'Reserved 43' },
//   { id: 44, label: 'Remote verify failed' },
//   { id: 45, label: 'Remote verify timeout' },
//   { id: 46, label: 'Reserved 46' },
//   { id: 47, label: 'Open door failed' },
//   { id: 48, label: 'Multi personnel failed' },
//   { id: 68, label: 'Temperature error' },
//   { id: 69, label: 'Mask error' },
//   { id: 100, label: 'Antistrip alarm' },
//   { id: 101, label: 'Emergency password alarm' },
//   { id: 102, label: 'Unexcept door open' },
//   { id: 103, label: 'Emergency fp alarm' },
//   { id: 104, label: 'Five failed card punch' },
//   { id: 105, label: 'Device offline' },
//   { id: 200, label: 'Door open' },
//   { id: 201, label: 'Door close' },
//   { id: 202, label: 'Button open door' },
//   { id: 203, label: 'Multi card and fp' },
//   { id: 204, label: 'Keep open end' },
//   { id: 205, label: 'Remote keep open' },
//   { id: 206, label: 'Device Start' },
//   { id: 207, label: 'Password open door' },
//   { id: 208, label: 'Super user open door' },
//   { id: 209, label: 'Door locked' },
//   { id: 210, label: 'Fire event' },
//   { id: 211, label: 'Super user closed' },
//   { id: 212, label: 'Multi card OK' },
//   { id: 213, label: 'First card open' },
//   { id: 214, label: 'Device online' },
//   { id: 220, label: 'Auxin open' },
//   { id: 221, label: 'Auxin close' },
//   { id: 222, label: 'Remote verify success' },
//   { id: 223, label: 'Remote verify' },
//   { id: 225, label: 'Auxin normal' },
//   { id: 226, label: 'Auxin trigger' },
//   { id: 227, label: 'Double open' },
//   { id: 228, label: 'Double close' },
// ];

const EventsManagement = () => {
  const inOutRef = useRef(null);
  // const verifyRef = useRef(null);
  // const eventRef = useRef(null);
  const companyRef = useRef(null);
  const deviceRef = useRef(null);
  const nameRef = useRef(null);
  const projectRef = useRef(null);
  const [projectList, setProjectList] = useState([]);
  const accessDateFromRef = useRef(null);
  const accessDateToRef = useRef(null);
  const [forceReloadSelect, setForceReloadSelect] = useState(false);
  const [accessTransactionList, setAccessTransactionList] = useState([]);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({ ...INIT_FILTERS });

  const [canLoadMore, setCanLoadMore] = useState(true);
  const [
    getAccessTransactionList,
    { data: { getAccessTransactionList: data } = {}, refetch, loading },
  ] = useLazyQuery(GET_ACCESS_TRANSACTION_LIST, {
    fetchPolicy: 'network-only',
  });
  const [
    getCompanyList,
    { data: { getCompanyList: companyList } = {}, loading: loadingCompanyList },
  ] = useLazyQuery(GET_COMPANY_LIST, {
    fetchPolicy: 'network-only',
  });

  const [getDeviceList, { data: { listDevices: deviceList } = {}, loading: loadingDeviceList }] =
    useLazyQuery(LIST_DEVICES, {
      fetchPolicy: 'network-only',
    });
  const [getProjectList, { data: { getProjectList: projectListData } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const {
    data: { me: user },
  } = useMe();

  const formatFilter = (filters) => {
    return Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== '' || filters[key] === undefined || filters[key] === null) {
        if ((key === 'dateFrom' || key === 'dateTo') && filters[key] !== null) {
          acc[key] = moment(filters[key]).format('MM/DD/YYYY');
        } else {
          acc[key] = filters[key];
        }
      }
      return acc;
    }, {});
  };

  const onClickSearch = () => {
    setAccessTransactionList([]);
    setCanLoadMore(true);
    if (page === 0) {
      getAccessTransactionList({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    } else {
      setPage(1);
    }
  };

  const onResetFilter = () => {
    if (projectRef && projectRef.current) {
      projectRef.current.clearValue();
    }
    if (inOutRef && inOutRef.current) {
      inOutRef.current.clearValue();
    }
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }
    if (companyRef && companyRef.current) {
      companyRef.current.clearValue();
    }
    if (deviceRef && deviceRef.current) {
      deviceRef.current.clearValue();
    }
    if (projectRef && projectRef.current) {
      projectRef.current.clearValue();
    }

    setFilters({ ...INIT_FILTERS });

    setAccessTransactionList([]);
    setCanLoadMore(true);

    getAccessTransactionList({
      variables: {
        page: 0,
      },
    });
  };

  const personListLoadOptions = async (search, loadedOptions, { page }) => {
    if (search) {
      const searchResult = find(loadedOptions, (option) => {
        if (
          option.data.label.toLowerCase().includes(search.toLowerCase()) ||
          option.data.value.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (searchResult) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page,
          },
        };
      }
    }
    const result = await client.query({
      query: GET_PERSON_LIST,
      variables: {
        page: search ? 0 : page,
        pageSize: 20,
        projectId: filters.projectId > 0 ? filters.projectId : null,
        userName: search,
      },
      fetchPolicy: 'network-only',
    });
    const newOptions = get(result, 'data.getPersonList.items', []).map((person) => ({
      value: person.userName,
      label: person.userName,
    }));
    const hasMore = get(result, 'data.getPersonList.totalPages', 0) > page;

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: search ? 1 : page + 1,
      },
    };
  };

  useEffect(() => {
    if (!loading && page) {
      getAccessTransactionList({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    }

    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (data && data.items && data.items.length) {
      setAccessTransactionList([...accessTransactionList, ...data.items]);
    }

    if (data && (data.totalPages === 1 || page === data.totalPages - 1)) {
      setCanLoadMore(false);
    }

    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (filters.inOutStatus === '' && inOutRef && inOutRef.current) {
      inOutRef.current.clearValue();
    }
    if (filters.companyId === '' && companyRef && companyRef.current) {
      companyRef.current.clearValue();
    }
    if (filters.devSN === '' && deviceRef && deviceRef.current) {
      deviceRef.current.clearValue();
    }
    if (filters.userName === '' && nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }

    if (filters.dateFrom === null && accessDateFromRef && accessDateFromRef.current) {
      try {
        accessDateFromRef.current.clear();
        accessDateFromRef.current.setSelected(null);
      } catch (error) {
        console.log(error);
      }
    }
    if (filters.dateFrom === null && accessDateToRef && accessDateToRef.current) {
      try {
        accessDateToRef.current.clear();
        accessDateToRef.current.setSelected(null);
      } catch (error) {
        console.log(error);
      }
    }
    // if (filters.verifyType === '' && verifyRef && verifyRef.current) {
    //   verifyRef.current.clearValue();
    // }
    // if (filters.eventLog === '' && eventRef && eventRef.current) {
    //   eventRef.current.clearValue();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters)]);

  // useEffect(() => {
  //   setFilters({ ...filters, userName: '' });
  //   if (nameRef && nameRef.current) {
  //     nameRef.current.clearValue();
  //   }

  //   setForceReloadSelect(true);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters.projectId]);

  // useEffect(() => {
  //   if (forceReloadSelect) {
  //     setTimeout(() => {
  //       setForceReloadSelect(false);
  //     }, 1000);
  //   }
  // }, [forceReloadSelect]);

  useEffect(() => {
    if (projectListData) {
      setProjectList(
        projectListData.map((project) => ({ value: project.id, label: project.name }))
      );
    }
  }, [projectListData]);

  useEffect(() => {
    setFilters({ ...filters, userName: '' });
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }

    setForceReloadSelect(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.projectId]);

  useEffect(() => {
    if (forceReloadSelect) {
      setTimeout(() => {
        setForceReloadSelect(false);
      }, 1000);
    }
  }, [forceReloadSelect]);

  useEffect(() => {
    if (!accessTransactionList || !accessTransactionList.length) {
      getAccessTransactionList({
        variables: { page: 0 },
      });
    }

    if (!companyList && getCompanyList) {
      getCompanyList({
        variables: {
          page: 0,
          keyWord: '',
        },
      });
    }

    if (!deviceList && getDeviceList) {
      getDeviceList({
        variables: {
          page: 0,
        },
      });
    }

    if (!projectListData && getProjectList) {
      getProjectList();
    }

    //eslint-disable-next-line
  }, []);

  return (
    <PageDashboard pageTitle="Events Management">
      <div className="event-management card">
        <div className="event-management__wrapper">
          <div className="event-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row is-col-3">
                <InputField
                  propertyName="pin"
                  fieldName="pin"
                  fieldType="text"
                  fieldLabel="User ID"
                  state={filters}
                  setState={setFilters}
                />

                {/* <InputField
                  propertyName="cardNo"
                  fieldName="cardNo"
                  fieldType="text"
                  fieldLabel="Card No"
                  state={filters}
                  setState={setFilters}
                /> */}

                {/* <InputField
                  propertyName="devSN"
                  fieldName="devSN"
                  fieldType="text"
                  fieldLabel="Device SN"
                  state={filters}
                  setState={setFilters}
                /> */}

                <div className="field">
                  <div className="field__label">In/Out</div>

                  <div className="field__wrap">
                    <Select
                      ref={inOutRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      id="inOutStatus"
                      name="inOutStatus"
                      onChange={(option) => {
                        if (option && option.id !== '') {
                          setFilters({ ...filters, inOutStatus: option.id });
                        } else {
                          setFilters({ ...filters, inOutStatus: '' });
                        }
                      }}
                      options={InOutFilterOption}
                    />
                  </div>
                </div>

                {/* <div className="field">
                  <div className="field__label">Verify Type</div>

                  <div className="field__wrap">
                    <Select
                      ref={verifyRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      id="verifyType"
                      name="verifyType"
                      onChange={(option) => {
                        if (option && option.id !== '') {
                          setFilters({ ...filters, verifyType: option.id });
                        } else {
                          setFilters({ ...filters, verifyType: '' });
                        }
                      }}
                      options={VerifyTypeFilterOption}
                    />
                  </div>
                </div> */}

                {/* <div className="field">
                  <div className="field__label">Event Log</div>
                  <div className="field__wrap">
                    <Select
                      ref={eventRef}
                      isSearchable
                      isClearable
                      id="eventLog"
                      className="basic-single"
                      classNamePrefix="select"
                      name="eventLog"
                      onChange={(option) => {
                        if (option && option.id !== '') {
                          setFilters({ ...filters, eventLog: option.id });
                        } else {
                          setFilters({ ...filters, eventLog: '' });
                        }
                      }}
                      options={EventLogFilterOption}
                    />
                  </div>
                </div> */}

                {/* <InputField
                  propertyName="userName"
                  fieldName="userName"
                  fieldType="text"
                  fieldLabel="User Name"
                  state={filters}
                  setState={setFilters}
                /> */}

                <div className="field">
                  <div className="field__label">User Name</div>

                  <div className="field__wrap">
                    {forceReloadSelect ? (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={true}
                        options={[]}
                      />
                    ) : (
                      <SelectAsync
                        selectRef={nameRef}
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue=""
                        name="userName"
                        onChange={(option) =>
                          setFilters({ ...filters, userName: get(option, 'value', '') })
                        }
                        loadOptions={personListLoadOptions}
                        additional={{
                          page: 0,
                        }}
                        noOptionsMessage="No users"
                      />
                    )}
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Date From</div>
                  <div className="field__wrap">
                    <DatePicker
                      ref={accessDateFromRef}
                      showIcon
                      isClearable
                      selected={filters.dateFrom}
                      onChange={(date) => setFilters({ ...filters, dateFrom: date })}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Date To</div>
                  <div className="field__wrap">
                    <DatePicker
                      ref={accessDateToRef}
                      showIcon
                      isClearable
                      selected={filters.dateTo}
                      onChange={(date) => setFilters({ ...filters, dateTo: date })}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Company</div>

                  <div className="field__wrap">
                    <Select
                      ref={companyRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      isLoading={loadingCompanyList}
                      isDisabled={loadingCompanyList}
                      id="companyId"
                      name="companyId"
                      options={
                        companyList && companyList.items
                          ? companyList.items.map(({ id, companyName }) => ({
                              value: id,
                              label: companyName,
                            }))
                          : []
                      }
                      onChange={(option) => {
                        if (option && option.value !== '') {
                          setFilters({ ...filters, companyId: option.value });
                        } else {
                          setFilters({ ...filters, companyId: '' });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Device</div>

                  <div className="field__wrap">
                    <Select
                      ref={deviceRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      isLoading={loadingDeviceList}
                      isDisabled={loadingDeviceList}
                      id="devSN"
                      name="devSN"
                      options={
                        deviceList && deviceList
                          ? deviceList.map(({ devSN, devName }) => ({
                              value: devSN,
                              label: devName,
                            }))
                          : []
                      }
                      onChange={(option) => {
                        if (option && option.value !== '') {
                          setFilters({ ...filters, devSN: option.value });
                        } else {
                          setFilters({ ...filters, devSN: '' });
                        }
                      }}
                    />
                  </div>
                </div>

                {user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
                  <div className="field">
                    <div className="field__label">Project</div>

                    <div className="field__wrap">
                      <Select
                        ref={projectRef}
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        name="projectId"
                        options={projectList}
                        onChange={(option) =>
                          setFilters({ ...filters, projectId: get(option, 'value', null) })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="settings__btns is-end">
                <button className="button button-stroke button-small" onClick={onResetFilter}>
                  Reset
                  <Icon className="repeat" name="repeat" />
                </button>
                <button className="button button-small" onClick={onClickSearch}>
                  Search
                  <Icon className="icon-search" name="icon-search" />
                </button>
              </div>
            </div>
          </div>
          <div className="v-space-40"> </div>
          <div className="v-space-40 ml-1">
            Page {get(data, 'pageIndex', 0) + 1} of {Math.max(get(data, 'totalPages', 1), 1)} |
            Found total {get(data, 'totalCount', 0)} records
          </div>
          <div className="table__scroll">
            <div className="table__wrapper event-management__table-wrapper">
              {/* Table Content Header */}
              <div className="table__row">
                <div className="table__col table__col--no">No.</div>
                {/* <div className="table__col table__col--id">ID</div> */}
                <div className="table__col table__col--time">Event Time</div>
                <div className="table__col table__col--userName">User Name</div>
                <div className="table__col table__col--companyName">Company Name</div>
                <div className="table__col table__col--deviceName">Device Name</div>
                <div className="table__col table__col--pin">User ID</div>
                {/* <div className="table__col table__col--address">Address Name</div> */}
                <div className="table__col table__col--card">Card No</div>
                {/* <div className="table__col table__col--sn">Device Sn</div> */}
                <div className="table__col table__col--mode">Verify Mode</div>
                {/* <div className="table__col table__col--log">Log Name</div> */}
                {/* <div className="table__col table__col--logId">Log Id</div> */}
                <div className="table__col table__col--inout">In/Out</div>
                <div className="table__col table__col--project">Project</div>
              </div>

              {/* Table Content  */}
              {accessTransactionList.map((trans, index) => (
                <div key={trans.id} className="table__row">
                  <div className="table__col">
                    <div className="table__label">No.</div>
                    {index + 1}
                  </div>

                  {/* <div className="table__col">
                    <div className="table__label">ID</div>
                    {trans.id}
                  </div> */}

                  <div className="table__col">
                    <div className="table__label">Event Time</div>
                    {trans && trans.time ? trans.time.substring(0, 19) : 'Unknown Time'}
                  </div>

                  <div className="table__col">
                    <div className="table__label">User Name</div>
                    {trans.userName ? trans.userName : 'Unknown User'}
                  </div>

                  <div className="table__col">
                    <div className="table__label">Company Name</div>
                    {trans.companyName ? trans.companyName : 'Unknown Company'}
                  </div>

                  <div className="table__col">
                    <div className="table__label">Device Name</div>
                    {trans.deviceName ? trans.deviceName : 'Unknown Device'}
                  </div>

                  <div className="table__col">
                    <div className="table__label">User ID</div>
                    {trans.pin > 0 ? trans.pin : 'Unknown User ID'}
                  </div>

                  {/* <div className="table__col">
                    <div className="table__label">Address Name</div>
                    {trans.eventAddrName}
                  </div> */}

                  <div className="table__col">
                    <div className="table__label">Card No</div>
                    {trans.cardNo}
                  </div>

                  {/* <div className="table__col">
                    <div className="table__label">Device Sn</div>
                    {trans.devSN}
                  </div> */}

                  <div className="table__col">
                    <div className="table__label">Verify Mode</div>
                    {trans.verifyTypeName}
                  </div>

                  {/* <div className="table__col">
                    <div className="table__label">Log Name</div>
                    {trans.eventLogName}
                  </div> */}

                  {/* <div className="table__col">
                    <div className="table__label">Log Id</div>
                    {trans.eventLog}
                  </div> */}

                  <div className="table__col">
                    <div className="table__label">In/Out</div>
                    {trans.inOutStatus}
                  </div>

                  <div className="table__col">
                    <div className="table__label">Project</div>
                    {trans.projectName}
                  </div>
                </div>
              ))}

              {(!getAccessTransactionList || !getAccessTransactionList.length) && (
                <div className="table__row empty">
                  <div className="table__col">No Records available to show</div>
                </div>
              )}
            </div>
          </div>

          <div className="table__foot text-align-center">
            {canLoadMore && (
              <ButtonTheme
                hasSpinnerLoading={loading}
                textLabel="Load More"
                classButton="button-small button-stroke has-loader"
                onClick={() => setPage(page + 1)}
              />
            )}
          </div>
        </div>
      </div>
    </PageDashboard>
  );
};

export default EventsManagement;

import React from 'react'
import PageDashboard from '../../components/PageDashboard/PageDashboard'

const MancountReportTrade = () => {
  return (
    <PageDashboard pageTitle="Mancount Report (Trade)">
      {' '}
    </PageDashboard>
  )
}

export default MancountReportTrade

import en from './en';
import de from './de';
import it from './it';
import bl from './bl';
import cn from './cn';
import cz from './cz';
import dk from './dk';
import es from './es';

export default {
  en,
  de,
  it,
  bl,
  cn,
  cz,
  dk,
  es,
}

import { useMutation } from '@apollo/react-hooks';
import React, { useState } from 'react';
import ModalPopupCustom from '../../../components/ModalPopup/ModalPopup';
import InputField from '../../../components/InputField/InputField';
import { ADD_NEW_HIK_DEVICE } from '../../../queriesAndMutations';
import { toaster } from '../../../utils';

const AddNewHikDeviceModal = ({ show = false, onClose = () => {}, onUpdated = () => {} }) => {
  const [addNewHikDevice, addNewHikDeviceStatus] = useMutation(ADD_NEW_HIK_DEVICE);
  const [data, setData] = useState({
    devName: '',
    ip: '',
    port: '',
    userName: '',
    password: '',
  });

  const handleAddHikDevice = async () => {
    const { devName, ip, port, userName, password } = data;
    await addNewHikDevice({
      variables: {
        input: {
          devName,
          ip,
          port,
          userName,
          password,
        },
      },
    })
      .then(({ data: { addNewHikDevice: success } }) => {
        if (success) {
          toaster.success('Device name add');
          setData({
            devName: '',
            ip: '',
            port: '',
            userName: '',
            password: '',
          });
          onUpdated();
          onClose();
        } else {
          toaster.error('Device name failed');
        }
      })
      .catch((e) => {
        toaster.error('Device name failed');
        console.log('e', e);
      });
  };

  return (
    <ModalPopupCustom
      modalClass={`js-popup-sysadmin popup-medium ${show ? 'animation visible' : ''}`}
      modalTitle="Add New Device"
      onClickClose={() => {
        onClose();
        setData({
          devName: '',
          ip: '',
          port: '',
          userName: '',
          password: '',
        });
      }}
    >
      <div className="settings__device">
        <div className="settings__row">
          <InputField
            propertyName="devName"
            fieldName="devName"
            fieldType="text"
            fieldLabel="Device Name"
            setState={setData}
            state={data}
          />
          <InputField
            propertyName="ip"
            fieldName="ip"
            fieldType="text"
            fieldLabel="IP"
            setState={setData}
            state={data}
          />
          <InputField
            propertyName="port"
            fieldName="port"
            fieldType="text"
            fieldLabel="Port"
            setState={setData}
            state={data}
          />
          <InputField
            propertyName="userName"
            fieldName="userName"
            fieldType="text"
            fieldLabel="User Name"
            setState={setData}
            state={data}
          />
          <InputField
            propertyName="password"
            fieldName="password"
            fieldType="password"
            fieldLabel="Password"
            setState={setData}
            state={data}
          />
        </div>
        <div className="action-buttons algin-right">
          <button
            className="button button-small settings__button__space"
            onClick={handleAddHikDevice}
            disabled={addNewHikDeviceStatus.loading}
          >
            Add
          </button>
          <button
            className="button button-small settings__button__space settings__button--close"
            onClick={() => {
              onClose();
              setData({
                devName: '',
                ip: '',
                port: '',
                userName: '',
                password: '',
              });
            }}
            disabled={addNewHikDeviceStatus.loading}
          >
            Close
          </button>
        </div>
      </div>
    </ModalPopupCustom>
  );
};

export default AddNewHikDeviceModal;

import gql from 'graphql-tag';

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input)
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input)
  }
`;

export const IMPORT_USERS = gql`
  mutation importUsers($input: ImportUsersInput!) {
    importUsers(input: $input)
  }
`;

import React from 'react';
import Icon from '../../Icons/Icon';
import { useCookies } from 'react-cookie';
import { useMe } from '../../../myHooks';
import { USER_RIGHT_TYPES } from '../../../constants/user';

const Header = ({ handleSidebarVisible }) => {
  const {
    loading: userLoading,
    data: { me: user },
    error,
  } = useMe({ fetchPolicy: 'cache-and-network' });

  const [, setCookie] = useCookies(['jwt']);

  const logout = () => {
    setCookie('jwt', '', { path: '/' });
    localStorage.removeItem('jwt');
    window.location.reload();
  };

  const isAdmin = () => {
    if (!userLoading) {
      return user.rights === USER_RIGHT_TYPES.ADMIN;
    }

    return false;
  };

  return (
    <header className="header">
      <button className="header__burger" onClick={handleSidebarVisible}>
        {' '}
      </button>

      <div className="search">
        {isAdmin() && (
          <div className="search__head">
            <button className="search__start">
              <Icon className="search" name="search" />{' '}
            </button>

            <button className="search__direction">
              <Icon className="arrow-left" name="arrow-left" />{' '}
            </button>

            <input type="text" className="search__input" placeholder="Search or type a command" />
            <button className="search__result">⌘ F</button>
            <button className="search__close">
              <Icon className="close-circle" name="close-circle" />{' '}
            </button>
          </div>
        )}
      </div>

      <div className="header__control">{/* leave it blank for now */}</div>

      <div className="header__btns">
        <span>{user.email}</span>
        <button onClick={logout} className="header__link">
          Log out
        </button>
      </div>
    </header>
  );
};

export default Header;

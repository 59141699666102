import React from 'react'

const Colors = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Colors</h1>
      </div>
    </>
  )
}

export default Colors

import React from 'react'

const HeadingTags = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">HTML Heading Tags</h1>
      </div>

      <div className="ds__card">
        <div className="ds__class is-purple">H1</div>
        <div className="ds__card-inner">
          <h1>Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</h1>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-purple">H1</div>
        <div className="ds__card-inner">
          <h1>Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</h1>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-purple">H2</div>
        <div className="ds__card-inner">
          <h1>Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</h1>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-purple">H3</div>
        <div className="ds__card-inner">
          <h1>Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</h1>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-purple">H4</div>
        <div className="ds__card-inner">
          <h1>Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</h1>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-purple">H5</div>
        <div className="ds__card-inner">
          <h1>Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</h1>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-purple">H6</div>
        <div className="ds__card-inner">
          <h1>Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</h1>
        </div>
      </div>

    </>
  )
}

export default HeadingTags

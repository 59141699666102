import routes from './routes'
import Maintain from './Maintain'
import { setTitle } from './utils'
import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ME } from "./queriesAndMutations";
import i18n from "i18next";
import * as languageHelper from './utils/lang'
import { usePublicSettings } from './myHooks/useSettings'

const Root = () => {
  const {
    data,
  } = useQuery(ME);
  const { data: { publicSettings = {} } = {} } = usePublicSettings()

  if (publicSettings && publicSettings.system_status === 'DISABLE') {
    return Maintain(publicSettings.company.email)
  }

  return routes()
}

export default Root

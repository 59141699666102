import React, {useState} from 'react'
import { Link } from "react-router-dom"
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import InputField from '../../../components/InputField/InputField'
import TextInput from '../../../components/InputTheme'

const DSFields = () => {
  const [values, setValues] = useState({
    dummy: "",
    password: "",
    rememberMe: true,
  });

  const roleLists = [
    { id: 1, label: 'OCBC' },
    { id: 2, label: 'Admin' },
  ]

  // Access Date
  const [accessDateFrom, setAccessDateFrom] = useState(new Date())

  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Fields</h1>
      </div>

      <div className="ds__cards">
        <div className="ds__card">
          <div className="ds__class is-blue">TextField 1</div>
          <div className="ds__card-inner">
            <InputField
              propertyName="devicename"
              fieldName="devicename"
              fieldType="text"
              fieldLabel="Textfield 1"
              isRequired
            />
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-blue">TextField 2 With Icon</div>
          <div className="ds__card-inner">
          <TextInput
            classWrapper="field"
            classInput="field__input"
            propertyName="email"
            name="email"
            type="email"
            placeholder="Textfield 2"
            state={values}
            setState={setValues}
            isRequired
            icon="mail"
          />
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-blue">Selectbox</div>
          <div className="ds__card-inner">
            <div className="field">
              <div className="field__label">Select Dropdown</div>
              <div className="field__wrap">
                <Select
                  isSearchable
                  isClearable
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={roleLists[0]}
                  name="role"
                  options={roleLists}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-blue">Checkbox</div>
          <div className="ds__card-inner">
            <div className="field">
              <div className="field__label">{' '}</div>
              <div className="field__wrap">
                <label className="checkbox">
                  <input
                    className="checkbox__input"
                    type="checkbox"
                    value="rememberMe"
                  />
                  <span className="checkbox__inner">
                    <span className="checkbox__tick">&nbsp;</span>
                    <span className="checkbox__text">Checkbox Label with <Link to="/">Link</Link></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-blue">Datepicker</div>
          <div className="ds__card-inner">
            <div className="field">
              <div className="field__label">Sample Date</div>
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={accessDateFrom}
                  onChange={(date) => setAccessDateFrom(date)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DSFields

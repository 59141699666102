import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_PERSON = gql`
  mutation createPerson($input: CreatePersonInput!) {
    createPerson(input: $input)
  }
`;

export const UPDATE_PERSON = gql`
  mutation updatePerson($input: UpdatePersonInput!) {
    updatePerson(input: $input)
  }
`;

export const DELETE_PERSON = gql`
  mutation deletePerson($input: DeletePersonInput!) {
    deletePerson(input: $input)
  }
`;

export const ADD_NEW_DEVICE = gql`
  mutation addNewDevice($devSN: String!) {
    addNewDevice(devSN: $devSN)
  }
`;

export const CREATE_ACCESS_LEVEL = gql`
  mutation createAccessLevel($name: String!, $timeZoneId: Int!) {
    createAccessLevel(name: $name, timeZoneId: $timeZoneId)
  }
`;

export const EDIT_ACCESS_LEVEL = gql`
  mutation updateAccessLevel($id: Int!, $name: String!, $timeZoneId: Int!) {
    updateAccessLevel(id: $id, name: $name, timeZoneId: $timeZoneId)
  }
`;

export const DELETE_ACCESS_LEVEL = gql`
  mutation deleteAccessLevel($id: Int!) {
    deleteAccessLevel(id: $id)
  }
`;

export const ADD_ACCESS_LEVEL_DEVICE = gql`
  mutation addAccessLevelDevice($deviceId: Int!, $accessLevelId: Int!) {
    addAccessLevelDevice(deviceId: $deviceId, accessLevelId: $accessLevelId)
  }
`;

export const SYNC_USERS_FROM_DEVICE = gql`
  mutation syncUsersFromDevice($devSN: String!) {
    syncUsersFromDevice(devSN: $devSN)
  }
`;

export const RESET_DEVICE = gql`
  mutation resetDevice($devSN: String!) {
    resetDevice(devSN: $devSN)
  }
`;

import React, { useEffect, useState, useRef } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { makeStyles, CircularProgress } from '@material-ui/core';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import ReactExport from 'react-export-excel';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { get, find } from 'lodash';
import Icon from '../../components/Icons/Icon';
import InputField from '../../components/InputField/InputField';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import {
  DAILY_ENTRY_EXIT,
  GET_TRADE_LIST,
  GET_PERSON_LIST,
  GET_PROJECT_LIST,
} from '../../queriesAndMutations';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import SpinnerLoading from '../../components/SpinnerLoading/SpinnerLoading';
import SelectAsync from '../../components/SelectAsync';
import client from '../../apolloClient';
import { useMe } from '../../myHooks';
import { USER_RIGHT_TYPES } from '../../constants/user';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const DailyEntryExitReport = () => {
  // const [
  //   getPersonList,
  //   { data: { getPersonList: personalData } = {}, loading: loadingPersonalData },
  // ] = useLazyQuery(GET_PERSON_LIST, {
  //   fetchPolicy: 'network-only',
  // });
  const [
    getDailyEntryExitReports,
    { data: { getDailyEntryExitReports: data } = {}, refetch, loading },
  ] = useLazyQuery(DAILY_ENTRY_EXIT, {
    fetchPolicy: 'network-only',
  });
  const [getTradeList, { data: { getTradeList: tradeList } = {} }] = useLazyQuery(GET_TRADE_LIST, {
    fetchPolicy: 'network-only',
  });
  const [getProjectList, { data: { getProjectList: projectListData } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const tradeRef = useRef(null);
  const nameRef = useRef(null);
  const projectRef = useRef(null);
  const [entryDate, setEntryDate] = useState(new Date());
  const [trades, setTrades] = useState([]);
  const [dailyEntryExitList, setDailyEntryExitList] = useState([]);
  const [exportedDailyEntryExitList, setExportedDailyEntryExitList] = useState([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [page, setPage] = useState(0);
  const [isShowDownload, setIsShowDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [exportedPage, setExportedPage] = useState(0);
  const [forceReloadSelect, setForceReloadSelect] = useState(false);
  // const [personList, setPersonList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const classes = useStyles();

  const {
    data: { me: user },
  } = useMe();

  const INIT_FILTERS = {
    entryDate: entryDate,
    pin: '',
    userName: '',
    tradeId: '',
    projectId: '',
  };
  const [filters, setFilter] = useState(INIT_FILTERS);

  const formatFilter = (filters) => {
    return Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== '' || filters[key] === undefined || filters[key] === null) {
        if (key === 'entryDate') {
          acc[key] = moment(filters[key]).format('MM/DD/YYYY');
        } else {
          acc[key] = filters[key];
        }
      }
      return acc;
    }, {});
  };

  const handleOpenModalCSV = () => {
    setIsShowDownload(true);
  };

  const handleCloseModalCSV = () => {
    setIsShowDownload(false);
  };

  const onExportTransactionList = () => {
    setExportedDailyEntryExitList([]);
    setIsLoading(true);
    /*eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]*/
    for (let i = 0; i < totalPages; i++) {
      (function (index) {
        setTimeout(() => {
          getDailyEntryExitReports({
            variables: {
              page: index,
              pageSize: 100,
              ...formatFilter(filters),
            },
          });
          setExportedPage(index);
        }, 2000 * index); // Multiply by index to stagger the timeouts
      })(i);
    }
  };

  const onResetFilter = () => {
    const currentDate = new Date();
    setDailyEntryExitList([]);
    refetch();
    setCanLoadMore(true);
    getDailyEntryExitReports({
      variables: {
        page: 0,
        pageSize: 25,
        entryDate: currentDate,
      },
    });
    if (tradeRef && tradeRef.current) {
      tradeRef.current.clearValue();
    }
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }
    if (projectRef && projectRef.current) {
      projectRef.current.clearValue();
    }

    setFilter({ ...INIT_FILTERS });
    setEntryDate(currentDate);
  };

  const personListLoadOptions = async (search, loadedOptions, { page }) => {
    if (search) {
      const searchResult = find(loadedOptions, (option) => {
        if (
          option.data.label.toLowerCase().includes(search.toLowerCase()) ||
          option.data.value.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (searchResult) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page,
          },
        };
      }
    }
    const result = await client.query({
      query: GET_PERSON_LIST,
      variables: {
        page: search ? 0 : page,
        pageSize: 20,
        projectId: filters.projectId > 0 ? filters.projectId : null,
        userName: search,
      },
      fetchPolicy: 'network-only',
    });
    const newOptions = get(result, 'data.getPersonList.items', []).map((person) => ({
      value: person.userName,
      label: person.userName,
    }));
    const hasMore = get(result, 'data.getPersonList.totalPages', 0) > page;

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: search ? 1 : page + 1,
      },
    };
  };

  const onClickSearch = () => {
    setDailyEntryExitList([]);
    setCanLoadMore(true);
    if (page === 0) {
      getDailyEntryExitReports({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    } else {
      setPage(1);
    }
  };

  const onSelected = (option, field) => {
    if (option && option.value) {
      setFilter({ ...filters, [field]: option.value });
    } else {
      setFilter({ ...filters, [field]: '' });
    }
  };

  useEffect(() => {
    if (!trades || !trades.length) {
      getTradeList({
        variables: { page: 0 },
      });
    }

    if (
      !dailyEntryExitList ||
      (dailyEntryExitList && Array.isArray(dailyEntryExitList) && !dailyEntryExitList.length)
    ) {
      getDailyEntryExitReports({
        variables: { page: 0, entryDate },
      });
    }

    // if (personList.length === 0) {
    //   getPersonList();
    // }

    if (getProjectList && (!projectListData || !projectListData.length)) {
      getProjectList();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tradeList && Array.isArray(tradeList)) {
      const mappedTrades = tradeList.map((e) => {
        return {
          label: get(e, 'name', ''),
          id: get(e, 'id', ''),
        };
      });
      setTrades(mappedTrades);
    }
    //eslint-disable-next-line
  }, [tradeList]);

  useEffect(() => {
    if (projectListData) {
      setProjectList(
        projectListData.map((project) => ({ value: project.id, label: project.name }))
      );
    }
  }, [projectListData]);

  useEffect(() => {
    if (data && data.items && data.items.length) {
      const items = data.items.map((item) => ({
        ...item,
        entryDate: get(item, 'entryDate', 'NO IN') || 'NO IN',
        exitDate: get(item, 'exitDate', 'NO OUT') || 'NO OUT',
      }));
      if (isShowDownload) {
        setExportedDailyEntryExitList([...exportedDailyEntryExitList, ...items]);
      } else {
        setDailyEntryExitList([...dailyEntryExitList, ...items]);
      }
    }

    if (data && data.items && data.items.length < 25) {
      setCanLoadMore(false);
    }

    if (data && data.totalPages) {
      setTotalPages(data.totalPages);
    }

    //eslint-disable-next-line
  }, [data]);

  // useEffect(() => {
  //   if (personalData && personalData.items) {
  //     setPersonList(
  //       personalData.items.map((person) => ({ value: person.userName, label: person.userName }))
  //     );
  //   }
  //   //eslint-disable-next-line
  // }, [personalData]);

  useEffect(() => {
    if (exportedPage === totalPages - 1) {
      setIsLoading(false);
    }
    //eslint-disable-next-line
  }, [exportedPage, totalPages, exportedDailyEntryExitList]);

  useEffect(() => {
    if (!loading && page) {
      getDailyEntryExitReports({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    }

    if (data && (data.totalPages === 1 || page === data.totalPages - 1)) {
      setCanLoadMore(false);
    }

    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (isShowDownload) {
      onExportTransactionList();
    }

    //eslint-disable-next-line
  }, [isShowDownload]);

  useEffect(() => {
    setFilter({ ...filters, userName: '' });
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }

    setForceReloadSelect(true);

    // getPersonList({
    //   variables: {
    //     page: 0,
    //     pageSize: 10,
    //     projectId: filters.projectId,
    //   },
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.projectId]);

  useEffect(() => {
    if (forceReloadSelect) {
      setTimeout(() => {
        setForceReloadSelect(false);
      }, 1000);
    }
  }, [forceReloadSelect]);

  return (
    <PageDashboard pageTitle="Daily Entry Exit Report">
      <ModalPopup
        modalClass={`js-popup-syadmin popup-small ${isShowDownload ? 'animation visible' : ''}`}
        modalTitle="Export To CSV"
        onClickClose={handleCloseModalCSV}
      >
        {isLoading ? (
          <div className={classes.circularProgressWrapper}>
            Please do not close this pop up.
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="settings__fieldset">
              <div className="title card__title">
                Your Excel is ready. Click here to download
                <ExcelFile
                  element={
                    <button
                      className="button-circle button-small"
                      data-tip="Export to Excel"
                      data-for="export-to-excel"
                    >
                      <Icon className="download" name="download" />{' '}
                    </button>
                  }
                >
                  <ExcelSheet data={exportedDailyEntryExitList} name="Monthly-OnSite>">
                    <ExcelColumn label="User" value="userName" />
                    <ExcelColumn label="User ID" value="pin" />
                    <ExcelColumn label="Entry Date" value="entryDate" />
                    <ExcelColumn label="Exit Date" value="exitDate" />
                    <ExcelColumn label="Trade" value="tradeName" />
                    <ExcelColumn label="Company" value="companyName" />
                    {/* <ExcelColumn label="Project" value="projectName" /> */}
                  </ExcelSheet>
                </ExcelFile>
                <ReactTooltip id="export-to-excel" />
              </div>
            </div>
            <div className="popup__foot">
              <button className="js-popup-close popup__foot-cancel" onClick={handleCloseModalCSV}>
                Cancel
              </button>
            </div>
          </>
        )}
      </ModalPopup>
      <div className="report-management card">
        <div className="report-management__wrapper">
          <div className="report-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row">
                {/* <div className="field">
                  <div className="field__label">Name</div>

                  <div className="field__wrap">
                    <Select
                      ref={nameRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="userName"
                      onChange={(option) => onSelected(option, 'userName')}
                      options={personList}
                    />
                  </div>
                </div> */}

                <div className="field">
                  <div className="field__label">Name</div>

                  <div className="field__wrap">
                    {forceReloadSelect ? (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={true}
                        options={[]}
                      />
                    ) : (
                      <SelectAsync
                        selectRef={nameRef}
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue=""
                        name="userName"
                        onChange={(option) => onSelected(option, 'userName')}
                        loadOptions={personListLoadOptions}
                        additional={{
                          page: 0,
                        }}
                        noOptionsMessage="No users"
                      />
                    )}
                  </div>
                </div>

                <InputField
                  propertyName="pin"
                  fieldName="pin"
                  fieldType="text"
                  fieldLabel="User ID"
                  state={filters}
                  setState={setFilter}
                />

                <div className="field">
                  <div className="field__label">Entry Date</div>
                  <div className="field__wrap">
                    <DatePicker
                      showIcon
                      selected={entryDate}
                      onChange={(date) => {
                        setFilter({ ...filters, entryDate: date });
                        setEntryDate(date);
                      }}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Trade</div>
                  <div className="field__wrap">
                    <Select
                      ref={tradeRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="tradeId"
                      id="tradeId"
                      options={trades}
                      onChange={(option) => {
                        if (option && option.id !== '') {
                          setFilter({ ...filters, tradeId: option.id });
                        } else {
                          setFilter({ ...filters, tradeId: '' });
                        }
                      }}
                    />
                  </div>
                </div>

                {user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
                  <div className="field">
                    <div className="field__label">Project</div>

                    <div className="field__wrap">
                      <Select
                        ref={projectRef}
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        name="projectId"
                        options={projectList}
                        onChange={(option) =>
                          setFilter({ ...filters, projectId: get(option, 'value', 0) })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="settings__btns is-end">
              <button className="button button-stroke button-small" onClick={handleOpenModalCSV}>
                Export To Excel
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-stroke button-small" onClick={onResetFilter}>
                Reset
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-small" onClick={onClickSearch}>
                Search
                <Icon className="icon-search" name="icon-search" />
              </button>
            </div>
          </div>

          <div className="v-space-40"> </div>
          <div className="v-space-40 ml-1">
            Page {get(data, 'pageIndex', 0) + 1} of {Math.max(get(data, 'totalPages', 1), 1)} |
            Found total {get(data, 'totalCount', 0)} records
          </div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S.No</div>
              <div className="table__col">Name</div>
              <div className="table__col">User ID</div>
              <div className="table__col">Entry Date</div>
              <div className="table__col">Exit Date</div>
              <div className="table__col">Trade</div>
              <div className="table__col">Company</div>
              <div className="table__col">Project</div>
              {/* <div className="table__col">Actions</div> */}
            </div>

            {/* Table Content Body */}
            {dailyEntryExitList.map((trans, index) => (
              <div key={trans.id} className="table__row">
                <div className="table__col">
                  <div className="table__label">S.No</div>
                  {index + 1}
                </div>

                <div className="table__col">
                  <div className="table__label">Name</div>
                  {trans.userName ? trans.userName : 'Unknown User'}
                </div>

                <div className="table__col">
                  <div className="table__label">User ID</div>
                  {trans.pin ? trans.pin : 'Unknown User ID'}
                </div>

                <div className="table__col">
                  <div className="table__label">Entry Date</div>
                  {trans && trans.entryDate && trans.entryDate !== 'NO IN'
                    ? moment(trans.entryDate).utc(0).format('YYYY-MM-DD HH:mm:ss')
                    : 'NO IN'}
                </div>

                <div className="table__col">
                  <div className="table__label">Exit Date</div>
                  {trans && trans.exitDate && trans.exitDate !== 'NO OUT'
                    ? moment(trans.exitDate).utc(0).format('YYYY-MM-DD HH:mm:ss')
                    : 'NO OUT'}
                </div>

                <div className="table__col">
                  <div className="table__label">Trade</div>
                  {trans.tradeName > 0 ? trans.tradeName : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Company</div>
                  {trans.companyName ? trans.companyName : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Project</div>
                  {trans.projectName ? trans.projectName : ''}
                </div>

                {/* <div className="table__col">
                  <div className="table__label">Actions</div>
                </div> */}
              </div>
            ))}
            {dailyEntryExitList &&
            Array.isArray(dailyEntryExitList) &&
            dailyEntryExitList.length ? (
              <></>
            ) : (
              <div className="table__row empty">
                <div className="table__col">No Records Available To Show</div>
              </div>
            )}
          </div>
          {canLoadMore && (
            <div className="table__foot text-center">
              <button
                type="button"
                className="button button-stroke has-loader"
                onClick={() => setPage(page + 1)}
              >
                {loading && <SpinnerLoading />}
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </PageDashboard>
  );
};

export default DailyEntryExitReport;

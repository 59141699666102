import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import Icon from '../../components/Icons/Icon'
import InputField from '../../components/InputField/InputField'
import PageDashboard from '../../components/PageDashboard/PageDashboard'

const WorkingHoursReport = () => {
  const availableName = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Test Account' },
    { id: 3, label: 'Test new' },
    { id: 4, label: 'Test srini xid'}
  ]

  // Access Date
  const [accessDateFrom, setAccessDateFrom] = useState(new Date())

  return (
    <PageDashboard pageTitle="Working Hours Report">
      <div className="report-management card">
        <div className="card__head is-spacebetween">
          <div className="title card__title">
            Working Hours Report
            <button
              className="button-circle button-small"
              data-tip="Export to Excel"
              data-for="export-to-excel"
            >
              <Icon className="download" name="download" />
            </button>

            <ReactTooltip id="export-to-excel"/>
          </div>
        </div>

        <div className="report-management__wrapper">
          <div className="report-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row is-col-3">
                <div className="field">
                  <div className="field__label">Name</div>
                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="entry"
                      options={availableName}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Date</div>
                  <div className="field__wrap">
                    <DatePicker
                      showIcon
                      selected={accessDateFrom}
                      onChange={(date) => setAccessDateFrom(date)}
                    />
                  </div>
                </div>

                <InputField
                  propertyName="userid"
                  fieldName="userid"
                  fieldType="text"
                  fieldLabel='Card No.'
                />
              </div>

              <div className="settings__btns is-end">
                <button className="button button-stroke button-small">
                  Reset
                  <Icon className="repeat" name="repeat" />
                </button>
                <button className="button button-small">
                  Search
                  <Icon className="icon-search" name="icon-search" />
                </button>
              </div>
            </div>
          </div>

          <div className="v-space-40">{' '}</div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S.No</div>
              <div className="table__col">Name</div>
              <div className="table__col">Acces Date</div>
              <div className="table__col">Card No.</div>
              <div className="table__col">Working Hours</div>
              <div className="table__col">Actions</div>
            </div>

            {/* Table Content  */}
            <div className="table__row empty">
              <div className="table__col">
                No Records available to show
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageDashboard>
  )
}

export default WorkingHoursReport

import cn from 'classnames';
import React, { useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import PageDashboard from '../../components/PageDashboard/PageDashboard';

// Inner Components
import { useMutation } from '@apollo/react-hooks';
import { CREATE_PROJECT } from '../../queriesAndMutations';
import { toaster } from '../../utils';
import ProjectInformation from '../ProjectsManagement/components/ProjectInformation';
import ProjectEPSSSettingInformation from '../ProjectsManagement/components/ProjectEPSSSettingInformation';
import AccessLevel from '../ProjectsManagement/components/AccessLevel';
import { INIT_NEW_PROJECT_VALUES } from '../../constants/project';

const ProjectAdd = () => {
  const [values, setValues] = useState(INIT_NEW_PROJECT_VALUES);
  const scrollToProject = useRef(null);
  const scrollToEPSSSetting = useRef(null);
  const scrollToAccessLevel = useRef(null);
  const history = useHistory();
  const [createProject] = useMutation(CREATE_PROJECT);

  const navigation = [
    {
      title: 'Project',
      action: () => scrollToProject.current.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      title: 'EPSS Settings',
      action: () => scrollToEPSSSetting.current.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      title: 'Access Level',
      action: () => scrollToAccessLevel.current.scrollIntoView({ behavior: 'smooth' }),
    },
  ];

  const handleSaveProject = React.useCallback(() => {
    const _values = { ...values };
    Object.keys(_values).forEach((key) => {
      const value = _values[key];
      if (value === '' || value === null) {
        delete _values[key];
      }
    });
    createProject({
      variables: {
        input: {
          ..._values,
        },
      },
    })
      .then(({ data: { createProject: success } }) => {
        if (success) {
          toaster.success('Project created');
          history.push('/projects-management');
        }
      })
      .catch((e) => {
        console.log('e', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  console.log(values);
  return (
    <PageDashboard pageTitle="Add Project">
      <div className="settings">
        <div className="settings__menu">
          {navigation.map((x, index) => (
            <button
              className={cn('settings__link', {
                active: true,
              })}
              key={index}
              onClick={x.action}
            >
              {x.title}
            </button>
          ))}
        </div>

        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToProject}>
                {' '}
              </div>

              <div className="title settings__title text-color-white">Information</div>
              <ProjectInformation mode="add" values={values} setValues={setValues} />
            </div>

            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToEPSSSetting}>
                {' '}
              </div>

              <div className="title settings__title text-color-white">EPSS Settings</div>
              <ProjectEPSSSettingInformation values={values} setValues={setValues} />
            </div>

            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToAccessLevel}>
                {' '}
              </div>
              <div className="title settings__title text-color-white">Access Level</div>
              <AccessLevel values={values} setValues={setValues} />
            </div>
          </div>
          <button onClick={handleSaveProject} className="button button-small settings__button">
            Save
          </button>
        </div>
      </div>
    </PageDashboard>
  );
};

export default ProjectAdd;

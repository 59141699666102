import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const ACC_LEVEL_LIST = gql`
  query accLevelList {
    accLevelList
  }
`;

export const GET_PERSON_LIST = gql`
  query getPersonList(
    $page: Int
    $pageSize: Int
    $pin: String
    $userName: String
    $idCard: String
    $companyId: Int
    $tradeId: Int
    $nationality: String
    $projectId: Int
    $typeMasterList: String
    $dateFrom: String
    $dateTo: String
    $isRegisterFace: Boolean
  ) {
    getPersonList(
      page: $page
      pageSize: $pageSize
      pin: $pin
      userName: $userName
      idCard: $idCard
      companyId: $companyId
      tradeId: $tradeId
      nationality: $nationality
      projectId: $projectId
      typeMasterList: $typeMasterList
      dateFrom: $dateFrom
      dateTo: $dateTo
      isRegisterFace: $isRegisterFace
    )
  }
`;

export const GET_HDB_REPORT = gql`
  query getHDBReport(
    $page: Int
    $pageSize: Int
    $userPin: String
    $name: String
    $idCard: String
    $companyId: Int
    $tradeId: Int
    $nationality: String
    $projectId: Int
    $typeMasterList: String
    $dateFrom: String
    $dateTo: String
    $isRegisterFace: Boolean
  ) {
    getHDBReport(
      page: $page
      pageSize: $pageSize
      userPin: $userPin
      name: $name
      idCard: $idCard
      companyId: $companyId
      tradeId: $tradeId
      nationality: $nationality
      projectId: $projectId
      typeMasterList: $typeMasterList
      dateFrom: $dateFrom
      dateTo: $dateTo
      isRegisterFace: $isRegisterFace
    )
  }
`;

export const GET_PERSON = gql`
  query getPerson($id: Int) {
    getPerson(id: $id)
  }
`;

export const GET_ACCESS_TRANSACTION_LIST = gql`
  query getAccessTransactionList(
    $page: Int
    $pin: String
    $cardNo: String
    $devSN: String
    $inOutStatus: Int
    $verifyType: Int
    $eventLog: Int
    $dateFrom: String
    $dateTo: String
    $userName: String
    $companyId: Int
    $projectId: Int
  ) {
    getAccessTransactionList(
      page: $page
      pin: $pin
      cardNo: $cardNo
      devSN: $devSN
      inOutStatus: $inOutStatus
      verifyType: $verifyType
      eventLog: $eventLog
      dateFrom: $dateFrom
      dateTo: $dateTo
      userName: $userName
      companyId: $companyId
      projectId: $projectId
    )
  }
`;

export const SEARCH_NEW_DEVICES = gql`
  query searchNewDevices {
    searchNewDevices
  }
`;

export const LIST_DEVICES = gql`
  query listDevices($page: Int) {
    listDevices(page: $page)
  }
`;

export const LIST_TIMEZONE = gql`
  query listTimeZone($page: Int) {
    listTimeZone(page: $page)
  }
`;

export const ACC_LEVEL_LIST_WITH_DEVICES = gql`
  query accLevelListWithDevices {
    accLevelListWithDevices
  }
`;

export const LIST_ACC_LEVEL_DEVICES = gql`
  query listAccLevelDevices($page: Int, $accessLevelId: Int!) {
    listAccLevelDevices(page: $page, accessLevelId: $accessLevelId)
  }
`;

export const DASHBOARD = gql`
  query getDashboard($dateFrom: String, $dateTo: String) {
    getDashboard(dateFrom: $dateFrom, dateTo: $dateTo)
  }
`;

export const DETAIL_ACCESS = gql`
  query getDetailedAccess(
    $page: Int
    $userName: String
    $inOutStatus: Int
    $resultType: String
    $dateFrom: String
    $dateTo: String
    $pin: String
    $devSN: String
    $cardNo: String
    $verifyType: Int
    $eventLog: Int
    $entryDate: String
    $companyId: Int
    $tradeId: Int
    $keyWord: String
    $projectId: Int
  ) {
    getDetailedAccess(
      page: $page
      userName: $userName
      inOutStatus: $inOutStatus
      resultType: $resultType
      dateFrom: $dateFrom
      dateTo: $dateTo
      pin: $pin
      devSN: $devSN
      cardNo: $cardNo
      verifyType: $verifyType
      eventLog: $eventLog
      entryDate: $entryDate
      companyId: $companyId
      tradeId: $tradeId
      keyWord: $keyWord
      projectId: $projectId
    )
  }
`;

export const EPSS_REPORTS = gql`
  query getEPSSReports($dateFrom: String, $dateTo: String, $projectId: Int) {
    getEPSSReports(dateFrom: $dateFrom, dateTo: $dateTo, projectId: $projectId)
  }
`;

export const GET_TRADE_LIST = gql`
  query getTradeList {
    getTradeList
  }
`;

export const DAILY_ENTRY_EXIT = gql`
  query getDailyEntryExitReports(
    $page: Int
    $pin: String
    $entryDate: String
    $userName: String
    $tradeId: Int
    $projectId: Int
  ) {
    getDailyEntryExitReports(
      page: $page
      pin: $pin
      entryDate: $entryDate
      userName: $userName
      tradeId: $tradeId
      projectId: $projectId
    )
  }
`;

export const PASSPORT_EXPIRING_REPORT = gql`
  query getPassportExpiringReport(
    $page: Int
    $pin: String
    $entryDate: String
    $userName: String
    $tradeId: Int
    $projectId: Int
  ) {
    getPassportExpiringReport(
      page: $page
      pin: $pin
      entryDate: $entryDate
      userName: $userName
      tradeId: $tradeId
      projectId: $projectId
    )
  }
`;

export const PERSON_ATTENDANCE_REPORT = gql`
  query getPersonAttendanceReport(
    $page: Int
    $dateFrom: String
    $dateTo: String
    $companyId: Int
    $tradeId: Int
    $pin: String
    $nationality: String
    $projectId: Int
    $isInAndOutStatus: String
  ) {
    getPersonAttendanceReport(
      page: $page
      dateFrom: $dateFrom
      dateTo: $dateTo
      companyId: $companyId
      tradeId: $tradeId
      pin: $pin
      nationality: $nationality
      projectId: $projectId
      isInAndOutStatus: $isInAndOutStatus
    )
  }
`;

export const TIME_ATTENDANCE_REPORT = gql`
  query getTimeAttendanceReport(
    $page: Int
    $dateFrom: String
    $dateTo: String
    $companyId: Int
    $tradeId: Int
    $pin: String
    $nationality: String
    $projectId: Int
    $isInAndOutStatus: String
  ) {
    getTimeAttendanceReport(
      page: $page
      dateFrom: $dateFrom
      dateTo: $dateTo
      companyId: $companyId
      tradeId: $tradeId
      pin: $pin
      nationality: $nationality
      projectId: $projectId
      isInAndOutStatus: $isInAndOutStatus
    )
  }
`;

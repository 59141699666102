import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import Icon from '../../../components/Icons/Icon';
import InputField from '../../../components/InputField/InputField';
// import ButtonTheme from '../../../components/ButtonXid/Button';
import ModalPopup from '../../../components/ModalPopup/ModalPopup';
import { USER_RIGHT_LISTS, USER_RIGHT_TYPES } from '../../../constants/user';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_USER,
  GET_USER_LIST,
  UPDATE_USER,
  DELETE_USER,
  // ACC_LEVEL_LIST,
  GET_PROJECT_LIST,
} from '../../../queriesAndMutations';
import { findByLabel, omitObject, toaster, findByValue } from '../../../utils';
import { useMe } from '../../../myHooks';

const Users = ({ triggerRefetch = 0 }) => {
  const { data: { me: user } = {} } = useMe();
  const USER_INIT = {
    id: '',
    email: '',
    rights: USER_RIGHT_LISTS[0].label,
    password: '',
    project_id: '',
  };

  const [modalDeleteIsOpen, setModelDeleteIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [userEdited, setUserEdited] = useState(USER_INIT);
  const [deleteUserId, setDeleteUserId] = useState('');
  const ROLE_LISTS = [
    ...USER_RIGHT_LISTS,
    ...(user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN
      ? [
          {
            id: 'GENERAL_ADMIN',
            label: USER_RIGHT_TYPES.GENERAL_ADMIN,
          },
        ]
      : []),
  ];

  // Query
  const [
    getUserList,
    { data: { getUserList: userListData } = {}, refetch: refetchUserList, loading },
  ] = useLazyQuery(GET_USER_LIST, {
    fetchPolicy: 'network-only',
  });
  const [getUser, { data: { getUser: userData } = {}, loading: loadingUser }] = useLazyQuery(
    GET_USER,
    {
      fetchPolicy: 'network-only',
    }
  );
  // const { data: { accLevelList = [] } = {}, loading: loadingAccLevelList } = useQuery(
  //   ACC_LEVEL_LIST,
  //   {
  //     fetchPolicy: 'cache-and-network',
  //   }
  // );
  // const accessLevelOptions = accLevelList
  //   ? accLevelList.map((accLevel) => ({ value: accLevel.id, label: accLevel.name }))
  //   : [];

  const { data: { getProjectList: projectList = [] } = {}, loading: loadingProjectList } = useQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const projectOptions = projectList
    ? projectList.map((project) => ({ value: project.id, label: project.name }))
    : [];

  // Mutations
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);

  const handleOpenDeleteModal = (id) => {
    setDeleteUserId(id);
    setModelDeleteIsOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteUserId('');
    setModelDeleteIsOpen(false);
  };

  const handleOpenEditModal = (id) => {
    getUser({
      variables: {
        id,
      },
    });
    setModalEditIsOpen(true);
  };

  const handleCloseEditModal = () => {
    setUserEdited(USER_INIT);
    setModalEditIsOpen(false);
  };

  const handleSaveUser = () => {
    if (userEdited && userEdited.id) {
      updateUser({
        variables: {
          input: {
            ...omitObject(userEdited, ['first_name', 'last_name', 'middle_name', 'created_at']),
          },
        },
      })
        .then(({ data: { updateUser: success } }) => {
          if (success) {
            toaster.success('User updated success.');
          } else {
            toaster.error('User updated failed.');
          }
        })
        .catch((error) => {
          console.log(error);
          toaster.error('User updated failed.');
        });
      setUserEdited(USER_INIT);
      setModalEditIsOpen(false);
      refetchUserList();
    }
  };

  const handleDeleteUser = () => {
    if (deleteUserId) {
      deleteUser({
        variables: {
          input: {
            id: deleteUserId,
          },
        },
      })
        .then(({ data: { deleteUser: success } }) => {
          if (success) {
            toaster.success('User deleted success.');
          } else {
            toaster.error('User deleted failed.');
          }
          refetchUserList();
        })
        .catch((error) => {
          console.log(error);
          toaster.error('User deleted failed.');
        });
      setDeleteUserId('');
      setModelDeleteIsOpen(false);
    }
  };

  useEffect(() => {
    getUserList({
      variables: { page: 0 },
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserEdited({ ...userData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, JSON.stringify(userData)]);

  useEffect(() => {
    if (refetchUserList && typeof refetchUserList === 'function') {
      refetchUserList();
    }
  }, [triggerRefetch, refetchUserList]);

  return (
    <>
      {/* Table Content */}
      <div className="table__wrapper">
        {/* Table Content Header */}
        <div className="table__row">
          <div className="table__col">Username</div>
          <div className="table__col">Role</div>
          <div className="table__col">Email</div>
          <div className="table__col">Date Created</div>
          <div className="table__col">Actions</div>
        </div>

        {/* Table Content Inner */}
        {!loading &&
          userListData &&
          userListData.map((user, index) => {
            return (
              <div key={index} className="table__row">
                <div className="table__col">
                  <div className="table__label">Username</div>
                  {user.email}
                </div>
                <div className="table__col">
                  <div className="table__label">Role</div>
                  {user.rights}
                </div>
                <div className="table__col">
                  <div className="table__label">Email</div>
                  {user.email}
                </div>
                <div className="table__col">
                  <div className="table__label">Date Created</div>
                  {user.created_at}
                </div>
                <div className="table__col">
                  <div className="table__label">Actions</div>
                  <div className="table__control">
                    <button
                      className="table__button"
                      data-tip="Edit"
                      data-for="table-admin-users-edit-2"
                      onClick={() => handleOpenEditModal(user.id)}
                      label="Edit"
                    >
                      <Icon className="edit" name="edit" />
                    </button>
                    <button
                      className="table__button"
                      data-tip="Delete"
                      data-for="table-admin-users-delete-2"
                      onClick={() => handleOpenDeleteModal(user.id)}
                      label="Delete"
                    >
                      <Icon className="trash" name="trash" />
                    </button>
                  </div>
                  <ReactTooltip id="table-admin-users-edit-2" />
                  <ReactTooltip id="table-admin-users-delete-2" />
                </div>
              </div>
            );
          })}
      </div>

      {/* Table Footer */}
      <div className="table__foot text-center">
        {/* <ButtonTheme
          hasSpinnerLoading
          textLabel="Load More"
          classButton="admin-management_load button-small button-stroke has-loader"
        /> */}
      </div>

      {/* Modal Delete */}
      <ModalPopup
        modalClass={`js-popup-sysadmin popup-small ${modalDeleteIsOpen ? 'animation visible' : ''}`}
        modalTitle="Delete"
        onClickClose={handleCloseDeleteModal}
      >
        <div className="popup__text">Are you sure you want to delete?</div>
        <div className="popup__foot">
          <button className="js-popup-close popup__foot-cancel" onClick={handleCloseDeleteModal}>
            {' '}
            Cancel{' '}
          </button>
          <button className="button button-small" onClick={handleDeleteUser}>
            Delete
            <Icon className="trash" name="trash" />
          </button>
        </div>
      </ModalPopup>

      {/* Modal Edit */}
      <ModalPopup
        modalClass={`js-popup-sysadmin popup-small ${modalEditIsOpen ? 'animation visible' : ''}`}
        modalTitle="Edit"
        onClickClose={handleCloseEditModal}
      >
        <div className="settings__fieldset">
          {/* <InputField
            propertyName="username"
            fieldName="username"
            fieldType="text"
            fieldLabel="Username"
            state={{ username: userEdited.email }}
          /> */}
          <InputField
            propertyName="email"
            fieldName="email"
            fieldType="email"
            fieldLabel="Email"
            state={userEdited}
            setState={userEdited.id ? () => {} : setUserEdited}
            readOnly={userEdited.id}
          />

          <InputField
            propertyName="password"
            fieldName="password"
            fieldType="password"
            fieldLabel="Password"
            fieldPlaceholder="*******"
            state={userEdited}
            setState={setUserEdited}
          />

          <div className="field">
            <div className="field__label">Role</div>

            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue={findByLabel(ROLE_LISTS, userEdited.rights) || ROLE_LISTS[0]}
                name="rights"
                options={ROLE_LISTS}
                value={findByLabel(ROLE_LISTS, userEdited.rights)}
                onChange={(option) => setUserEdited({ ...userEdited, rights: option.label })}
              />
            </div>
          </div>

          {/* {userEdited.rights === USER_RIGHT_TYPES.ADMIN && (
            <div className="field">
              <div className="field__label">Access Level</div>

              <div className="field__wrap">
                <Select
                  isSearchable
                  isClearable
                  className="basic-single"
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  name="accessLevelIds"
                  isLoading={loadingAccLevelList}
                  isDisabled={loadingAccLevelList}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? 'grey' : 'red',
                    }),
                    multiValue: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: '#1a1a1a',
                    }),
                    multiValueLabel: (baseStyles) => ({
                      ...baseStyles,
                      color: '#ffffff',
                      fontSize: '100%',
                      lineHeight: '85%',
                    }),
                    multiValueRemove: (baseStyles) => ({
                      ...baseStyles,
                      padding: '4px',
                      borderRadius: '50%',
                    }),
                  }}
                  value={
                    userEdited.access_level_id
                      ? findByValue(accessLevelOptions, userEdited.access_level_id)
                      : undefined
                  }
                  defaultValue={
                    userEdited.access_level_id
                      ? findByValue(accessLevelOptions, userEdited.access_level_id)
                      : undefined
                  }
                  onChange={(option) =>
                    setUserEdited({ ...userEdited, access_level_id: option.value })
                  }
                  options={accessLevelOptions}
                />
              </div>
            </div>
          )} */}

          {userEdited.rights === USER_RIGHT_TYPES.ADMIN && (
            <div className="field">
              <div className="field__label">Project</div>

              <div className="field__wrap">
                <Select
                  isSearchable
                  isClearable
                  className="basic-single"
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  name="projectId"
                  isLoading={loadingProjectList}
                  isDisabled={loadingProjectList}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? 'grey' : 'red',
                    }),
                    multiValue: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: '#1a1a1a',
                    }),
                    multiValueLabel: (baseStyles) => ({
                      ...baseStyles,
                      color: '#ffffff',
                      fontSize: '100%',
                      lineHeight: '85%',
                    }),
                    multiValueRemove: (baseStyles) => ({
                      ...baseStyles,
                      padding: '4px',
                      borderRadius: '50%',
                    }),
                  }}
                  value={
                    userEdited.project_id
                      ? findByValue(projectOptions, userEdited.project_id)
                      : undefined
                  }
                  defaultValue={
                    userEdited.project_id
                      ? findByValue(projectOptions, userEdited.project_id)
                      : undefined
                  }
                  onChange={(option) => setUserEdited({ ...userEdited, project_id: option.value })}
                  options={projectOptions}
                />
              </div>
            </div>
          )}
        </div>

        <div className="popup__foot">
          <button className="js-popup-close popup__foot-cancel" onClick={handleCloseEditModal}>
            {' '}
            Cancel{' '}
          </button>
          <button className="button button-small" onClick={handleSaveUser}>
            Save
            <Icon className="arrow-right" name="arrow-right" />
          </button>
        </div>
      </ModalPopup>
    </>
  );
};

export default Users;

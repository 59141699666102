import React, { useState } from 'react'

// InnerComponents
import DSPageStructure from './PageStructure/PageStructure'
import DSHeadingTags from './HeadingTags/HeadingTags'
import DSHeadingStyles from './HeadingStyles/HeadingStyles'
import DSOtherHTMLTags from './OtherHTMLTags/OtherHTMLTags'
import DSButtons from './Buttons/Buttons'
import DSIcons from './DSIcons/DSIcons'
import DSTexts from './Texts/Texts'
import DSColors from './Colors/Colors'
import DSFields from './DSFields/DSFields'
import DSCards from './DSCards/DSCards'
import DSTooltips from './DSTooltips/DSTooltips'
import DSTables from './DSTables/DSTables'
import DSModal from './DSModal/DSModal'
import DSSettings from './DSSettings/DSSettings'
import DSSpace from './DSSpace/DSSpace'

const DesignSystem = () => {
  const DSMenu = [
    "Page Structure",
    "Heading Tags",
    "Heading Styles",
    "Other HTML Tags",
    "Buttons",
    "Icons",
    "Texts",
    "Colors",
    "Fields",
    "Tooltips",
    "Cards",
    "Tables",
    "Modal",
    "Settings",
    "Space"
  ]

  const [activeDSMenu, setActiveDSMenu] = useState(DSMenu[0])

  return (
    <div className="page ds__page">
      <div className="pre-page__container">
        <div className="ds__card">
          <div className="ds__class">Design System</div>

          <div className="ds__card-inner">
            <div className="group__button">
              {DSMenu.map((x, index) => (
                <button
                  type="button"
                  className={`ds__button ${x === activeDSMenu ? "active" : ""}`}
                  key={index}
                  onClick={() => setActiveDSMenu(x)}
                >
                  {x}
                </button>
              ))}

            </div>
          </div>
        </div>

        {activeDSMenu === DSMenu[0] && <DSPageStructure />}
        {activeDSMenu === DSMenu[1] && <DSHeadingTags />}
        {activeDSMenu === DSMenu[2] && <DSHeadingStyles />}
        {activeDSMenu === DSMenu[3] && <DSOtherHTMLTags />}
        {activeDSMenu === DSMenu[4] && <DSButtons />}
        {activeDSMenu === DSMenu[5] && <DSIcons />}
        {activeDSMenu === DSMenu[6] && <DSTexts />}
        {activeDSMenu === DSMenu[7] && <DSColors />}
        {activeDSMenu === DSMenu[8] && <DSFields />}
        {activeDSMenu === DSMenu[9] && <DSTooltips />}
        {activeDSMenu === DSMenu[10] && <DSCards />}
        {activeDSMenu === DSMenu[11] && <DSTables />}
        {activeDSMenu === DSMenu[12] && <DSModal />}
        {activeDSMenu === DSMenu[13] && <DSSettings />}
        {activeDSMenu === DSMenu[14] && <DSSpace />}
      </div>
    </div>
  )
}

export default DesignSystem

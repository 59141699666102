import gql from 'graphql-tag';

export const UPDATE_DEVICE_TO_MASTER = gql`
  mutation updateDeviceToMaster($input: UpdateDeviceToMasterInput!) {
    updateDeviceToMaster(input: $input)
  }
`;

export const UPDATE_DEVICE_NAME = gql`
  mutation updateDeviceName($input: UpdateDeviceNameInput) {
    updateDeviceName(input: $input)
  }
`;

import React from 'react'
import Icon from '../../../components/Icons/Icon'

const DSIcons = () => {
  const iconName = [
    {
      name: 'Sun',
      class: 'sun'
    },
    {
      name: 'Moon',
      class: 'moon'
    },
    {
      name: 'Arrow Down',
      class: 'arrow-down'
    },
    {
      name: 'Arrow Next',
      class: 'arrow-next'
    },
    {
      name: 'Arrow Right',
      class: 'arrow-right'
    },
    {
      name: 'Arrow Bottom',
      class: 'arrow-bottom'
    },
    {
      name: 'Arrow Top',
      class: 'arrow-top'
    },
    {
      name: 'Arrows Up Down',
      class: 'arrows-up-down'
    },
    {
      name: 'Store',
      class: 'store'
    },
    {
      name: 'Profile Circle',
      class: 'profile-circle'
    },
    {
      name: 'Lightning',
      class: 'lightning'
    },
    {
      name: 'Plus',
      class: 'plus'
    },
    {
      name: 'Plus Circle',
      class: 'plus-circle'
    },
    {
      name: 'Pie Chart',
      class: 'pie-chart'
    },
    {
      name: 'Home',
      class: 'home'
    },
    {
      name: 'Diamond',
      class: 'diamond'
    },
    {
      name: 'Promotion',
      class: 'promotion'
    },
    {
      name: 'Help',
      class: 'help'
    },
    {
      name: 'Icon Help',
      class: 'icon-help'
    },
    {
      name: 'Icon Search',
      class: 'icon-search'
    },
    {
      name: 'Close Circle',
      class: 'close-circle'
    },
    {
      name: 'Close',
      class: 'close'
    },
    {
      name: 'Photos',
      class: 'photos'
    },
    {
      name: 'Add',
      class: 'add'
    },
    {
      name: 'Check',
      class: 'check'
    },
    {
      name: 'Check Circle',
      class: 'check-all'
    },
    {
      name: 'Trash',
      class: 'trash'
    },
    {
      name: 'Message',
      class: 'message'
    },
    {
      name: 'Messages',
      class: 'messages'
    },
    {
      name: 'More Horizontal',
      class: 'more-horizontal'
    },
    {
      name: 'Notification',
      class: 'notification'
    },
    {
      name: 'Bar Chart',
      class: 'bar-chart'
    },
    {
      name: 'Grid',
      class: 'grid'
    },
    {
      name: 'Leaderboard',
      class: 'leaderboard'
    },
    {
      name: 'Ticket',
      class: 'ticket'
    },
    {
      name: 'Shopping Bag',
      class: 'shopping-bag'
    },
    {
      name: 'Info',
      class: 'info'
    },
    {
      name: 'Info Stroke',
      class: 'info-stroke'
    },
    {
      name: 'Activity',
      class: 'activity'
    },
    {
      name: 'Link',
      class: 'link'
    },
    {
      name: 'Heart',
      class: 'heart'
    },
    {
      name: 'Heart Fill',
      class: 'heart-fill'
    },
    {
      name: 'basket',
      class: 'basket'
    },
    {
      name: 'Video Recorder',
      class: 'video-recorder'
    },
    {
      name: 'Phone',
      class: 'phone'
    },
    {
      name: 'Design',
      class: 'design'
    },
    {
      name: 'Multiselect',
      class: 'multiselect'
    },
    {
      name: 'Schedule',
      class: 'schedule'
    },
    {
      name: 'Facebook',
      class: 'facebook'
    },
    {
      name: 'Facebook Fill',
      class: 'facebook-fill'
    },
    {
      name: 'Twitter',
      class: 'twitter'
    },
    {
      name: 'Instagram',
      class: 'instagram'
    },
    {
      name: 'Pinterest',
      class: 'pinterest'
    },
    {
      name: 'Mail',
      class: 'mail'
    },
    {
      name: 'Payment',
      class: 'payment'
    },
    {
      name: 'Edit',
      class: 'edit'
    },
    {
      name: 'Upload',
      class: 'upload'
    },
    {
      name: 'Expand',
      class: 'expand'
    },
    {
      name: 'Calendar',
      class: 'calendar'
    },
    {
      name: 'List',
      class: 'list'
    },
    {
      name: 'Clock',
      class: 'clock'
    },
    {
      name: 'Star Stroke',
      class: 'star-stroke'
    },
    {
      name: 'Star Fill',
      class: 'star-fill'
    },
    {
      name: 'Smile',
      class: 'smile'
    },
    {
      name: 'Repeat',
      class: 'repeat'
    },
    {
      name: 'Mobile',
      class: 'mobile'
    },
    {
      name: 'Tablet',
      class: 'tablet'
    },
    {
      name: 'Desktop',
      class: 'desktop'
    },
    {
      name: 'Filter',
      class: 'filter'
    },
    {
      name: 'Mouse',
      class: 'mouse'
    },
    {
      name: 'Image',
      class: 'image'
    },
    {
      name: 'Image Stroke',
      class: 'image-stroke'
    },
    {
      name: 'Video',
      class: 'video'
    },
    {
      name: 'Video Stroke',
      class: 'video-stroke'
    },
    {
      name: 'Setting',
      class: 'setting'
    },
    {
      name: 'File Add',
      class: 'file-add'
    },
    {
      name: 'Copy',
      class: 'copy'
    },
    {
      name: 'User',
      class: 'user'
    },
    {
      name: 'Lock',
      class: 'lock'
    },
    {
      name: 'Donut Chart',
      class: 'donut-chart'
    }
  ]

  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Icons</h1>
      </div>

      <div className="ds__cards is-col-6">
        {iconName.map((item) => {
          return (
            <div className="ds__card" key={item.name}>
              <div className="ds__class is-purple">{item.name}</div>
              <div className="ds__card-inner text-align-center">
                <Icon className={item.class} name={item.class} size="24" />
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default DSIcons

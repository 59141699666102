import React from 'react';
import Select from 'react-select';
import Icon from '../../../components/Icons/Icon';
import CheckboxField from '../../../components/CheckboxField/CheckboxField';
import ButtonTheme from '../../../components/ButtonXid/Button';
import { USER_RIGHT_LISTS } from '../../../constants/user';

const Roles = () => {
  // const roleLists = [
  //   { id: 1, label: 'OCBC' },
  //   { id: 2, label: 'Admin' },
  // ]

  return (
    <>
      <div className="admin-management__fieldset">
        <div className="settings__fieldset">
          <div className="field field-flex">
            <div className="field__label">Roles List</div>

            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue={USER_RIGHT_LISTS[0]}
                name="role"
                options={USER_RIGHT_LISTS}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Table Content */}
      <div className="table__wrapper roles">
        {/* Table Content Header */}
        <div className="table__row">
          <div className="table__col">Page Name</div>
          <div className="table__col">View</div>
          <div className="table__col">Add</div>
          <div className="table__col">Edit</div>
          <div className="table__col">Delete</div>
        </div>

        {/* Table Content Inner */}
        <div className="table__row">
          <div className="table__col">
            <div className="table__label">Page Name</div>
            Dashboard
          </div>

          <div className="table__col">
            <div className="table__label">View</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Add</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Edit</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Delete</div>
            <CheckboxField defaultChecked={true} />
          </div>
        </div>

        <div className="table__row">
          <div className="table__col">
            <div className="table__label">Page Name</div>
            User Management
          </div>

          <div className="table__col">
            <div className="table__label">View</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Add</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Edit</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Delete</div>
            <CheckboxField defaultChecked={true} />
          </div>
        </div>

        <div className="table__row">
          <div className="table__col">
            <div className="table__label">Page Name</div>
            Event Management
          </div>

          <div className="table__col">
            <div className="table__label">View</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Add</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Edit</div>
            <CheckboxField defaultChecked={true} />
          </div>
          <div className="table__col">
            <div className="table__label">Delete</div>
            <CheckboxField defaultChecked={true} />
          </div>
        </div>

        <div className="table__row">
          <div className="table__col">
            <div className="table__label">Page Name</div>
            Reports
          </div>

          <div className="table__col">
            <div className="table__label">View</div>
            <CheckboxField />
          </div>
          <div className="table__col">
            <div className="table__label">Add</div>
            <CheckboxField />
          </div>
          <div className="table__col">
            <div className="table__label">Edit</div>
            <CheckboxField />
          </div>
          <div className="table__col">
            <div className="table__label">Delete</div>
            <CheckboxField />
          </div>
        </div>

        <div className="table__row">
          <div className="table__col">
            <div className="table__label">Page Name</div>
            -- Standard Reports
          </div>

          <div className="table__col">
            <div className="table__label">View</div>
            <CheckboxField />
          </div>
          <div className="table__col">
            <div className="table__label">Add</div>
            <CheckboxField />
          </div>
          <div className="table__col">
            <div className="table__label">Edit</div>
            <CheckboxField />
          </div>
          <div className="table__col">
            <div className="table__label">Delete</div>
            <CheckboxField />
          </div>
        </div>
      </div>

      {/* Table Footer */}
      <div className="table__foot">
        <ButtonTheme
          textLabel="Update"
          classButton="button button-small has-loader"
          hasChildrenIcon
        >
          <Icon className="arrow-right" name="arrow-right" />
        </ButtonTheme>
      </div>
    </>
  );
};

export default Roles;

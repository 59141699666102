import React, { useState } from 'react';
import ModalPopupCustom from '../../../components/ModalPopup/ModalPopup';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { LIST_TIMEZONE, CREATE_ACCESS_LEVEL } from '../../../queriesAndMutations';
import InputField from '../../../components/InputField/InputField';
import Select from 'react-select';
import Icon from '../../../components/Icons/Icon';
import { EDIT_ACCESS_LEVEL } from '../../../queriesAndMutations/personMutations';

const FormAccessLevel = ({
  modalAddClusterIsOpen,
  handleCloseClusterModal,
  refetch,
  mode = 'add',
  currentAccessLevel = null,
}) => {
  const { data: { listTimeZone = [] } = {}, loading } = useQuery(LIST_TIMEZONE, {
    page: 0,
  });

  const [values, setValues] = useState({
    name: '',
    timeZoneId: 0,
  });

  const [createAccessLevel, createAccessLevelStatus] = useMutation(CREATE_ACCESS_LEVEL);
  const [updateAccessLevel, updateAccessLevelStatus] = useMutation(EDIT_ACCESS_LEVEL);

  React.useEffect(() => {
    if (currentAccessLevel && currentAccessLevel.id) {
      setValues({
        name: currentAccessLevel.name,
        timeZoneId: currentAccessLevel.timeZoneId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccessLevel && currentAccessLevel.id]);

  const onAddDevice = async () => {
    try {
      if (mode === 'add') {
        await createAccessLevel({
          variables: {
            ...values,
            timeZoneId: values.timeZoneId,
          },
        });
      } else {
        await updateAccessLevel({
          variables: {
            ...values,
            timeZoneId: values.timeZoneId,
            id: currentAccessLevel.id,
          },
        });
      }

      refetch();
      handleCloseClusterModal();
    } catch (e) {}
  };

  const selectedOption = listTimeZone && listTimeZone.find((item) => item.id === values.timeZoneId);

  return (
    <ModalPopupCustom
      modalClass={`js-popup-sysadmin popup-small ${
        modalAddClusterIsOpen ? 'animation visible' : ''
      }`}
      modalTitle={mode === 'add' ? 'Add Access Level' : 'Edit Access Level'}
      onClickClose={handleCloseClusterModal}
    >
      <div className="settings__fieldset">
        <InputField
          propertyName="name"
          fieldName="name"
          fieldType="text"
          fieldLabel="Name"
          setState={setValues}
          state={values}
        />
      </div>

      <div className="field">
        <div className="field__label">TimeZone</div>

        <div className="field__wrap">
          <Select
            isSearchable
            isClearable
            className="basic-single"
            classNamePrefix="select"
            defaultValue={
              selectedOption ? { id: selectedOption.id, label: selectedOption.name } : ''
            }
            value={selectedOption ? { id: selectedOption.id, label: selectedOption.name } : ''}
            name="timeZoneId"
            onChange={(option) => setValues({ ...values, timeZoneId: option.id })}
            options={
              listTimeZone ? listTimeZone.map((zone) => ({ id: zone.id, label: zone.name })) : []
            }
          />
        </div>
      </div>

      <div className="popup__foot">
        <button className="js-popup-close popup__foot-cancel" onClick={handleCloseClusterModal}>
          Cancel
        </button>
        <button
          className="button button-small"
          disabled={loading || createAccessLevelStatus.loading || updateAccessLevelStatus.loading}
          onClick={() => onAddDevice()}
        >
          Save
          <Icon className="arrow-right" name="arrow-right" />
        </button>
      </div>
    </ModalPopupCustom>
  );
};

export default FormAccessLevel;

import React, { useState, useEffect } from 'react';
import EditorField from '../../../components/EditorField/EditorField';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

const MoreInformation = ({ editorContentInit, onChange }) => {
  const [content, setContent] = useState(EditorState.createEmpty());

  useEffect(() => {
    try {
      if (
        content &&
        content.getCurrentContent() &&
        content.getCurrentContent().getPlainText() === '' &&
        editorContentInit
      ) {
        setContent(EditorState.createWithContent(convertFromRaw(JSON.parse(editorContentInit))));
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContentInit]);

  useEffect(() => {
    if (content) {
      const check = convertToRaw(content.getCurrentContent());
      onChange(JSON.stringify(check).toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.getCurrentContent().getPlainText()]);

  return (
    <>
      <div className="settings__fieldset">
        <EditorField fieldLabel="Remark" state={content} onChange={setContent} />
      </div>
    </>
  );
};

export default MoreInformation;

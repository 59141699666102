import React from 'react'
import { Editor as ReactEditor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditorField = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  state,
  onChange,
  fieldLabel = '',
} = {}) => {
  return (
    <div
      className="field"
      key={propertyName}
    >
      {/* Label */}
      {fieldLabel && (
        <div className="field__label">
          {fieldLabel}
        </div>
      )}

      <div className="field__wrap">
        <ReactEditor
          editorState={state}
          onEditorStateChange={onChange}
          toolbar={{
            options: ["inline", "emoji", "link", "list", "textAlign", "history"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            link: {
              options: ["link"],
            },
            list: {
              options: ["unordered"],
            },
            textAlign: {
              options: ["center"],
            },
          }}
        />
      </div>
    </div>
  )
}

export { EditorField as default }

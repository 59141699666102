import gql from 'graphql-tag';

export const CREATE_COMPANY = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input)
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input)
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($input: DeleteCompanyInput!) {
    deleteCompany(input: $input)
  }
`;

import cn from 'classnames';
import React, { useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useParams } from 'react-router-dom';
import PageDashboard from '../../components/PageDashboard/PageDashboard';

// Inner Components
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_COMPANY, UPDATE_COMPANY, DELETE_COMPANY } from '../../queriesAndMutations';
import { toaster } from '../../utils';
import CompanyInformation from '../CompaniesManagement/components/CompanyInformation';

const CompanyView = () => {
  const [values, setValues] = useState({ id: '', companyName: '' });
  const scrollToCompany = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [deleteCompany] = useMutation(DELETE_COMPANY);
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [getCompany, { data: { getCompany: data } = {}, refetch, loading }] = useLazyQuery(
    GET_COMPANY,
    {
      fetchPolicy: 'network-only',
    }
  );

  const navigation = [
    {
      title: 'Company',
      action: () => scrollToCompany.current.scrollIntoView({ behavior: 'smooth' }),
    },
  ];

  const handleDeleteCompany = React.useCallback(() => {
    deleteCompany({
      variables: {
        input: {
          id: parseInt(id),
        },
      },
    })
      .then(({ data: { deleteCompany: success } }) => {
        if (success) {
          toaster.success('Company deleted');
          history.push('/companies-management');
        }
      })
      .catch((e) => {
        console.error(e);
        toaster.error('Delete company failed, please try again later');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleUpdateCompany = React.useCallback(() => {
    const _values = { ...values };
    Object.keys(_values).forEach((key) => {
      const value = _values[key];
      if (value === '' || value === null) {
        delete _values[key];
      }
    });
    updateCompany({
      variables: {
        input: {
          ..._values,
        },
      },
    })
      .then(({ data: { updateCompany: success } }) => {
        if (success) {
          toaster.success('Company updated');
          history.push('/companies-management');
        }
      })
      .catch((e) => {
        console.log('e', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  React.useEffect(() => {
    getCompany({
      variables: {
        id: parseInt(id),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    if (data && data.id) {
      setValues({
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  return (
    <PageDashboard pageTitle="View Company">
      <div className="settings">
        <div className="settings__menu">
          {navigation.map((x, index) => (
            <button
              className={cn('settings__link', {
                active: true,
              })}
              key={index}
              onClick={() => {}}
            >
              {x.title}
            </button>
          ))}
        </div>

        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToCompany}>
                {' '}
              </div>

              <div className="title settings__title text-color-white">Company</div>
              <CompanyInformation mode="edit" values={values} setValues={setValues} />
            </div>
          </div>
          <button
            onClick={handleDeleteCompany}
            className="button button-small settings__button--delete"
          >
            Delete
          </button>{' '}
          <button onClick={handleUpdateCompany} className="button button-small settings__button">
            Save
          </button>
        </div>
      </div>
    </PageDashboard>
  );
};

export default CompanyView;

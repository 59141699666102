import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import ReactTooltip from 'react-tooltip';
import { CircularProgress } from '@material-ui/core';
import ReactExport from 'react-export-excel';
import Select from 'react-select';
import moment from 'moment';
import Icon from '../../components/Icons/Icon';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import { GET_COMPANY_LIST, GET_PROJECT_LIST } from '../../queriesAndMutations';
import { useMe, usePublicSettings } from '../../myHooks';
import { USER_RIGHT_TYPES } from '../../constants/user';
import { findByValue, getNationalityList, toaster } from '../../utils';
import {
  GET_PERSON_LIST,
  GET_TRADE_LIST,
  PERSON_ATTENDANCE_REPORT,
} from '../../queriesAndMutations/personQuery';
import DatePicker from 'react-datepicker';
import SelectAsync from '../../components/SelectAsync';
import client from '../../apolloClient';
import { get, find } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const INIT_FILTERS = {
  pin: [],
  dateFrom: '',
  dateTo: '',
  tradeId: '',
  nationality: '',
  companyId: '',
  projectId: '',
  isInAndOutStatus: 'false',
};

const PersonAttendanceReport = () => {
  // Access Date
  const [personAttendanceList, setPersonAttendanceList] = useState([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState({ ...INIT_FILTERS });
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [exportedPersonAttendanceList, setExportedPersonAttendanceList] = useState([]);
  const [exportedPage, setExportedPage] = useState(0);
  const [isShowDownload, setIsShowDownload] = useState(false);
  const [dataSets, setDataSets] = useState([]);
  const [companyListData, setCompanyListData] = useState([]);
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const nationalityList = getNationalityList(publicSettings) || [];
  const dropdownNationality = nationalityList.map((item) => ({ value: item, label: item }));
  const dateToMaxDate = new Date();
  const dateFromMaxDate = new Date();
  const [dropdownTrade, setDropdownTrade] = useState([]);
  const [getTradeList, { data: { getTradeList: tradeList } = {} }] = useLazyQuery(GET_TRADE_LIST, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (tradeList) {
      setDropdownTrade(tradeList.map((item) => ({ value: item.id, label: item.name })) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeList]);

  let [
    getPersonAttendanceReport,
    { data: { getPersonAttendanceReport: data } = {}, refetch, loading },
  ] = useLazyQuery(PERSON_ATTENDANCE_REPORT, {
    fetchPolicy: 'network-only',
  });

  const personListLoadOptions = async (search, loadedOptions, { page }) => {
    if (search) {
      const searchResult = find(loadedOptions, (option) => {
        if (
          option.data.label.toLowerCase().includes(search.toLowerCase()) ||
          option.data.value.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (searchResult) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page,
          },
        };
      }
    }
    const result = await client.query({
      query: GET_PERSON_LIST,
      variables: {
        page: search ? 0 : page,
        pageSize: 20,
        projectId: filters.projectId > 0 ? filters.projectId : null,
        userName: search,
      },
      fetchPolicy: 'network-only',
    });
    const newOptions = get(result, 'data.getPersonList.items', []).map((person) => ({
      value: person.pin,
      label: person.userName,
    }));
    const hasMore = get(result, 'data.getPersonList.totalPages', 0) > page;

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: search ? 1 : page + 1,
      },
    };
  };

  const [
    getCompanyList,
    { data: { getCompanyList: companyList } = {}, loading: loadingCompanyList },
  ] = useLazyQuery(GET_COMPANY_LIST, {
    fetchPolicy: 'network-only',
  });
  const [getProjectList, { data: { getProjectList: projectListData } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const {
    data: { me: user },
  } = useMe();

  const handleCloseModalCSV = () => {
    setIsShowDownload(false);
  };

  const handleOpenModalCSV = () => {
    setIsShowDownload(true);
  };

  const handlePinChange = (selectedOptions) => {
    const pinValues = selectedOptions.map((option) => option.value); // Extracting values from selected options
    setFilters({ ...filters, pin: pinValues });
  };

  const formatFilter = (filters) => {
    return Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== '' || filters[key] === undefined || filters[key] === null) {
        if (key === 'dateFrom' || key === 'dateTo') {
          acc[key] = moment(filters[key]).format('MM/DD/YYYY');
        } else {
          acc[key] = filters[key];
        }
      }
      return acc;
    }, {});
  };

  const onClickSearch = () => {
    setPersonAttendanceList([]);
    setExportedPersonAttendanceList([]);
    setCanLoadMore(true);
    if (page === 0) {
      if (
        !filters?.pin?.length &&
        (!filters?.nationality || filters?.nationality === '') &&
        (!filters?.companyId || filters?.companyId === '') &&
        (!filters?.tradeId || filters?.tradeId === '')
      ) {
        return toaster.error(
          `At least one of Employee, Nationality, Company, or Trade is required`
        );
      }

      if (
        !filters?.dateFrom ||
        !filters?.dateFrom === '' ||
        !filters?.dateTo ||
        !filters?.dateTo === ''
      ) {
        return toaster.error(`Date From and Date To are required`);
      }

      if (new Date(filters?.dateTo) < new Date(filters?.dateFrom)) {
        return toaster.error(`Date From must be before Date To`);
      }
      return getPersonAttendanceReport({
        variables: {
          page,
          ...formatFilter({ ...filters, pin: filters?.pin ? filters.pin.join(',') : null }),
        },
      });
    } else {
      return setPage(1);
    }
  };

  const onExportTransactionList = () => {
    // setExportedPersonAttendanceList([]);
    handleOpenModalCSV();
    setIsLoading(true);
    /*eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]*/
    for (let i = 0; i < totalPages; i++) {
      (function (index) {
        setTimeout(() => {
          getPersonAttendanceReport({
            variables: {
              page: index,
              pageSize: 100,
            },
          });
          setExportedPage(index);
        }, 2000 * index); // Multiply by index to stagger the timeouts
      })(i);
    }
    setIsLoading(false);
  };

  const onResetFilters = () => {
    setPersonAttendanceList([]);
    // refetch();
    setCanLoadMore(true);
    setFilters({ ...INIT_FILTERS });
    getPersonAttendanceReport({
      variables: {
        page: 0,
      },
    });
  };

  useEffect(() => {
    if (exportedPage === totalPages - 1) {
      setIsLoading(false);
    }
  }, [exportedPage, totalPages, exportedPersonAttendanceList]);

  useEffect(() => {
    if (!loading && page) {
      getPersonAttendanceReport({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    }
    //eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    if (data && data.items && data.items.length) {
      if (!isShowDownload) {
        const customPersonAttendanceList = [];
        data.items?.forEach((d) => {
          d?.attendance?.forEach((att) => {
            customPersonAttendanceList.push({ ...att, user: d.userInfo });
          });
        });
        setPersonAttendanceList([...personAttendanceList, ...customPersonAttendanceList]);
      }
      setExportedPersonAttendanceList([...exportedPersonAttendanceList, ...data.items]);
    }

    if (data && data.items && data.items.length < 25) {
      setCanLoadMore(false);
    }

    if (data && data.totalPages) {
      setTotalPages(data.totalPages);
    }

    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (projectListData) {
      setProjectList(
        projectListData.map((project) => ({ value: project.id, label: project.name }))
      );
    }
  }, [projectListData]);

  useEffect(() => {
    // if (!personAttendanceList || !personAttendanceList.length) {
    // getPersonAttendanceReport({
    //   variables: { page: 0 },
    // });
    // }

    if (!companyList && getCompanyList) {
      getCompanyList({
        variables: {
          page: 0,
          keyWord: '',
        },
      });
    }
    getTradeList();
    if (!projectListData && getProjectList) {
      getProjectList();
    }

    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (companyList && companyList.items && Array.isArray(companyList.items)) {
      setCompanyListData(
        companyList.items.map((company) => ({ value: company.id, label: company.companyName }))
      );
    }
  }, [companyList]);

  useEffect(() => {
    const boldStyleColumn = {
      font: { sz: '11', bold: true },
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    const normalStyleColumn = {
      font: { sz: '11', bold: false },
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      },
      // alignment: { horizontal: 'center', vertical: 'center' }
    };

    const dataStyleColumn = {
      font: { sz: '11', bold: false },
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      },
      alignment: { horizontal: 'center', vertical: 'center' },
    };
    const dataSetExport = exportedPersonAttendanceList.map((exportedPersonList, index) => {
      const customExportedPerson = exportedPersonList?.attendance.map((e) => {
        return [
          // { value: e.no, style: dataStyleColumn },
          // { value: e.day, style: dataStyleColumn },
          // { value: e.loginDate, style: dataStyleColumn },
          // { value: e.inTime, style: dataStyleColumn },
          // { value: e.outTime, style: dataStyleColumn },
          // { value: e.totalHrs, style: dataStyleColumn }
          e.no,
          e.day,
          e.loginDate,
          e.inTime,
          e.outTime,
          e.totalHrs,
        ];
      });
      return [
        {
          columns: [
            {
              value: 'STAFF ATTENDANCE REPORT - BY PERSON',
              style: { font: { sz: '16', bold: true, underline: true, color: { rgb: '191970' } } },
            },
            {
              value: '',
            },
            {
              value: '',
            },
            {
              value: '',
            },
          ],
          data: [],
        },
        {
          columns: ['', '', '', ''],
          data: [
            [
              {
                value:
                  'Date range: ' +
                  moment(filters?.dateFrom).format('MM/DD/YYYY') +
                  ' to ' +
                  moment(filters?.dateTo).format('MM/DD/YYYY'),
                style: boldStyleColumn,
              },
              { value: '', style: normalStyleColumn },
            ],
            [
              {
                value: 'User PIN: ' + exportedPersonList?.userInfo?.userPin || '',
                style: boldStyleColumn,
              },
              { value: '', style: normalStyleColumn },
            ],
            [
              {
                value: 'Staff Name: ' + exportedPersonList?.userInfo?.name || '',
                style: boldStyleColumn,
              },
              { value: '', style: normalStyleColumn },
            ],
            [
              {
                value: 'Job Title: ' + get(exportedPersonList?.userInfo, 'jobTitle', ''),
                style: boldStyleColumn,
              },
              { value: '', style: normalStyleColumn },
            ],
            [
              {
                value: 'Company: ' + exportedPersonList?.userInfo?.company || '',
                style: boldStyleColumn,
              },
              { value: '', style: normalStyleColumn },
            ],
            [
              {
                value: 'Nationality: ' + exportedPersonList?.userInfo?.nationality || '',
                style: boldStyleColumn,
              },
              { value: '', style: normalStyleColumn },
            ],
          ],
        },
        {
          ySteps: 1,
          columns: [
            { value: 'S:No' },
            { value: 'Day' },
            { value: 'Login Date' },
            { value: 'In Time' },
            { value: 'Out Time' },
            { value: 'Total Hrs' },
          ],
          data: [
            ...customExportedPerson,
            [
              '',
              '',
              '',
              '',
              'Total Hours',
              data?.items[index]?.totalHrs || 0,
              // { value: "", style: dataStyleColumn },
              // { value: "", style: dataStyleColumn },
              // { value: "", style: dataStyleColumn },
              // { value: "", style: dataStyleColumn },
              // { value: "Total Hours", style: dataStyleColumn },
              // { value: "0", style: dataStyleColumn },
            ],
          ],
        },
      ];
    });
    setDataSets(dataSetExport);
  }, [exportedPersonAttendanceList, filters?.dateFrom, filters?.dateTo, data]);

  return (
    <PageDashboard pageTitle="Attendance Report (By Person)">
      <ModalPopup
        modalClass={`js-popup-syadmin popup-small ${isShowDownload ? 'animation visible' : ''}`}
        modalTitle="Export To CSV"
        onClickClose={handleCloseModalCSV}
      >
        {isLoading ? (
          <div className={classes.circularProgressWrapper}>
            Please do not close this pop up.
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="settings__fieldset">
              <div className="title card__title">
                Your Excel is ready. Click here to download
                <ExcelFile
                  element={
                    <button
                      className="button-circle button-small"
                      data-tip="Export to Excel"
                      data-for="export-to-excel"
                    >
                      <Icon className="download" name="download" />{' '}
                    </button>
                  }
                >
                  {dataSets.map((dataSet, index) => (
                    <ExcelSheet
                      dataSet={dataSet}
                      name={
                        exportedPersonAttendanceList[index]?.userInfo?.name ||
                        'Person Attendance Report'
                      }
                    />
                  ))}
                </ExcelFile>
                <ReactTooltip id="export-to-excel" />
              </div>
            </div>
            <div className="popup__foot">
              <button className="js-popup-close popup__foot-cancel" onClick={handleCloseModalCSV}>
                Cancel
              </button>
            </div>
          </>
        )}
      </ModalPopup>
      <div className="report-management card">
        <div className="report-management__wrapper">
          <div className="report-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row">
                {user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
                  <div className="field">
                    <div className="field__label">Project</div>

                    <div className="field__wrap">
                      <Select
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        name="projectId"
                        options={projectList}
                        value={findByValue(projectList, filters.projectId)}
                        onChange={(option) =>
                          setFilters({ ...filters, projectId: get(option, 'value', 0) })
                        }
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div className="field">
                    <div className="field__label">Access Date From</div>
                    <div className="field__wrap">
                      <DatePicker
                        showIcon
                        selected={get(filters, 'dateFrom', new Date())}
                        onChange={(date) => {
                          setFilters({ ...filters, dateFrom: date });
                        }}
                        maxDate={dateFromMaxDate}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="field__label">Access Date To</div>
                    <div className="field__wrap">
                      <DatePicker
                        showIcon
                        selected={get(filters, 'dateTo', new Date())}
                        onChange={(date) => {
                          setFilters({ ...filters, dateTo: date });
                        }}
                        maxDate={dateToMaxDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="field__label">Employee Name</div>
                  <div className="field__wrap">
                    <SelectAsync
                      isSearchable
                      isClearable
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue=""
                      name="pin"
                      onChange={handlePinChange}
                      // onChange={(option) =>
                      //   setFilters({ ...filters, pin: get(option, 'value', '') })
                      // }
                      loadOptions={personListLoadOptions}
                      additional={{
                        page: 0,
                      }}
                      noOptionsMessage="No users"
                      isRequired={true}
                      isMulti
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="field__label">Export With</div>
                  <div className="field__wrap">
                    <Select
                      // ref={companyRef}
                      isSearchable
                      // isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      name="isInAndOutStatus"
                      options={[
                        {
                          value: 'false',
                          label: 'All Active Employees',
                        },
                        {
                          value: 'true',
                          label: 'Only employees having IN/OUT',
                        },
                      ]}
                      onChange={(option) =>
                        setFilters({ ...filters, isInAndOutStatus: option ? option.value : null })
                      }
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Nationality</div>
                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      name="nationality"
                      options={dropdownNationality}
                      onChange={(option) =>
                        setFilters({ ...filters, nationality: option ? option.value : null })
                      }
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="field__label">Company</div>
                  <div className="field__wrap">
                    <Select
                      // ref={companyRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      name="companyId"
                      options={companyListData}
                      onChange={(option) =>
                        setFilters({ ...filters, companyId: option ? option.value : null })
                      }
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="field__label">Trade</div>

                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="trade"
                      options={dropdownTrade}
                      onChange={(option) =>
                        setFilters({ ...filters, tradeId: option ? option.value : null })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="settings__btns is-end">
              <button
                className="button button-stroke button-small"
                disabled={exportedPersonAttendanceList.length === 0}
                onClick={onExportTransactionList}
              >
                Export To Excel
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-stroke button-small" onClick={onResetFilters}>
                Reset
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-small" onClick={onClickSearch}>
                Search
                <Icon className="icon-search" name="icon-search" />
              </button>
            </div>
          </div>

          <div className="v-space-40"> </div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S:No</div>
              <div className="table__col">User</div>
              <div className="table__col">Day</div>
              <div className="table__col">Login Date</div>
              <div className="table__col">In Time</div>
              <div className="table__col">Out Time</div>
              <div className="table__col">Total Hrs</div>
            </div>
            {/* Table Content Body */}
            {personAttendanceList.map((att, index) => (
              <div key={index} className="table__row">
                <div className="table__col">
                  <div className="table__label">S:No</div>
                  {att.no ? att.no : ''}
                </div>
                <div className="table__col">
                  <div className="table__label">User</div>
                  {att.user ? att.user?.name : ''}
                </div>
                <div className="table__col">
                  <div className="table__label">Day</div>
                  {att.day ? att.day : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Login Date</div>
                  {att.loginDate ? att.loginDate : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">In Time</div>
                  {att && att.inTime ? att.inTime : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Out Time</div>
                  {att && att.outTime ? att.outTime : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Total Hrs</div>
                  {att.totalHrs ? att.totalHrs : ''}
                </div>
              </div>
            ))}
            {personAttendanceList &&
            Array.isArray(personAttendanceList) &&
            personAttendanceList.length ? (
              <></>
            ) : (
              <div className="table__row empty">
                <div className="table__col">No Records Available To Show</div>
              </div>
            )}
          </div>
          {canLoadMore && (
            <div className="table__foot text-center">
              <button
                type="button"
                className="button button-stroke has-loader"
                onClick={() => setPage(page + 1)}
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </PageDashboard>
  );
};

export default PersonAttendanceReport;

import React from 'react'

const PageStructure = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Page Structure</h1>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.page *dashboard</div>
        <div className="ds__card-inner">
          {' '}

          <div className="text-color-white">
            This class used for small wrapper
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.page.page-simple</div>
        <div className="ds__card-inner">
          <div className="text-color-white">
            This class used for small wrapper
          </div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.entry</div>
        <div className="ds__card-inner">
          {' '}
        </div>
      </div>
    </>
  )
}

export default PageStructure

// eslint-disable-next-line import/prefer-default-export
export const USER_RIGHT_TYPES = {
  COMMON: 'COMMON',
  // ACCOUNTING: 'ACCOUNTING',
  // CUSTODIAN: 'CUSTODIAN',
  // COMPLIANCE_OFFICER: 'COMPLIANCE_OFFICER',
  // REFERRAL_PARTNER: 'REFERRAL_PARTNER',
  ADMIN: 'ADMIN',
  GENERAL_ADMIN: 'GENERAL_ADMIN',
};

export const USER_RIGHT_LISTS = Object.keys(USER_RIGHT_TYPES)
  .filter((key) => key !== USER_RIGHT_TYPES.GENERAL_ADMIN)
  .map((key) => ({
    id: key,
    label: USER_RIGHT_TYPES[key],
  }));

export const USER_RIGHTS_WEIGHT = {
  [USER_RIGHT_TYPES.COMMON]: 1,
  // [USER_RIGHT_TYPES.ACCOUNTING]: 2,
  // [USER_RIGHT_TYPES.CUSTODIAN]: 1.5,
  // [USER_RIGHT_TYPES.REFERRAL_PARTNER]: 1.75,
  // [USER_RIGHT_TYPES.COMPLIANCE_OFFICER]: 2,
  [USER_RIGHT_TYPES.ADMIN]: 3,
  [USER_RIGHT_TYPES.GENERAL_ADMIN]: 4,
};

export const USER_STATUS_TYPES = {
  DELETED: 'DELETED',
  BLOCKED: 'BLOCKED',
  ACTIVE: 'ACTIVE',
};

export const USER_KYC_STATUS_TYPES = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  PASSED: 'PASSED',
  REOPEN: 'REOPEN',
};

export const USER_VIDEO_IDENT_STATUS = {
  NOT_NEEDED: 'NOT_NEEDED',
  PENDING: 'PENDING',
  PASSED: 'PASSED',
  DENIED: 'DENIED',
  NOT_STARTED: 'NOT_STARTED',
};

export const INIT_NEW_USER_VALUES = {
  pin: '',
  userName: '',
  idCard: '',
  accessLevelId: '',
  accessLevelIds: [],
  startTime: '',
  endTime: '',
  personPhoto: '',
  passwd: '',
  pri: '',
  grp: '',
  jobTitle: '',
  designation: '',
  passExpireDate: '',
  tradeId: '',
  workPassNumber: '',
  idNumber: '',
  passCategory: '',
  companyId: '',
  remark: '',
  projectId: '',
  nationality: '',
};

export const INIT_EDIT_USER_VALUES = {
  ...INIT_NEW_USER_VALUES,
  id: '',
  devSN: '',
  disable: false,
  verify: true,
};

export const IMPORT_USER_FIELDS = [
  'pin',
  'userName',
  'idCard',
  'companyName',
  'jobTitle',
  'designation',
  'tradeName',
  'workPassNumber',
  'passCategory',
  'idNumber',
  'remark',
  'nationality',
  'projectName',
];

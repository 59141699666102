import React from 'react'
import ReactTooltip from 'react-tooltip'
import Icon from '../../../components/Icons/Icon'

const DSTooltips = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Tooltips</h1>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">Demo Tooltip</div>

        <div className="ds__card-inner text-align-center">
          <button
            className="button-circle button-small"
            data-tip="Demo Tooltip"
            data-for="demo-tooltip"
          >
            <Icon className="plus" name="plus" />
          </button>
          <ReactTooltip
            id="demo-tooltip"
          />
        </div>
      </div>
    </>
  )
}

export default DSTooltips

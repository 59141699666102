export const findByLabel = (list, label) => {
  return list.find((item) => item.label === label) || {};
};

export const findByValue = (list, value) => {
  return list.find((item) => item.value === value) || {};
};

export const omitObject = (object, keys = []) => {
  const clone = { ...object };
  keys.forEach((key) => {
    delete clone[key];
  });

  return clone;
};

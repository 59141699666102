import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import ModalPopupCustom from '../../../components/ModalPopup/ModalPopup';
import InputField from '../../../components/InputField/InputField';
import { UPDATE_DEVICE_NAME } from '../../../queriesAndMutations';
import { toaster } from '../../../utils';

const EditDeviceModal = ({
  show = false,
  data = {},
  onClose = () => {},
  onUpdated = () => {},
  setStates = () => {},
}) => {
  const [updateDeviceName, updateDeviceNameResult] = useMutation(UPDATE_DEVICE_NAME);

  const handleUpdateDevice = async () => {
    const { deviceId, devName, devIP, devPort, devUserName, devPassword } = data;
    await updateDeviceName({
      variables: {
        input: {
          deviceId,
          devName,
          ip: devIP,
          port: devPort,
          userName: devUserName,
          password: devPassword,
        },
      },
    })
      .then(({ data: { updateDeviceName: success } }) => {
        if (success) {
          toaster.success('Device name updated');
          onUpdated();
        } else {
          toaster.error('Device name failed');
        }
      })
      .catch((e) => {
        toaster.error('Device name failed');
        console.log('e', e);
      });

    onClose();
  };

  const lastRequestTime = get(data, 'lastRequestTime', '');
  return (
    <ModalPopupCustom
      modalClass={`js-popup-sysadmin popup-medium ${show ? 'animation visible' : ''}`}
      modalTitle="Update Device"
      onClickClose={onClose}
    >
      <div className="settings__device">
        <div className="settings__row">
          <InputField
            propertyName="devSN"
            fieldName="devSN"
            fieldType="text"
            fieldLabel="Device SN"
            state={{ devSN: data.devSN }}
            readOnly={true}
            disabled={true}
          />
          <InputField
            propertyName="devName"
            fieldName="devName"
            fieldType="text"
            fieldLabel="Device Name"
            setState={setStates}
            state={data}
          />
          <InputField
            propertyName="lastRequestTime"
            fieldName="lastRequestTime"
            fieldType="text"
            fieldLabel="Activated Date"
            state={{
              lastRequestTime: lastRequestTime
                ? moment(lastRequestTime).format('YYYY-MM-DD HH:mm:ss')
                : '',
            }}
            readOnly={true}
            disabled={true}
          />
          {data.supplier === 1 && (
            <>
              <InputField
                propertyName="devIP"
                fieldName="devIP"
                fieldType="text"
                fieldLabel="IP"
                setState={setStates}
                state={data}
              />
              <InputField
                propertyName="devPort"
                fieldName="devPort"
                fieldType="text"
                fieldLabel="Port"
                setState={setStates}
                state={data}
              />
              <InputField
                propertyName="devUserName"
                fieldName="devUserName"
                fieldType="text"
                fieldLabel="User Name"
                setState={setStates}
                state={data}
              />
              <InputField
                propertyName="devPassword"
                fieldName="devPassword"
                fieldType="password"
                fieldLabel="Password"
                setState={setStates}
                state={data}
              />
            </>
          )}
        </div>
        <div className="action-buttons algin-right">
          <button
            className="button button-small settings__button__space"
            onClick={handleUpdateDevice}
            disabled={updateDeviceNameResult.loading}
          >
            Update
          </button>
          <button
            className="button button-small settings__button__space settings__button--close"
            onClick={onClose}
            disabled={updateDeviceNameResult.loading}
          >
            Close
          </button>
        </div>
      </div>
    </ModalPopupCustom>
  );
};

export default EditDeviceModal;

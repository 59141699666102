import React from 'react'

const CheckboxField = ({
  propertyName,
  defaultChecked,
  onChange,
  value
} = {}) => {
  return (
    <label className="checkbox" key={propertyName}>
      <input
        className="checkbox__input"
        type="checkbox"
        checked={defaultChecked}
        onChange={onChange}
        value={value}
      />
      <span className="checkbox__inner">
        <span className="checkbox__tick">{' '}</span>
      </span>
    </label>
  )
}

export { CheckboxField as default }

import React, { useState, useRef } from 'react';
import cn from 'classnames';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import { toaster } from '../../utils';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { UPDATE_COMPANY_NAME_SETTING } from '../../queriesAndMutations/settingsMutation';
import { GET_COMPANY_NAME_SETTING } from '../../queriesAndMutations/settingsQuery';

// Inner Components
import SiteInformation from './SiteInformation/SiteInformation';
// import AlertInformation from './AlertInformation/AlertInformation';

const Settings = () => {
  const [siteInformation, setSiteInformation] = useState({ company_name: '' });
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollSiteInformation = useRef(null);
  // const scrollAlertInformation = useRef(null);
  const [updateCompanyNameSetting, updateCompanyNameSettingStatus] = useMutation(
    UPDATE_COMPANY_NAME_SETTING
  );
  const [
    getCompanyNameSetting,
    { data: { getCompanyNameSetting: companyNameData } = {}, refetch, loading },
  ] = useLazyQuery(GET_COMPANY_NAME_SETTING, {
    fetchPolicy: 'network-only',
  });

  const handleClick = (x, index) => {
    setActiveIndex(index);
    x.action();
  };

  const handleClickSave = React.useCallback(() => {
    const { company_name } = siteInformation;
    console.log(company_name);
    updateCompanyNameSetting({
      variables: {
        input: {
          company_name,
        },
      },
    })
      .then(({ data: { updateCompanyNameSetting: success } }) => {
        if (success) {
          toaster.success('Settings updated');
        }
      })
      .catch((e) => {
        console.log('e', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteInformation]);

  const navigation = [
    {
      title: 'Site Information',
      action: () => scrollSiteInformation.current.scrollIntoView({ behavio: 'smooth' }),
    },
    // {
    //   title: 'Alert Information',
    //   action: () => scrollAlertInformation.current.scrollIntoView({ behavior: 'smooth' }),
    // },
  ];

  React.useEffect(() => {
    getCompanyNameSetting();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (companyNameData && companyNameData.company_name) {
      setSiteInformation({
        company_name: companyNameData.company_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyNameData]);

  return (
    <PageDashboard pageTitle="Settings">
      <div className="settings">
        <div className="settings__menu">
          {navigation.map((x, index) => (
            <button
              key={index}
              onClick={() => handleClick(x, index)}
              className={cn('settings__link', { active: activeIndex === index })}
            >
              {x.title}
            </button>
          ))}
        </div>

        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              <div className="settings__anchor" ref={scrollSiteInformation}>
                {' '}
              </div>
              <div className="title settings__title">Site Information</div>
              <SiteInformation values={siteInformation} setValues={setSiteInformation} />
            </div>

            {/* <div className="settings__item">
              <div className="settings__anchor" ref={scrollAlertInformation}>
                {' '}
              </div>
              <div className="title settings__title">Alert Information</div>
              <AlertInformation />
            </div> */}

            <button className="button button-small settings__button" onClick={handleClickSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </PageDashboard>
  );
};

export default Settings;

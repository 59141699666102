import React, { useState, useEffect } from 'react';
import ModalPopupCustom from '../../../components/ModalPopup/ModalPopup';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  SEARCH_NEW_DEVICES,
  ADD_NEW_DEVICE,
  LIST_DEVICES,
  DELETE_DEVICE,
} from '../../../queriesAndMutations';
import EditDeviceModal from './EditDeviceModal';
import { toaster } from '../../../utils';
import AddNewHikDeviceModal from './AddNewHikDeviceModal';

const SearchDevice = ({ modalSearchDeviceIsOpen, setModalSearchDeviceIsOpen }) => {
  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const [showAddNewHikDeviceModal, setShowAddNewHikDeviceModal] = useState(false);
  const [currentDeviceEdited, setCurrentDeviceEdited] = useState({ deviceId: 0, devName: '' });
  const { data: { searchNewDevices = [] } = {}, loading, refetch } = useQuery(SEARCH_NEW_DEVICES);
  const {
    data: { listDevices = [] } = {},
    loading: listDevicesLoading,
    refetch: listDevicesRefetch,
  } = useQuery(LIST_DEVICES, {
    page: 0,
  });

  const [addNewDevice, addNewDeviceStatus] = useMutation(ADD_NEW_DEVICE);
  const [deleteDevice, deleteDeviceStatus] = useMutation(DELETE_DEVICE);

  const onAddDevice = async (devSN) => {
    try {
      await addNewDevice({
        variables: {
          devSN,
        },
      });

      refetch();
      listDevicesRefetch();
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleEditDeviceName = (device) => {
    setCurrentDeviceEdited({ deviceId: device.id, devName: device.devName, ...device });
    setShowEditDeviceModal(true);
  };

  const onDeviceUpdated = () => {
    listDevicesRefetch();
  };

  const handleDeleteDevice = async (deviceId) => {
    try {
      await deleteDevice({
        variables: {
          input: {
            id: deviceId,
          },
        },
      })
        .then(({ data: { deleteDevice: success } }) => {
          if (success) {
            toaster.success('Device deleted');
          } else {
            toaster.error('Delete device failed');
          }
        })
        .catch((e) => {
          toaster.error('Delete device failed');
          console.log('e', e);
        });

      refetch();
      listDevicesRefetch();
    } catch (e) {
      toaster.error('Delete device failed');
      console.log(e);
    }
  };

  useEffect(() => {
    if (modalSearchDeviceIsOpen) {
      if (typeof refetch === 'function') {
        refetch();
      }
      if (typeof listDevicesRefetch === 'function') {
        listDevicesRefetch();
      }
    }
  }, [modalSearchDeviceIsOpen, refetch, listDevicesRefetch]);

  return (
    <>
      <ModalPopupCustom
        modalClass={`js-popup-sysadmin popup-wide ${
          modalSearchDeviceIsOpen ? 'animation visible' : ''
        }`}
        modalTitle="Devices List"
        onClickClose={() => setModalSearchDeviceIsOpen(false)}
      >
        <div className="label">Added Devices</div>
        <div className="table__wrapper">
          {/* Table Content Header */}
          <div className="table__row">
            <div className="table__col">Device SN</div>
            <div className="table__col">Device Name</div>
            <div className="table__col">Device Type</div>
            <div className="table__col">IP Address</div>
            <div className="table__col">Vendor Name</div>
            <div className="table__col">Action</div>
          </div>

          {/* Table Content */}
          {listDevices &&
            listDevices.map((device, index) => (
              <div className="table__row" key={index}>
                <div className="table__col">
                  <div className="table__label">Device SN</div>
                  {device.devSN}
                </div>

                <div className="table__col">
                  <div className="table__label">Device Name</div>
                  {device.devName}
                </div>

                <div className="table__col">
                  <div className="table__label">Device Type</div>
                  {device.deviceType}
                </div>

                <div className="table__col">
                  <div className="table__label">IP Address</div>
                  {device.devIP}
                </div>

                <div className="table__col">
                  <div className="table__label">Vendor Name</div>
                  {device.vendorName}
                </div>
                <div className="table__col">
                  <div className="table__label">Action</div>
                  {/* <button
                  className="button button-small"
                  onClick={() => onAddDevice(device.deviceSN)}
                  disabled={addNewDeviceStatus.loading}
                >
                  Add
                </button> */}
                  <button
                    onClick={() => handleEditDeviceName(device)}
                    title="Edit device name"
                    data-tip="Edit device name"
                    className="button button-small"
                  >
                    Edit
                  </button>{' '}
                  <button
                    onClick={() => handleDeleteDevice(device.id)}
                    title="Delete device name"
                    data-tip="Delete device name"
                    className="button button-small settings__button--delete"
                    disabled={deleteDeviceStatus.loading}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
        </div>
        <br />
        <div className="page__title-wrap" style={{ marginBottom: 0 }}>
          <p>New Devices</p>
          <button
            onClick={() => setShowAddNewHikDeviceModal(true)}
            title="Add device name"
            data-tip="Add device name"
            className="button button-small settings__button--add"
          >
            Add Device
          </button>
        </div>
        <br />
        <div className="table__wrapper">
          {/* Table Content Header */}
          <div className="table__row">
            <div className="table__col">Device SN</div>
            <div className="table__col">Device Name</div>
            <div className="table__col">Device Type</div>
            <div className="table__col">IP Address</div>
            <div className="table__col">Vendor Name</div>
            <div className="table__col">Action</div>
          </div>

          {/* Table Content */}
          {searchNewDevices.map((device, index) => (
            <div className="table__row" key={index}>
              <div className="table__col">
                <div className="table__label">Device SN</div>
                {device.deviceSN}
              </div>

              <div className="table__col">
                <div className="table__label">Device Name</div>
                {device.deviceName}
              </div>

              <div className="table__col">
                <div className="table__label">Device Type</div>
                {device.deviceType}
              </div>

              <div className="table__col">
                <div className="table__label">IP Address</div>
                {device.ipAddress}
              </div>

              <div className="table__col">
                <div className="table__label">Vendor Name</div>
                {device.vendorName}
              </div>
              <div className="table__col">
                <div className="table__label">Action</div>
                <button
                  className="button button-small"
                  onClick={() => onAddDevice(device.deviceSN)}
                  disabled={addNewDeviceStatus.loading}
                >
                  Add
                </button>{' '}
                <button
                  onClick={() => handleEditDeviceName(device)}
                  title="Edit device name"
                  data-tip="Edit device name"
                  className="button button-small"
                >
                  Edit
                </button>{' '}
                <button
                  onClick={() => handleDeleteDevice(device.id)}
                  title="Delete device name"
                  data-tip="Delete device name"
                  className="button button-small settings__button--delete"
                  disabled={deleteDeviceStatus.loading}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="popup__foot">
          <button
            className="js-popup-close popup__foot-cancel"
            onClick={() => setModalSearchDeviceIsOpen(false)}
          >
            Cancel
          </button>
          <button className="button button-small" onClick={() => refetch()}>
            Refresh
          </button>
        </div>
      </ModalPopupCustom>
      <EditDeviceModal
        show={showEditDeviceModal}
        onClose={() => {
          setShowEditDeviceModal(false);
          setCurrentDeviceEdited({});
        }}
        onUpdated={onDeviceUpdated}
        data={currentDeviceEdited}
        setStates={setCurrentDeviceEdited}
      />
      <AddNewHikDeviceModal
        show={showAddNewHikDeviceModal}
        onClose={() => setShowAddNewHikDeviceModal(false)}
        onUpdated={onDeviceUpdated}
      />
    </>
  );
};

export default SearchDevice;

import React, {useState} from 'react'
import ReactTooltip from 'react-tooltip'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import Icon from '../../components/Icons/Icon'
import PageDashboard from '../../components/PageDashboard/PageDashboard'

const MancountReport = () => {
  // Access Date
  const [startDateSearch, setStartDateSearch] = useState(new Date());
  const [endDateSearch, setEndDateSearch] = useState(new Date());

  return (
    <PageDashboard pageTitle="Man Count Report (Subcon)">
      <div className="report-management card">
        <div className="card__head is-spacebetween">
          <div className="title card__title">
            Man Count Report (Subcon)
            <button
              className="button-circle button-small"
              data-tip="Export to Excel"
              data-for="export-to-excel"
            >
              <Icon className="download" name="download" />
            </button>

            <ReactTooltip id="export-to-excel"/>
          </div>

          <div className="card__nav has-control">
            <div className="field">
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={startDateSearch}
                  onChange={(date) => setStartDateSearch(date)}
                />
              </div>
            </div>

            <div className="field">
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={endDateSearch}
                  onChange={(date) => setEndDateSearch(date)}
                />
              </div>
            </div>

            <button
              className="button"
            >
              Search
            </button>
          </div>
        </div>

        <div className="report-management__wrapper">
          <div className="table__wrapper">
            <div className="table__row">
              <div className="table__col">Subcom/Date</div>
              <div className="table__col">31 May, 2023</div>
              <div className="table__col">1 June, 2023</div>
            </div>

            <div className="table__row">
              <div className="table__col">
                <div className="table__label">Subcom/Date</div>
                None
              </div>
              <div className="table__col">
                <div className="table__label">31 May, 2023</div>
                0
              </div>
              <div className="table__col">
                <div className="table__label">1 June, 2023</div>
                0
              </div>
            </div>

            <div className="table__row">
              <div className="table__col">
                <div className="table__label">Subcom/Date</div>
                Xid Technologies
              </div>
              <div className="table__col">
                <div className="table__label">31 May, 2023</div>
                0
              </div>
              <div className="table__col">
                <div className="table__label">1 June, 2023</div>
                0
              </div>
            </div>

            <div className="table__row">
              <div className="table__col">
                <div className="table__label">Subcom/Date</div>
                Grand Total
              </div>
              <div className="table__col">
                <div className="table__label">31 May, 2023</div>
                0
              </div>
              <div className="table__col">
                <div className="table__label">1 June, 2023</div>
                0
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageDashboard>
  )
}

export default MancountReport

import cn from 'classnames';
import React, { useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useParams } from 'react-router-dom';
import PageDashboard from '../../components/PageDashboard/PageDashboard';

// Inner Components
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { INIT_EDIT_USER_VALUES } from '../../constants/user';
import { DELETE_PERSON, UPDATE_PERSON } from '../../queriesAndMutations/personMutations';
import { GET_PERSON } from '../../queriesAndMutations/personQuery';
import { toaster } from '../../utils';
import EmployeeInformation from '../UsersAdd/EmployeeInformation';
import MoreInformation from '../UsersAdd/MoreInformation/MoreInformation';
import PersonalInformation from '../UsersAdd/PersonalInformation/PersonalInformation';

const UsersView = () => {
  const userFields = Object.keys(INIT_EDIT_USER_VALUES);
  const [values, setValues] = useState(INIT_EDIT_USER_VALUES);
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollToPersonal = useRef(null);
  const scrollToEmployement = useRef(null);
  const scrollToMore = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [deletePerson, deletePersonStatus] = useMutation(DELETE_PERSON);
  const [updatePerson, updatePersonStatus] = useMutation(UPDATE_PERSON);
  const [getPerson, { data: { getPerson: data } = {}, refetch, loading }] = useLazyQuery(
    GET_PERSON,
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleClick = (x, index) => {
    setActiveIndex(index);
    x.action();
  };

  const onClickGoBack = () => {
    history.push({
      pathname: '/users-management',
    });
  };

  const navigation = [
    {
      title: 'Personal Information',
      action: () => scrollToPersonal.current.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      title: 'Employement Information',
      action: () => scrollToEmployement.current.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      title: 'More Information',
      action: () => scrollToMore.current.scrollIntoView({ behavior: 'smooth' }),
    },
  ];

  const handleDeleteUser = React.useCallback(() => {
    deletePerson({
      variables: {
        input: {
          id: parseInt(id),
        },
      },
    })
      .then(({ data: { deletePerson: success } }) => {
        if (success) {
          toaster.success('Person deleted');
          history.push('/users-management');
        }
      })
      .catch((e) => {
        console.error(e);
        toaster.error('Delete personal failed, please try again later');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    getPerson({
      variables: {
        id: parseInt(id),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const setFileField = React.useCallback(
    (field) =>
      async ({ target: { files } }) => {
        setValues({
          ...values,
          [field]: files[0],
        });
      },
    [values]
  );

  React.useEffect(() => {
    if (data && data.model) {
      setValues({
        ...data.model,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  const handleUpdatePerson = React.useCallback(() => {
    const _values = { ...values };
    Object.keys(_values).forEach((key) => {
      if (userFields.includes(key)) {
        const value = _values[key];
        if (value === '' || value === null) {
          delete _values[key];
        }
      } else {
        delete _values[key];
      }
    });
    console.log('_values', _values);
    updatePerson({
      variables: {
        input: {
          ..._values,
          accessLevelId:
            _values.accessLevelIds && _values.accessLevelIds.length > 0
              ? _values.accessLevelIds[0]
              : 0,
        },
      },
    })
      .then(({ data: { updatePerson: success } }) => {
        if (success) {
          toaster.success('Person updated');
          history.push('/users-management');
        }
      })
      .catch((e) => {
        console.log('e', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <PageDashboard pageTitle="View User">
      <div className="settings">
        <div className="settings__menu">
          {navigation.map((x, index) => (
            <button
              className={cn('settings__link', {
                active: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>

        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToPersonal}>
                {' '}
              </div>

              <div className="title settings__title text-color-white">Personal Information</div>
              <PersonalInformation
                mode="view"
                values={values}
                setFileField={setFileField}
                setValues={setValues}
              />
            </div>

            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToEmployement}>
                {' '}
              </div>
              <div className="title settings__title text-color-white">Employement Information</div>

              <EmployeeInformation
                mode="view"
                values={values}
                setFileField={setFileField}
                setValues={setValues}
              />
            </div>

            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToMore}>
                {' '}
              </div>
              <div className="title settings__title text-color-white">More Information</div>

              <MoreInformation
                editorContentInit={values.remark}
                onChange={(text) => setValues({ ...values, remark: text })}
              />
            </div>
          </div>
          <button
            onClick={handleDeleteUser}
            className="button button-small settings__button--delete"
          >
            Delete
          </button>{' '}
          <button onClick={handleUpdatePerson} className="button button-small settings__button">
            Save
          </button>{' '}
          <button onClick={onClickGoBack} className="button button-small settings__button--back">
            Go Back
          </button>
        </div>
      </div>
    </PageDashboard>
  );
};

export default UsersView;

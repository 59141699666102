import React from 'react'

const Buttons = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Buttons</h1>
      </div>

      <div className="ds__cards is-col-4">
        <div className="ds__card">
          <div className="ds__class is-purple">Default Button</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button">
              Button Text
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Default Button Disabled</div>
          <div className="ds__card-inner text-align-center ">
            <button type="button" className="button disabled">
              Button Text
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Button Stroke</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-stroke">
              Button Text
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Button Stroke Primary</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-stroke button-stroke-primary">
              Button Text
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Button White</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-white">
              Button Text
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Button Transparent</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-transparent">
              Button Text
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Button Square Stroke</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-square-stroke">
              X
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Button Circle</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-circle">
              X
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Button Small</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-small">
              Button Text
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">Button Small With Icon</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-stroke button-small has-loader">
              <span className="loader">{' '}</span>
              Button Text
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Buttons

import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import ButtonTheme from '../../../components/ButtonXid/Button'
import Icon from '../../../components/Icons/Icon'
import InputField from '../../../components/InputField/InputField'
import ModalPopup from '../../../components/ModalPopup/ModalPopup'

const PublicHolidays = () => {
  // Modal Delete
  const [modalDeleteIsOpen, setModelDeleteIsOpen] = useState(false)
  const handleOpenDeleteModal = () => {
    setModelDeleteIsOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setModelDeleteIsOpen(false)
  }

  // Modal Edit
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false)
  const handleOpenEditModal = () => {
    setModalEditIsOpen(true)
  }
  const handleCloseEditModal = () => {
    setModalEditIsOpen(false)
  }

  // Holidays Active
  const holidaysActiveDropdown = [
    { id: 1, label: 'Yes' },
    { id: 2, label: 'No' },
  ]

  // Holiday Starts
  const [startHolidayDate, setStartHolidayDate] = useState(new Date());
  // Holidays End
  const [endHolidayDate, setEndHolidayDate] = useState(new Date());

  return (
    <>
      {/* Table Content */}
      <div className="table__wrapper">
        {/* Table Content Header */}
        <div className="table__row">
          <div className="table__col">S.No</div>
          <div className="table__col">Name</div>
          <div className="table__col">Date From</div>
          <div className="table__col">Date To</div>
          <div className="table__col">Active</div>
          <div className="table__col">Actions</div>
        </div>

      {/* Table Content Inner */}
      <div className="table__row">
        <div className="table__col">
          <div className="table__label">S.No</div>
          1
        </div>

        <div className="table__col">
          <div className="table__label">Name</div>
          Test
        </div>

        <div className="table__col">
          <div className="table__label">Date From</div>
          09-05-2023
        </div>

        <div className="table__col">
          <div className="table__label">Date To</div>
          11-05-2023
        </div>

        <div className="table__col">
          <div className="table__label">Active</div>
          Yes
        </div>

        <div className="table__col">
          <div className="table__label">Actions</div>

          <div className="table__control">
            <button
              className="table__button"
              data-tip="Edit"
              data-for="table-admin-users-edit-2"
              onClick={handleOpenEditModal}
            >
              <Icon className="edit" name="edit" />
            </button>
            <button
              className="table__button"
              data-tip="Delete"
              data-for="table-admin-users-delete-2"
              onClick={handleOpenDeleteModal}
            >
              <Icon className="trash" name="trash" />
            </button>
          </div>
          <ReactTooltip
            id="table-admin-users-edit-2"
          />
          <ReactTooltip
            id="table-admin-users-delete-2"
          />
        </div>
      </div>
      </div>

      {/* Table Footer */}
      <div className="table__foot">
        <ButtonTheme
          hasSpinnerLoading
          textLabel="Load More"
          classButton="admin-management_load button-small button-stroke has-loader"
        />
      </div>

      {/* Modal Delete */}
      <ModalPopup
        modalClass={`js-popup-sysadmin popup-small ${modalDeleteIsOpen ? "animation visible" : ""}`}
        modalTitle="Delete"
        onClickClose={handleCloseDeleteModal}
      >
        <div className="popup__text">Are you sure you want to delete?</div>
        <div className="popup__foot">
          <button className="js-popup-close popup__foot-cancel" onClick={handleCloseDeleteModal}> Cancel </button>
          <button className="button button-small">
            Delete
            <Icon className="trash" name="trash" />
          </button>
        </div>
      </ModalPopup>

      {/* Modal Edit */}
      <ModalPopup
        modalClass={`js-popup-sysadmin popup-small ${modalEditIsOpen ? "animation visible" : ""}`}
        modalTitle="Edit Public Holiday"
        onClickClose={handleCloseEditModal}
      >
        <div className="settings__fieldset">
          <InputField
            propertyName="holidayname"
            fieldName="holidayname"
            fieldType="text"
            fieldLabel="Holiday Name"
          />

          <div className="field">
            <div className="field__label">Holidays Starts</div>
            <div className="field__wrap">
              <DatePicker
                showIcon
                selected={startHolidayDate}
                onChange={(date) => setStartHolidayDate(date)}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">Holidays End</div>
            <div className="field__wrap">
              <DatePicker showIcon selected={endHolidayDate} onChange={(date) => setEndHolidayDate(date)} />
            </div>
          </div>

          <div className="field">
            <div className="field__label">Holidays Active</div>
            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                name="role"
                options={holidaysActiveDropdown}
              />
            </div>
          </div>
        </div>
        <div className="popup__foot">
          <button className="js-popup-close popup__foot-cancel" onClick={handleCloseEditModal}> Cancel </button>
          <button className="button button-small">
            Save
            <Icon className="arrow-right" name="arrow-right" />
          </button>
        </div>
      </ModalPopup>

    </>
  )
}

export default PublicHolidays

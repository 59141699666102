import React from 'react'
import cn from 'classnames'

import SpinnerLoading from '../SpinnerLoading/SpinnerLoading'

const ButtonXid = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  textLabel = '',
  classButton,
  hasSpinnerLoading,
  hasChildrenIcon,
  children,
  onClick,
} = {}) => {
  return (
    <button
      key={propertyName}
      className={
        cn(classButton)
      }
      onClick={onClick}
    >
      {hasSpinnerLoading && (
        <SpinnerLoading />
      )}
      {textLabel}

      {hasChildrenIcon && (
        children
      )}
    </button>
  )
}

export { ButtonXid as default }

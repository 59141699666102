import React from 'react'

const DSSpace = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Fields</h1>
      </div>

      <div className="ds__cards">
        <div className="ds__card">
          <div className="ds__class is-blue">Space 24</div>
          <div className="ds__card-inner">
            <div className="v-space-24 bg-color-white">{' '}</div>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-blue">Space 40</div>
          <div className="ds__card-inner">
            <div className="v-space-40 bg-color-white">{' '}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DSSpace

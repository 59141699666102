import React from 'react';
import Select from 'react-select';
import { useQuery } from '@apollo/react-hooks';
import { ACC_LEVEL_LIST } from '../../../queriesAndMutations';
// import { findByValue } from '../../../utils';

const AccessLevel = ({ values, setValues = () => {} }) => {
  const { data: { accLevelList = [] } = {}, loading: loadingAccLevelList } = useQuery(
    ACC_LEVEL_LIST,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const accessLevelOptions = accLevelList
    ? accLevelList.map((accLevel) => ({ value: accLevel.id, label: accLevel.name }))
    : [];

  const getSelectOption = (options = [], ids = []) => {
    if (options && ids && Array.isArray(options) && Array.isArray(ids)) {
      return options.filter((op) => ids.includes(op.value));
    }

    return [];
  };

  return (
    <div className="settings__project">
      <div className="settings__row ">
        <div className="field">
          <div className="field__wrap">
            {/* <Select
              isSearchable
              isClearable
              className="basic-single"
              classNamePrefix="select"
              isLoading={loadingAccLevelList}
              isDisabled={loadingAccLevelList}
              defaultValue={
                findByValue(accessLevelOptions, values.access_level_id) || accessLevelOptions[0]
              }
              name="access_level_id"
              options={accessLevelOptions}
              value={findByValue(accessLevelOptions, values.access_level_id)}
              onChange={(option) => setValues({ ...values, access_level_id: option.value })}
            /> */}
            <Select
              isMulti
              isSearchable
              isClearable
              className="basic-single"
              classNamePrefix="select"
              cacheOptions
              defaultOptions
              name="accessLevelIds"
              isLoading={loadingAccLevelList}
              isDisabled={loadingAccLevelList}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? 'grey' : 'red',
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: '#1a1a1a',
                }),
                multiValueLabel: (baseStyles) => ({
                  ...baseStyles,
                  color: '#ffffff',
                  fontSize: '100%',
                  lineHeight: '85%',
                }),
                multiValueRemove: (baseStyles) => ({
                  ...baseStyles,
                  padding: '4px',
                  borderRadius: '50%',
                }),
              }}
              value={getSelectOption(accessLevelOptions, values.access_level_ids)}
              defaultValue={getSelectOption(accessLevelOptions, values.access_level_ids)}
              onChange={(options) =>
                setValues({ ...values, access_level_ids: options.map((option) => option.value) })
              }
              options={accessLevelOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessLevel;

/* eslint-disable jsx-a11y/control-has-associated-label */
import { useMutation, useQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import SlideToggle from 'react-slide-toggle';
import ReactTooltip from 'react-tooltip';
import Icon from '../../components/Icons/Icon';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import ResetButton from '../../components/ResetButton';
import { USER_RIGHT_TYPES } from '../../constants/user';
import { useMe } from '../../myHooks';
import {
  ACC_LEVEL_LIST_WITH_DEVICES,
  RESET_DEVICE,
  UPDATE_DEVICE_TO_MASTER,
} from '../../queriesAndMutations';
import { DELETE_ACCESS_LEVEL } from '../../queriesAndMutations/personMutations';
import { PULL_USERS, PUSH_USERS } from '../../queriesAndMutations/zktecoMutation';
import { toaster } from '../../utils';
import AddDeviceToAccessLevel from './components/AddDeviceToAccessLevel';
import FormAccessLevel from './components/FormAccessLevel';
import SearchDevice from './components/SearchDevice';
import EditDeviceModal from './components/EditDeviceModal';

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const DevicesManagement = () => {
  const {
    loading: userLoading,
    data: { me: user },
    error,
  } = useMe();
  const {
    data: { accLevelListWithDevices = [] } = {},
    loading,
    refetch,
  } = useQuery(ACC_LEVEL_LIST_WITH_DEVICES, {
    fetchPolicy: 'network-only',
    pollInterval: 30000,
  });
  const classes = useStyles();
  const [pullUsers, pullUsersStatus] = useMutation(PULL_USERS);
  const [pushUsers, pushUsersStatus] = useMutation(PUSH_USERS);
  const [updateDeviceToMaster, updateDeviceToMasterStatus] = useMutation(UPDATE_DEVICE_TO_MASTER);
  const [resetDevice, resetDeviceStatus] = useMutation(RESET_DEVICE);
  const [deleteAccessLevel, deleteAccessLevelStatus] = useMutation(DELETE_ACCESS_LEVEL);

  // Modal Add Device
  const [modalAddDeviceIsOpen, setModalAddDeviceIsOpen] = useState(false);
  const [currentAccessLevelId, setCurrentAccessLevelId] = useState('');
  const [currentAccessLevel, setCurrentAccessLevel] = useState(null);
  const [mode, setMode] = useState('add');
  // Modal Add Cluster
  const [modalAddClusterIsOpen, setModalOpenClusterIsOpen] = useState(false);
  const [modalSearchDeviceIsOpen, setModalSearchDeviceIsOpen] = useState(false);

  //Modal Edit Device
  const [showEditDeviceModal, setShowEditDeviceModal] = useState(false);
  const [currentDeviceEdited, setCurrentDeviceEdited] = useState({
    deviceId: 0,
    devName: '',
    lastRequestTime: '',
  });

  const handleOpenDeviceModal = React.useCallback(
    (id) => () => {
      if (!id) return;
      setCurrentAccessLevelId(id);
      setModalAddDeviceIsOpen(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAccessLevelId, modalAddDeviceIsOpen]
  );

  const handleCloseDeviceModal = React.useCallback(() => {
    setCurrentAccessLevelId('');
    setModalAddDeviceIsOpen(false);
  }, []);

  const handleOpenClusterModal = React.useCallback(
    (accessLV = null, mode = 'add') =>
      () => {
        setModalOpenClusterIsOpen(true);
        if (accessLV) {
          setCurrentAccessLevel(accessLV);
        }
        setMode(mode);
      },
    []
  );

  const handleCloseClusterModal = React.useCallback(() => {
    setModalOpenClusterIsOpen(false);
  }, []);

  const handlePullUsers = React.useCallback(
    (devSN) => async () => {
      try {
        await pullUsers({
          variables: {
            devSN,
          },
        });

        refetch();
        toaster.success('Pull successfully');
      } catch (e) {
        console.log('Pull error', e && e.message ? e.message : e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePushUsers = React.useCallback(
    (devSN) => async () => {
      try {
        await pushUsers({
          variables: {
            devSN,
          },
        });

        refetch();
        toaster.success('Push successfully');
      } catch (e) {
        console.log('Push error', e && e.message ? e.message : e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUpdateDeviceToMaster = React.useCallback(
    (data) => async () => {
      try {
        await updateDeviceToMaster({
          variables: {
            input: {
              ...data,
            },
          },
        });

        refetch();
        toaster.success('Update master successfully');
      } catch (e) {
        console.log('Update master error', e && e.message ? e.message : e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleEditDeviceName = (deviceInfo) => async () => {
    setCurrentDeviceEdited({ ...deviceInfo });
    setShowEditDeviceModal(true);
  };

  const handleResetDevice = React.useCallback(
    (devSN) => async () => {
      try {
        await resetDevice({
          variables: {
            devSN,
          },
        });

        refetch();
        toaster.success('Reset successfully');
      } catch (e) {
        console.log('Reset error', e && e.message ? e.message : e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleDeleteAccessLevel = React.useCallback(
    (id) => async () => {
      try {
        await deleteAccessLevel({
          variables: {
            id,
          },
        });
        refetch();
        toaster.success('Delete successfully');
      } catch (e) {
        console.log('Reset error', e && e.message ? e.message : e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderStatusTag = React.useCallback((syncTime) => {
    return syncTime ? (
      <span className="status-success">Online</span>
    ) : (
      <span className="status-error">Offline</span>
    );
  }, []);

  useEffect(() => {
    if (!modalSearchDeviceIsOpen) {
      refetch();
    }
  }, [modalSearchDeviceIsOpen, refetch]);

  if (userLoading) {
    return (
      <div className={classes.circularProgressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (user.rights !== USER_RIGHT_TYPES.ADMIN && user.rights !== USER_RIGHT_TYPES.GENERAL_ADMIN) {
    return <Redirect to="/" />;
  }

  return (
    <PageDashboard enableCustomPageTitle>
      <div className="page__title-wrap">
        <div className="h3 page__title">Device management</div>

        {user && user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
          <button type="button" className="button" onClick={handleOpenClusterModal(null, 'add')}>
            <Icon className="user" name="user" size="24" /> Create Access Level
          </button>
        )}

        <button type="button" className="button" onClick={() => setModalSearchDeviceIsOpen(true)}>
          <Icon className="user" name="user" size="24" /> Search Device
        </button>
      </div>

      {accLevelListWithDevices &&
        Array.isArray(accLevelListWithDevices) &&
        accLevelListWithDevices.map((accLevel, index) => {
          const masterDevice = accLevel.deviceAccessLevels.find((device) => device.isMaster);
          const devices = accLevel.deviceAccessLevels.filter((device) => !device.isMaster);
          return (
            <SlideToggle
              key={accLevel.id + index}
              collapsed
              render={({ toggle, setCollapsibleElement, toggleState }) => (
                <div className="add-device card card__collapse">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div className="card__head">
                    <div className="title card__title">
                      {accLevel.name} ( {accLevel.id} )
                      <button
                        className="button-circle button-small"
                        data-tip="Add device"
                        data-for="add-device-1-view"
                        onClick={handleOpenDeviceModal(accLevel.id)}
                      >
                        <Icon className="add" name="add" />
                      </button>
                      <button
                        className="button-circle button-small"
                        data-tip="Edit device"
                        data-for="edit-device-1-view"
                        onClick={handleOpenClusterModal(accLevel, 'edit')}
                      >
                        <Icon className="edit" name="edit" />
                      </button>
                      <button
                        className="button-circle button-small"
                        data-tip="Remove device"
                        data-for="remove-device-1-view"
                        onClick={handleDeleteAccessLevel(accLevel.id)}
                      >
                        <Icon className="trash" name="trash" />
                      </button>
                      <ReactTooltip id="add-device-1-view" />
                    </div>
                    <button
                      type="button"
                      className={classNames(
                        'card__collapse-icon',
                        (toggleState || '').toLowerCase()
                      )}
                      onClick={toggle}
                    >
                      <Icon className="arrow-down" name="arrow-down" size="24" />
                    </button>
                  </div>

                  <div className="card__collapse-content" ref={setCollapsibleElement}>
                    <div className="card__collapse-inner">
                      <div className="add-device__wrapper">
                        <div className="add-device__table">
                          <div className="add-device__row is-head">
                            <div className="add-device__col">S.No.</div>
                            <div className="add-device__col">Device Name</div>
                            <div className="add-device__col">Function</div>
                            <div className="add-device__col">Serial No</div>
                            <div className="add-device__col">Status</div>
                            <div className="add-device__col">Action</div>
                          </div>

                          {masterDevice && masterDevice.id ? (
                            <div className="add-device__row">
                              <div className="add-device__col">
                                <div className="add-device__label">S.No.</div>1
                              </div>

                              <div className="add-device__col">
                                <div className="add-device__label">Device Name</div>

                                {masterDevice.devName}
                              </div>

                              <div className="add-device__col">
                                <div className="add-device__label">Function</div>

                                <div className="status-success">Master</div>
                              </div>

                              <div className="add-device__col">
                                <div className="add-device__label">Serial No.</div>
                                {masterDevice.devSN}
                              </div>

                              <div className="add-device__col">
                                <div className="add-device__label">Status</div>
                                {renderStatusTag(masterDevice.syncTime)}
                              </div>

                              <div className="add-device__col">
                                <button
                                  title="Push users to child device"
                                  onClick={handlePushUsers(masterDevice.devSN)}
                                  data-tip="Push users to child device"
                                  className="button button-small table__buttons--push"
                                  disabled={pushUsersStatus.loading}
                                >
                                  Push
                                </button>{' '}
                                <button
                                  onClick={handlePullUsers(masterDevice.devSN)}
                                  title="Pull users from master device"
                                  data-tip="Pull users from master device"
                                  className="button button-small table__buttons--pull"
                                  disabled={pullUsersStatus.loading}
                                >
                                  Pull
                                </button>{' '}
                                <button
                                  onClick={handleEditDeviceName(masterDevice)}
                                  title="Edit device name"
                                  data-tip="Edit device name"
                                  className="button button-small"
                                >
                                  Edit
                                </button>{' '}
                                <ResetButton
                                  handleResetDevice={handleResetDevice(masterDevice.devSN)}
                                  loading={resetDeviceStatus.loading}
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="add-device__row is-head">
                            <div className="add-device__col"> </div>
                            <div className="add-device__col">Slave Group Out</div>
                            <div className="add-device__col">Function</div>
                            <div className="add-device__col">Serial No</div>
                            <div className="add-device__col">Status</div>
                            {/* <div className="add-device__col">
                            IP Address
                          </div> */}
                            {/* <div className="add-device__col">
                            Update
                          </div> */}
                            <div className="add-device__col">Action</div>
                          </div>

                          {devices.map((device, index) => (
                            <div key={device.devSN} className="add-device__row">
                              <div className="add-device__col">
                                <div className="add-device__label">S.No.</div>

                                {index + 1}
                              </div>

                              <div className="add-device__col">
                                <div className="add-device__label">Device Name</div>

                                {device.devName}
                              </div>

                              <div className="add-device__col">
                                <div className="add-device__label">Function</div>

                                {/* Slave (Out) */}
                              </div>

                              <div className="add-device__col">
                                <div className="add-device__label">Serial No.</div>
                                {device.devSN}
                              </div>

                              <div className="add-device__col">
                                <div className="add-device__label">Status</div>
                                {renderStatusTag(device.syncTime)}
                              </div>

                              {/* <div className="add-device__col">
                              <div className="add-device__label">
                                Status
                              </div>

                              <div className="status-success">Working</div>
                            </div>

                            <div className="add-device__col">
                              <div className="add-device__label">
                                IP Address
                              </div>

                              192.168.1.68
                            </div>

                            <div className="add-device__col">
                              <div className="add-device__label">
                                Update
                              </div>
                              <div
                                className="add-device__date"
                              >
                                25th May, 2023
                              </div>
                            </div> */}

                              <div className="add-device__col">
                                <button
                                  onClick={handleUpdateDeviceToMaster({
                                    accessLevelId: device.accessLevelId,
                                    isMaster: true,
                                    deviceId: device.deviceId,
                                  })}
                                  title="Update this device to master device"
                                  data-tip="Update this device to master device"
                                  className="button button-small table__buttons--sync"
                                >
                                  Up to master
                                </button>{' '}
                                <button
                                  onClick={handleEditDeviceName(device)}
                                  title="Edit device name"
                                  data-tip="Edit device name"
                                  className="button button-small"
                                >
                                  Edit
                                </button>{' '}
                                <ResetButton
                                  handleResetDevice={handleResetDevice(device.devSN)}
                                  loading={resetDeviceStatus.loading}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          );
        })}

      {/* Modal Add Device */}

      <AddDeviceToAccessLevel
        modalAddDeviceIsOpen={modalAddDeviceIsOpen}
        handleCloseDeviceModal={handleCloseDeviceModal}
        currentAccessLevelId={currentAccessLevelId}
        refetch={refetch}
      />

      <FormAccessLevel
        modalAddClusterIsOpen={modalAddClusterIsOpen}
        handleCloseClusterModal={handleCloseClusterModal}
        refetch={refetch}
        mode={mode}
        currentAccessLevel={currentAccessLevel}
      />

      <SearchDevice
        modalSearchDeviceIsOpen={modalSearchDeviceIsOpen}
        setModalSearchDeviceIsOpen={setModalSearchDeviceIsOpen}
      />

      <EditDeviceModal
        show={showEditDeviceModal}
        onClose={() => setShowEditDeviceModal(false)}
        onUpdated={() => {
          setCurrentDeviceEdited({});
          refetch();
        }}
        data={currentDeviceEdited}
        setStates={setCurrentDeviceEdited}
      />
    </PageDashboard>
  );
};

export default DevicesManagement;

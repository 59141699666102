import gql from 'graphql-tag'

export const PULL_USERS = gql`
  mutation pullUsers($devSN: String!) {
    pullUsers(devSN: $devSN)
  }
`;

export const PUSH_USERS = gql`
  mutation pushUsers($devSN: String!) {
    pushUsers(devSN: $devSN)
  }
`;




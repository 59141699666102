import React, { useState } from 'react'
import { Link } from "react-router-dom"
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import Icon from '../../components/Icons/Icon'
import InputField from '../../components/InputField/InputField'
import PageDashboard from '../../components/PageDashboard/PageDashboard'
import SpinnerLoading from '../../components/SpinnerLoading/SpinnerLoading'

const AbsentReport = () => {
  const availableName = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Test Account' },
    { id: 3, label: 'Test new' },
    { id: 4, label: 'Test srini xid'}
  ]

  // Access Date
  const [accessDateFrom, setAccessDateFrom] = useState(new Date())

  return (
    <PageDashboard pageTitle="Absent Report">
      <div className="report-management card">
        <div className="report-management__wrapper">
          <div className="report-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row is-col-3">
                <div className="field">
                  <div className="field__label">Name</div>

                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="entry"
                      options={availableName}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Date</div>
                  <div className="field__wrap">
                    <DatePicker
                      showIcon
                      selected={accessDateFrom}
                      onChange={(date) => setAccessDateFrom(date)}
                    />
                  </div>
                </div>

                <InputField
                  propertyName="userid"
                  fieldName="userid"
                  fieldType="text"
                  fieldLabel='Card No.'
                />
              </div>
            </div>

            <div className="settings__btns is-end">
              <button className="button button-stroke button-small">
                Reset
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-small">
                Search
                <Icon className="icon-search" name="icon-search" />
              </button>
            </div>
          </div>

          <div className="v-space-40">{' '}</div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S.No</div>
              <div className="table__col">Name</div>
              <div className="table__col">Access Date</div>
              <div className="table__col">Card No.</div>
              <div className="table__col">Actions</div>
            </div>

            {/* Table Content Body */}
            <div className="table__row">
              <div className="table__col">
                <div className="table__label">S.No</div>
                1
              </div>

              <div className="table__col">
                <div className="table__label">Name</div>
                <Link to="/user-view">Test Srini XID</Link>
              </div>

              <div className="table__col">
                <div className="table__label">Access Date</div>
                31 May, 2023
              </div>

              <div className="table__col">
                <div className="table__label">Card No.</div>
                555
              </div>
              <div className="table__col">
                <div className="table__label">Action</div>
                -
              </div>
            </div>

            <div className="table__row">
              <div className="table__col">
                <div className="table__label">S.No</div>
                2
              </div>

              <div className="table__col">
                <div className="table__label">Name</div>
                <Link to="/user-view">Test Account</Link>
              </div>

              <div className="table__col">
                <div className="table__label">Access Date</div>
                31 May, 2023
              </div>

              <div className="table__col">
                <div className="table__label">Card No.</div>
                2
              </div>

              <div className="table__col">
                <div className="table__label">Action</div>
                -
              </div>
            </div>

            <div className="table__row">
              <div className="table__col">
                <div className="table__label">S.No</div>
                3
              </div>

              <div className="table__col">
                <div className="table__label">Name</div>
                <Link to="/user-view">Test new</Link>
              </div>

              <div className="table__col">
                <div className="table__label">Access Date</div>
                31 May, 2023
              </div>

              <div className="table__col">
                <div className="table__label">999</div>
                555
              </div>

              <div className="table__col">
                <div className="table__label">Action</div>
                -
              </div>
            </div>
          </div>

          <div className="table__foot">
            <button
              type="button"
              className="button-small button-stroke has-loader"
            >
              <SpinnerLoading />
              Load More
            </button>
          </div>
        </div>
      </div>
    </PageDashboard>
  )
}

export default AbsentReport

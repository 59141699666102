import React from 'react';
import InputField from '../../../components/InputField/InputField';

const ProjectEPSSSettingInformation = ({ values, setValues = () => {} }) => {
  return (
    <>
      <div className="settings__project">
        <div className="settings__row ">
          <InputField
            propertyName="epss_builder_uen"
            fieldName="epss_builder_uen"
            fieldType="text"
            fieldLabel="Builder UEN No."
            setState={setValues}
            state={values}
          />
          <InputField
            propertyName="epss_project_bp"
            fieldName="epss_project_bp"
            fieldType="text"
            fieldLabel="Project BP No."
            setState={setValues}
            state={values}
          />
          <InputField
            propertyName="epss_project_name"
            fieldName="epss_project_name"
            fieldType="text"
            fieldLabel="Project Name"
            setState={setValues}
            state={values}
          />
          <InputField
            propertyName="epss_builder_name"
            fieldName="epss_builder_name"
            fieldType="text"
            fieldLabel="Project Builder Name"
            setState={setValues}
            state={values}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectEPSSSettingInformation;

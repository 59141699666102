import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const ME = gql`
    query me {
        me {
            id
            email
            name
            birth_date
            phone
            is_active
            rights
        }
    }
`;

export const ME_INVEST_IN_BASE_CURRENCY = gql`
    query meInvestInBaseCurrency {
        meInvestInBaseCurrency {
            invest_amount,
            approved_invest,
            allocated_invest
            invest_token_without_kyc,
            invest_token,
            kyc_lvl_change,
            currency,
            current_lvl,
            currencies_contribution {
                currency
                sum
            }
        }
    }
`;

export const NEW_2FA_SECRET = gql`
    query new2FASecret {
      new2FASecret
    }
  `;

export const VIDEO_ID_ATTENDED_AUTHORIZATION = gql`
   query videoIDAttendAuthorization {
       videoIDAttendAuthorization {
           status
           id
           authorization
           scriptSrc
       }
    }
`;

export const VIDEO_SCAN_AUTHORIZATION = gql`
   query videoScanAuthorization {
       videoScanAuthorization
    }
`;

export const VIDEO_ID_UNATTENDED_AUTHORIZATION = gql`
   query videoIDUnAttendAuthorization {
       videoIDUnAttendAuthorization
    }
`;

export const VIDEO_ID_RECORDED_EXTRACTED_DATA = gql`
   query videoIDRecoredExtractedData($video_ident_id: String) {
       videoIDRecoredExtractedData(video_ident_id: $video_ident_id)
    }
`;

export const SEARCH_GENERAL = gql`
   query searchGeneral($search: String!) {
       searchGeneral(search: $search)
    }
`;

import React from 'react'
import cn from 'classnames'
import Icon from '../Icons/Icon'

const ModalPopup = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  modalClass = '',
  modalTitle = '',
  onClickClose,
  modalFullSize,
  children
} = {}) => {
  return (
    <div
      className={
        cn(
          'js-popup popup',
          modalClass
        )
      }
      key={propertyName}
    >
        <div className="js-popup-overlay popup__overlay" onClick={onClickClose}>{' '}</div>
        <div className="js-popup-wrap popup__wrap">
          {modalFullSize ? (
            <>
              <div className="popup-wide__inner">
                <div className="popup-wide__control">
                  <button className="popup-wide__close" onClick={onClickClose}>
                    <Icon className="close" name="close" />
                  </button>
                </div>

                <div className="popup-wide__wrapper">
                  <div className="popup-wide__details">
                    {children}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {modalTitle && (
                <div className="popup__title title text-color-white">{modalTitle}</div>
              )}

              <div className="popup__content">
                {children}
              </div>
              <button className="js-popup-close popup__close" onClick={onClickClose}>
                <Icon className="close" name="close"/>
              </button>
            </>
          )}
        </div>
      </div>
  )
}

export default ModalPopup

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { get, find } from 'lodash';
import client from '../../apolloClient';
import Icon from '../../components/Icons/Icon';
import InputField from '../../components/InputField/InputField';
import SelectAsync from '../../components/SelectAsync';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import SpinnerLoading from '../../components/SpinnerLoading/SpinnerLoading';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import './styles.scss';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

import {
  GET_PERSON_LIST,
  GET_PROJECT_LIST,
  GET_COMPANY_LIST,
  GET_TRADE_LIST,
} from '../../queriesAndMutations';
import { findByValue, getNationalityList, toaster } from '../../utils';
import { usePublicSettings, useMe } from '../../myHooks';
import { IMPORT_USER_FIELDS, USER_RIGHT_TYPES } from '../../constants/user';
import { IMPORT_USERS } from '../../queriesAndMutations/userMutation';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const INIT_FILTERS = {
  userName: '',
  pin: '',
  idCard: '',
  companyId: null,
  tradeId: null,
  nationality: '',
  projectId: null,
};

const UsersManagement = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const nationalityList = getNationalityList(publicSettings) || [];
  const dropdownNationality = nationalityList.map((item) => ({ value: item, label: item }));
  const nameRef = useRef(null);
  const [personList, setPersonList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [tradeList, setTradeList] = useState([]);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(INIT_FILTERS);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [forceReloadSelect, setForceReloadSelect] = useState(false);
  const [isShowUpload, setIsShowUpload] = useState(false);
  const [getPersonList, { data: { getPersonList: data } = {}, refetch, loading }] = useLazyQuery(
    GET_PERSON_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [getProjectList, { data: { getProjectList: projectListData } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const [getCompanyList, { data: { getCompanyList: companyListData } = {} }] = useLazyQuery(
    GET_COMPANY_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [getTradeList, { data: { getTradeList: tradeListData } = {} }] = useLazyQuery(
    GET_TRADE_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );
  const {
    data: { me: user },
  } = useMe();

  const dropdownBoolean = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];
  const [importUsers, { loading: importLoading, error: importError }] = useMutation(IMPORT_USERS);
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [userDataSet, setUserDataSet] = useState(null);

  const sampleData = [
    {
      pin: '2232',
      userName: 'Madison Harris 7578',
      idCard: '003-74-0440',
      companyName: 'Taylor, Lee and Villa',
      jobTitle: 'Geophysical data processor',
      designation: 'Mr.',
      tradeName: 'Ceramics designer',
      workPassNumber: '49782039',
      passCategory: 'last',
      idNumber: '475504235',
      remark: 'Evidence spring too.',
      nationality: 'United Arab Emirates',
      projectName: 'project1',
    },
  ];

  const handleFormatData = (data, format) => {
    const keys = IMPORT_USER_FIELDS;
    let formatedData = [];
    if (data?.length && Array.isArray(data) && format === 'xls') {
      formatedData = data.map((item) => {
        return Object.fromEntries(keys.map((key, index) => [key, item[index]]));
      });
    } else if (data && format === 'csv') {
      formatedData = data.map((e) => {
        return {
          pin: e['PIN'],
          userName: e['Name'],
          idCard: e['Card Number'],
          companyName: e['Company Name'],
          jobTitle: e['Job Title'],
          designation: e['Designation'],
          tradeName: e['Trade Name'],
          workPassNumber: e['Work Pass Number'],
          passCategory: e['Pass Category'],
          idNumber: e['ID Number'],
          remark: e['Remark'],
          nationality: e['Nationality'],
          projectName: e['Project Name'],
        };
      });
    }
    setFileContent(formatedData);
  };
  const readFileContent = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;

      if (file.name.endsWith('.csv')) {
        Papa.parse(data, {
          complete: (results) => {
            let modifiedData = [];
            if (results.data?.length) {
              modifiedData = results.data;
              if (results.data[results.data.length - 1]['PIN'] === '') {
                // In case last element is empty
                modifiedData.pop();
              }
            }
            handleFormatData(modifiedData, 'csv');
          },
          header: true,
        });
      } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        if (jsonData?.length) {
          // Remove first element as it's the columns name - not data
          jsonData.shift();
        }
        handleFormatData(jsonData, 'xls');
      }
    };

    if (file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      readFileContent(e.target.files[0]);
    }
  };

  const generateUserResultExport = (userData) => {
    const dataSetExport = [
      {
        columns: [
          { value: 'PIN', widthPx: 40 },
          { value: 'Name', widthPx: 120 },
          { value: 'Card Number', widthPx: 80 },
          { value: 'Company Name', widthPx: 120 },
          { value: 'Job Title', widthPx: 100 },
          { value: 'Designation', widthPx: 80 },
          { value: 'Trade Name', widthPx: 100 },
          { value: 'Work Pass Number', widthPx: 100 },
          { value: 'Pass Category', widthPx: 100 },
          { value: 'ID Number', widthPx: 80 },
          { value: 'Remark', widthPx: 100 },
          { value: 'Nationality', widthPx: 80 },
          { value: 'Project Name', widthPx: 90 },
          { value: 'Import Status', widthPx: 90 },
        ],
        data: userData.map((user) => {
          return [
            user.pin,
            user.userName,
            user.idCard,
            user.companyName,
            user.jobTitle,
            user.designation,
            user.tradeName,
            user.workPassNumber,
            user.passCategory,
            user.idNumber,
            user.remark,
            user.nationality,
            user.projectName,
            user.status,
          ];
        }),
      },
    ];

    setUserDataSet(dataSetExport);
  };

  const onResetFilter = () => {
    setPersonList([]);
    setCanLoadMore(true);
    setFilters(INIT_FILTERS);
    if (page === 0) {
      getPersonList({
        variables: {
          page,
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const allowedExtensions = /(\.csv|\.xlsx|\.xls)$/i;
      if (!allowedExtensions.exec(file.name)) {
        toaster.error('Please upload a file with .csv, .xlsx, or .xls extension.');
        return;
      }
      importUsers({
        variables: {
          input: {
            fileContent: fileContent,
          },
        },
      })
        .then((response) => {
          if (response.data && response.data.importUsers?.success) {
            toaster.success('Successfully importing users file.');
            generateUserResultExport(response.data.importUsers.data);
            onResetFilter();
          } else if (response.data && response.data.importUsers?.message) {
            toaster.error(response.data.importUsers?.message);
          } else {
            toaster.error('Import user failed, please check again or contact with your admin.');
          }
        })
        .catch((e) => console.log(e));
    } else {
      toaster.error('You need to upload a file first.');
    }
  };

  const onClickSearch = () => {
    setPersonList([]);
    setCanLoadMore(true);
    if (page === 0) {
      getPersonList({
        variables: {
          page,
          ...filters,
        },
      });
    } else {
      setPage(0);
    }
  };

  const handleOpenModalCSV = () => {
    setIsShowUpload(true);
  };

  const handleCloseModalCSV = () => {
    setIsShowUpload(false);
    setFile(null);
    setFileContent(null);
    setUserDataSet(null);
  };

  const personListLoadOptions = async (search, loadedOptions, { page }) => {
    if (search) {
      const searchResult = find(loadedOptions, (option) => {
        if (
          option.data.label.toLowerCase().includes(search.toLowerCase()) ||
          option.data.value.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (searchResult) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page,
          },
        };
      }
    }
    const result = await client.query({
      query: GET_PERSON_LIST,
      variables: {
        page: search ? 0 : page,
        pageSize: 20,
        projectId: filters.projectId > 0 ? filters.projectId : null,
        userName: search,
      },
      fetchPolicy: 'network-only',
    });
    const newOptions = get(result, 'data.getPersonList.items', []).map((person) => ({
      value: person.userName,
      label: person.userName,
    }));
    const hasMore = get(result, 'data.getPersonList.totalPages', 0) > page;

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: search ? 1 : page + 1,
      },
    };
  };

  useEffect(() => {
    getPersonList({
      variables: { page: 0 },
    });
    getProjectList();
    getCompanyList();
    getTradeList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && page >= 0) {
      getPersonList({
        variables: {
          page,
          ...filters,
        },
      });
    }

    if (data && (data.totalPages === 1 || page === data.totalPages - 1)) {
      setCanLoadMore(false);
    }

    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (data && data.items && data.items.length) {
      setPersonList([...personList, ...data.items]);
    }

    if (
      data &&
      data.totalPages &&
      (data.totalPages === 1 || (page !== 0 && page === data.totalPages - 1))
    ) {
      setCanLoadMore(false);
    }

    //eslint-disable-next-line
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (projectListData) {
      setProjectList(
        projectListData.map((project) => ({ value: project.id, label: project.name }))
      );
    }
  }, [projectListData]);

  useEffect(() => {
    if (companyListData && companyListData.items && Array.isArray(companyListData.items)) {
      setCompanyList(
        companyListData.items.map((company) => ({ value: company.id, label: company.companyName }))
      );
    }
  }, [companyListData]);

  useEffect(() => {
    if (tradeListData) {
      setTradeList(tradeListData.map((trade) => ({ value: trade.id, label: trade.name })));
    }
  }, [tradeListData]);

  useEffect(() => {
    setFilters({ ...filters, userName: '' });
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }

    setForceReloadSelect(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.projectId]);

  useEffect(() => {
    if (forceReloadSelect) {
      setTimeout(() => {
        setForceReloadSelect(false);
      }, 1000);
    }
  }, [forceReloadSelect]);

  return (
    <PageDashboard pageTitle="User Management">
      <div className="user-management card">
        {/* Card Head */}
        <div className="card__head">
          <div className="title card__title ">
            Create Users...
            <NavLink
              to="/add-user"
              className="button-circle button-small"
              data-tip="Create User"
              data-for="tooltip-create-user"
            >
              <Icon className="plus" name="plus" />
            </NavLink>
            <ReactTooltip id="tooltip-create-user" />
          </div>
        </div>

        {/* Card Body */}
        <div className="user-management__wrapper">
          <div className="user-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row is-col-3">
                {/* <InputField
                  propertyName="userName"
                  fieldName="userName"
                  fieldType="text"
                  fieldLabel="Employee Name"
                  state={filters}
                  setState={setFilters}
                /> */}

                <div className="field">
                  <div className="field__label">Employee Name</div>

                  <div className="field__wrap">
                    {forceReloadSelect ? (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={true}
                        options={[]}
                      />
                    ) : (
                      <SelectAsync
                        selectRef={nameRef}
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue=""
                        name="userName"
                        onChange={(option) =>
                          setFilters({ ...filters, userName: get(option, 'value', '') })
                        }
                        loadOptions={personListLoadOptions}
                        additional={{
                          page: 0,
                        }}
                        noOptionsMessage="No users"
                      />
                    )}
                  </div>
                </div>

                <InputField
                  propertyName="pin"
                  fieldName="pin"
                  fieldType="text"
                  fieldLabel="User ID"
                  state={filters}
                  setState={setFilters}
                />

                <InputField
                  propertyName="idCard"
                  fieldName="idCard"
                  fieldType="text"
                  fieldLabel="Card No."
                  state={filters}
                  setState={setFilters}
                />

                <div className="field">
                  <div className="field__label">Company</div>
                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      name="companyId"
                      options={companyList}
                      onChange={(option) =>
                        setFilters({ ...filters, companyId: option ? option.value : null })
                      }
                    />
                  </div>
                </div>

                {/* <InputField
                  propertyName="cardno"
                  fieldName="cardno"
                  fieldType="text"
                  fieldLabel="NRIC/FIN"
                /> */}

                <div className="field">
                  <div className="field__label">Trade</div>
                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      name="tradeId"
                      options={tradeList}
                      onChange={(option) =>
                        setFilters({ ...filters, tradeId: option ? option.value : null })
                      }
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Nationality</div>
                  <div className="field__wrap">
                    <Select
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      options={dropdownNationality}
                      name="nationality"
                      onChange={(option) =>
                        setFilters({ ...filters, nationality: option ? option.value : null })
                      }
                    />
                  </div>
                </div>

                {user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
                  <div className="field">
                    <div className="field__label">Project</div>

                    <div className="field__wrap">
                      <Select
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        name="projectId"
                        options={projectList}
                        onChange={(option) => setFilters({ ...filters, projectId: option.value })}
                      />
                    </div>
                  </div>
                )}

                <div className="field">
                  <div className="field__label">Face registered</div>
                  <div className="field__wrap">
                    <Select
                      isSearchable
                      className="basic-single"
                      classNamePrefix="select"
                      options={dropdownBoolean}
                      name="isRegisterFace"
                      onChange={(option) =>
                        setFilters({ ...filters, isRegisterFace: option ? option.value : null })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="settings__btns is-end">
              <button className="button button-stroke button-small" onClick={handleOpenModalCSV}>
                Import Users List
                <Icon className="upload" name="upload" />
              </button>

              <button className="button button-stroke button-small" onClick={onResetFilter}>
                Reset
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-small" onClick={onClickSearch}>
                Search
                <Icon className="icon-search" name="icon-search" />
              </button>
            </div>
          </div>
          <div className="v-space-40"> </div>
          <div className="v-space-40 ml-1">
            Page {get(data, 'pageIndex', 0) + 1} of {Math.max(get(data, 'totalPages', 1), 1)} |
            Found total {get(data, 'totalCount', 0)} records
          </div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S.No.</div>
              <div className="table__col">Employee Name</div>
              <div className="table__col">User ID</div>
              <div className="table__col">Card No.</div>
              <div className="table__col">Company Name</div>
              <div className="table__col">Project Name</div>
              <div className="table__col">Face registered</div>
            </div>

            {/* Table Content */}
            {personList.map((person, index) => (
              <div key={index} className="table__row">
                <div className="table__col">
                  <div className="table__label">S.No.</div>
                  {index + 1}
                </div>

                <div className="table__col">
                  <div className="table__label">Employee Name</div>
                  <NavLink to={`/user-view/${person.id}`}>{`${person.userName}`}</NavLink>
                </div>

                <div className="table__col">
                  <div className="table__label">User ID</div>
                  <NavLink to={`/user-view/${person.id}`}>{person.pin}</NavLink>
                </div>

                <div className="table__col">
                  <div className="table__label">Card No.</div>
                  <NavLink to={`/user-view/${person.id}`}>{person.idCard}</NavLink>
                </div>

                <div className="table__col">
                  <div className="table__label">Company Name</div>
                  <NavLink to={`/user-view/${person.id}`}>{person.companyName}</NavLink>
                </div>
                <div className="table__col">
                  <div className="table__label">Project Name</div>
                  <NavLink to={`/user-view/${person.id}`}>
                    {findByValue(projectList, person.projectId).label}
                  </NavLink>
                </div>

                <div className="table__col">
                  <div className="table__label">Face registered</div>
                  <NavLink to={`/user-view/${person.id}`}>
                    {person.isRegisterFace ? 'Registered' : ''}
                  </NavLink>
                </div>
              </div>
            ))}
          </div>

          {canLoadMore && (
            <div className="table__foot text-center">
              <button
                type="button"
                className="button button-stroke has-loader"
                onClick={() => setPage(page + 1)}
              >
                {loading && <SpinnerLoading />}
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
      <ModalPopup
        modalClass={`js-popup-syadmin popup-small ${isShowUpload ? 'animation visible' : ''}`}
        modalTitle="Import Users List"
        onClickClose={handleCloseModalCSV}
      >
        {userDataSet && (
          <div className="title card__title">
            The file successfully uploaded. Click here to download the result detail:
            <ExcelFile
              element={
                <button
                  className="button-circle button-small"
                  data-tip="Export to Excel"
                  data-for="export-to-excel"
                >
                  <Icon className="download" name="download" />{' '}
                </button>
              }
              filename={`User_Uploaded_${moment().format('YYYYMMDD')}`}
            >
              <ExcelSheet
                dataSet={userDataSet}
                name={`User uploaded ${moment().format('YYYYMMDD')}`}
              />
            </ExcelFile>
            <ReactTooltip id="export-to-excel" />
          </div>
        )}

        {!userDataSet && (
          <>
            <div className="settings__fieldset">
              <div className="title card__title">
                Download sample file
                <ExcelFile
                  element={
                    <button
                      className="button-circle button-small"
                      data-tip="Export to Excel"
                      data-for="export-to-excel"
                    >
                      <Icon className="download" name="download" />{' '}
                    </button>
                  }
                >
                  <ExcelSheet data={sampleData} name="Users Example">
                    <ExcelColumn label="PIN" value="pin" />
                    <ExcelColumn label="Name" value="userName" />
                    <ExcelColumn label="Card Number" value="idCard" />
                    <ExcelColumn label="Company Name" value="companyName" />
                    <ExcelColumn label="Job Title" value="jobTitle" />
                    <ExcelColumn label="Designation" value="designation" />
                    <ExcelColumn label="Trade Name" value="tradeName" />
                    <ExcelColumn label="Work Pass Number" value="workPassNumber" />
                    <ExcelColumn label="Pass Category" value="passCategory" />
                    <ExcelColumn label="ID Number" value="idNumber" />
                    <ExcelColumn label="Remark" value="remark" />
                    <ExcelColumn label="Nationality" value="nationality" />
                    <ExcelColumn label="Project Name" value="projectId" />
                  </ExcelSheet>
                </ExcelFile>
              </div>
            </div>
            <div className="settings__fieldset">
              <div className="title card__title">
                Upload users file
                <input id="file" type="file" onChange={handleFileChange} />
                <ReactTooltip id="sample-file" />
              </div>
            </div>
            <div className="popup__foot">
              <button className="js-popup-close popup__foot-cancel" onClick={handleCloseModalCSV}>
                Cancel
              </button>
              <button
                className="button button-small flex flex-row"
                data-tip="Export to Excel"
                data-for="export-to-excel"
                onClick={handleUpload}
              >
                Import
                <Icon className="upload" name="upload" />{' '}
              </button>
            </div>
          </>
        )}
      </ModalPopup>
    </PageDashboard>
  );
};

export default UsersManagement;

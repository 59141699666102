import React from 'react'
import RegisterForm from './RegisterForm'

const Register = () => {
  return (
    <div className="page page_simple">
      <div className="entry">
        <RegisterForm />
      </div>
    </div>
  );
};

export default Register;

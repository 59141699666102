import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { useLazyQuery } from '@apollo/react-hooks';
import DatePicker from 'react-datepicker';
import { get } from 'lodash';
import InputField from '../../../components/InputField/InputField';
import { GET_COMPANY_LIST, GET_TRADE_LIST, GET_PROJECT_LIST } from '../../../queriesAndMutations';
import 'react-datepicker/dist/react-datepicker.css';
import { findByValue, findByLabel, parseToDateTime, getNationalityList } from '../../../utils';
import { usePublicSettings } from '../../../myHooks';

const dropdownDesignation = [
  { id: 1, label: 'None' },
  { id: 2, label: 'Manage' },
  { id: 3, label: 'Engineer' },
  { id: 4, label: 'Foreman' },
  { id: 5, label: 'Supervisor' },
  { id: 6, label: 'General Construction Worker' },
  { id: 7, label: 'Management & Technical staff' },
  { id: 8, label: 'Safety Co-ordinator' },
  { id: 9, label: 'Driver or Operator' },
  { id: 10, label: 'Director' },
];

const dropdownAdministrator = [
  { id: '0', label: 'Normal user' },
  { id: '2', label: 'Enroller' },
  { id: '14', label: 'Super Admin' },
];

const dropdownWPCategory = [
  { id: 1, label: 'WP-C' },
  { id: 2, label: 'WP-M' },
  { id: 3, label: 'WP-O' },
  { id: 4, label: 'WP-S' },
  { id: 5, label: 'WP-C(S)' },
  { id: 6, label: 'WP-M(S)' },
  { id: 7, label: 'WP-O(S)' },
  { id: 8, label: 'WP-S(S)' },
  { id: 9, label: 'Others' },
];

// const dropdownEmployer = [
//   { id: 1, label: 'NONE' },
//   { id: 2, label: 'Xid Technologies' },
// ];

// const dropdownSkills = [
//   { id: 1, label: 'R1' },
//   { id: 2, label: 'R2' },
//   { id: 3, label: 'Others' },
// ];

// const dropdownShift = [
//   { id: 1, label: 'iokjoij' },
//   { id: 2, label: 'Morning' },
// ];

// const dropdownEnter = [
//   { id: 1, label: 'Yes' },
//   { id: 2, label: 'No' },
// ];

const EmployeeInformation = ({ values, setValues, setFileField, mode }) => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const nationalityList = getNationalityList(publicSettings) || [];
  const dropdownNationality = nationalityList.map((item) => ({ value: item, label: item }));
  // Expiry Date
  const [dateOfExpiry, setDateOfExpiry] = useState(null);
  // SOC Date
  const [socDate, setSOCDate] = useState(new Date());
  const [companyValue, setCompanyValue] = useState('');
  const [roleValue, setRoleValue] = useState('');
  const [companies, setCompanies] = useState([]);
  const [dropdownTrade, setDropdownTrade] = useState([]);
  const [dropdownProjects, setDropdownProjects] = useState([]);

  const [getCompanyList, { data: { getCompanyList: companyListRes } = {}, loading }] = useLazyQuery(
    GET_COMPANY_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [getTradeList, { data: { getTradeList: tradeList } = {} }] = useLazyQuery(GET_TRADE_LIST, {
    fetchPolicy: 'network-only',
  });
  const [getProjectList, { data: { getProjectList: projectList } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const onChangeRole = React.useCallback(
    (option) => {
      setValues({ ...values, pri: `${option.id}` });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  const onChangeCompany = useCallback(
    (option) => {
      setValues({ ...values, companyId: option.id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  useEffect(() => {
    getCompanyList({ variables: { page: 0 } });
    getTradeList();
    getProjectList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (values.endTime && typeof values.endTime === 'string') {
      setDateOfExpiry(parseToDateTime(values.endTime));
    }
  }, [values.endTime]);

  useEffect(() => {
    if (companyListRes) {
      setCompanies(
        companyListRes.items.map((item) => ({ id: item.id, label: item.companyName })) || []
      );
    }
  }, [companyListRes]);

  useEffect(() => {
    if (tradeList) {
      setDropdownTrade(tradeList.map((item) => ({ value: item.id, label: item.name })) || []);
      if (!values.tradeId) {
        setValues({ ...values, tradeId: tradeList[0].id });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeList]);

  useEffect(() => {
    if (projectList) {
      setDropdownProjects(projectList.map((item) => ({ value: item.id, label: item.name })));
    }
  }, [projectList]);

  useEffect(() => {
    if (values.pri !== '') {
      const roleOptionData = dropdownAdministrator.find((role) => role.id === values.pri);
      if (roleOptionData) {
        setRoleValue({ id: roleOptionData.id, label: roleOptionData.label });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.pri]);

  useEffect(() => {
    if (values.companyId !== '' && companies && companies.length > 0) {
      const companyOptionData = companies.find((company) => company.id === values.companyId);
      if (companyOptionData) {
        setCompanyValue(companyOptionData);
      }
    }
  }, [values.companyId, companies]);

  console.log('values', values);
  return (
    <>
      <div className="settings__fieldset">
        <div className="settings__row">
          <div className="field">
            <div className="field__label">Project*</div>
            <div className="field__wrap">
              <Select
                isSearchable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="dropdownProject"
                options={dropdownProjects}
                value={findByValue(dropdownProjects, values.projectId)}
                onChange={(option) => setValues({ ...values, projectId: option.value })}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">Company</div>
            <div className="field__wrap">
              <Select
                isSearchable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="dropdownCompany"
                options={loading ? [] : companies}
                value={companyValue}
                onChange={onChangeCompany}
              />
            </div>
          </div>

          <InputField
            propertyName="jobTitle"
            fieldName="jobTitle"
            fieldType="text"
            fieldLabel="Job Title"
            state={values}
            setState={setValues}
          />

          <div className="field">
            <div className="field__label">Nationality</div>
            <div className="field__wrap">
              <Select
                isSearchable
                className="basic-single"
                classNamePrefix="select"
                defaultValue={findByValue(dropdownNationality, values.nationality)}
                value={findByValue(dropdownNationality, values.nationality)}
                name="nationality"
                options={dropdownNationality}
                onChange={(option) => setValues({ ...values, nationality: option.value })}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">Designation</div>
            <div className="field__wrap">
              <Select
                isSearchable
                className="basic-single"
                classNamePrefix="select"
                defaultValue={findByLabel(dropdownDesignation, values.designation)}
                value={findByLabel(dropdownDesignation, values.designation)}
                name="designation"
                options={dropdownDesignation}
                onChange={(option) => setValues({ ...values, designation: option.label })}
              />
            </div>
          </div>

          <div className="field">
            <div className="field__label">Pass Expiry Date</div>
            <div className="field__wrap">
              <DatePicker
                showIcon
                selected={dateOfExpiry}
                onChange={(date) => {
                  setDateOfExpiry(date);
                  setValues({ ...values, endTime: date });
                }}
              />
            </div>
          </div>

          {dropdownTrade.length > 0 && (
            <div className="field">
              <div className="field__label">Trade</div>
              <div className="field__wrap">
                <Select
                  isSearchable
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={get(dropdownTrade, '0', null)}
                  value={findByValue(dropdownTrade, values.tradeId)}
                  name="tradeId"
                  options={dropdownTrade}
                  onChange={(option) => setValues({ ...values, tradeId: option.value })}
                />
              </div>
            </div>
          )}

          <div className="field">
            <div className="field__label">Device Operation Role</div>
            <div className="field__wrap">
              <Select
                isSearchable
                className="basic-single"
                classNamePrefix="select"
                defaultValue={roleValue}
                value={roleValue}
                name="dropdownAdministrator"
                options={dropdownAdministrator}
                onChange={onChangeRole}
              />
            </div>
          </div>

          <InputField
            propertyName="workPassNumber"
            fieldName="workPassNumber"
            fieldType="text"
            fieldLabel="Work Pass Number"
            state={values}
            setState={setValues}
          />

          <InputField
            propertyName="idNumber"
            fieldName="idNumber"
            fieldType="text"
            fieldLabel="ID Number"
            state={values}
            setState={setValues}
          />

          <div className="field">
            <div className="field__label">Pass Category</div>
            <div className="field__wrap">
              <Select
                isSearchable
                className="basic-single"
                classNamePrefix="select"
                defaultValue={findByLabel(dropdownWPCategory, values.passCategory)}
                value={findByLabel(dropdownWPCategory, values.passCategory)}
                name="passCategory"
                options={dropdownWPCategory}
                onChange={(option) => setValues({ ...values, passCategory: option.label })}
              />
            </div>
          </div>

          {/* <div className="field">
            <div className="field__label">Employeer</div>
            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="dropdownEmployer"
                options={dropdownEmployer}
              />
            </div>
          </div> */}

          {/*   <div className="field">
            <div className="field__label">SOC Date</div>
            <div className="field__wrap">
              <DatePicker showIcon selected={socDate} onChange={(date) => setSOCDate} />
            </div>
          </div> */}

          {/* <InputField
            propertyName="periodofemployment"
            fieldName="periodofemployment"
            fieldType="text"
            fieldLabel="Period of Employment"
          /> */}

          {/* <div className="field">
            <div className="field__label">Skill Level</div>
            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="dropdownSkills"
                options={dropdownSkills}
              />
            </div>
          </div> */}

          {/* <InputField
            propertyName="myecertificateno"
            fieldName="myecertificateno"
            fieldType="text"
            fieldLabel="MYE Certificate No"
          /> */}

          {/* <InputField
            propertyName="coretraderegid"
            fieldName="coretraderegid"
            fieldType="text"
            fieldLabel="Core Trade Reg ID"
          /> */}

          {/*  <div className="field">
            <div className="field__label">Shift</div>
            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="dropdownShift"
                options={dropdownShift}
              />
            </div>
          </div> */}

          {/* <div className="field">
            <div className="field__label">Entered</div>
            <div className="field__wrap">
              <Select
                isSearchable
                isClearable
                className="basic-single"
                classNamePrefix="select"
                defaultValue=""
                name="dropdownEnter"
                options={dropdownEnter}
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default EmployeeInformation;

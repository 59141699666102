import React from 'react'
import PageDashboard from '../../components/PageDashboard/PageDashboard'

const WorkingHoursReportTrade = () => {
  return (
    <PageDashboard pageTitle="Working Hours Report By Trade">
      {' '}
    </PageDashboard>
  )
}

export default WorkingHoursReportTrade

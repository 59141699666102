import React, { useState } from 'react'
import Header from './Header/Header'
import Sidebar from './Sidebar/Sidebar'

const PageDashboard = ({
  children,
  pageTitle,
  enableCustomPageTitle = false,
}) => {
  // Handle sidebar visible and invisible on mobile
  const [sidebarVisible, setSidebarVisible] = useState({
    isSidebarVisible: false
  });

  const handleSidebarVisible = () => {
    setSidebarVisible({ ...sidebarVisible, isSidebarVisible: !sidebarVisible.isSidebarVisible})
  }
  const handleSidebarInvisible = () => {
    setSidebarVisible({ isSidebarVisible: false})
  }
  return (
    <div className="page">

      <Header
        handleSidebarVisible={handleSidebarVisible}
      />

      <Sidebar
        handleSidebarInvisible={handleSidebarInvisible}
        sidebarVisible={sidebarVisible}
      />

      <div className="page__inner">
        <div className="page__container">
          {enableCustomPageTitle === true ? (
            children
          ) : (
            <>
              {pageTitle && (
                <div className="heading-h3 text-color-white page__title">{pageTitle}</div>
              )}
              {children}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PageDashboard

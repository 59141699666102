import React from 'react'
import PageDashboard from '../../components/PageDashboard/PageDashboard'

const ReportTimeAttendance = () => {
  return (
    <PageDashboard pageTitle="Report - Time Attendance">
      {' '}
    </PageDashboard>
  )
}

export default ReportTimeAttendance

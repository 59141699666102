export const INIT_NEW_PROJECT_VALUES = {
  name: '',
  epss_builder_uen: '',
  epss_project_bp: '',
  epss_project_name: '',
  epss_builder_name: '',
  access_level_id: '',
};

export const INIT_EDIT_PROJECT_VALUES = {
  ...INIT_NEW_PROJECT_VALUES,
  id: '',
};

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { NavLink, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Icon from '../../components/Icons/Icon';
import InputField from '../../components/InputField/InputField';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import SpinnerLoading from '../../components/SpinnerLoading/SpinnerLoading';
import { GET_PROJECT_LIST, DELETE_PROJECT } from '../../queriesAndMutations';
import { toaster } from '../../utils';

const INIT_FILTERS = {
  keyWord: '',
};

const ProjectsManagement = () => {
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [page, setPage] = useState(0);
  const [filters, setFilter] = useState(INIT_FILTERS);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [getProjectList, { data: { getProjectList: data } = {}, refetch, loading }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [deleteProject] = useMutation(DELETE_PROJECT);

  const onClickSearch = () => {
    setProjectList([]);
    setCanLoadMore(true);
    if (page === 0) {
      getProjectList({
        variables: {
          page,
          ...filters,
        },
      });
    } else {
      setPage(0);
    }
  };

  const onResetFilter = () => {
    setFilter(INIT_FILTERS);
    setProjectList([]);
    setCanLoadMore(true);
    if (page === 0) {
      getProjectList({
        variables: {
          page,
          keyWord: '',
        },
      });
    } else {
      setPage(0);
    }
  };

  const onClickEditProject = (id) => {
    history.push(`/project-view/${id}`);
  };

  const onClickDeleteProject = (id) => {
    deleteProject({
      variables: {
        input: {
          id: parseInt(id),
        },
      },
    })
      .then(({ data: { deleteProject: success } }) => {
        if (success) {
          toaster.success('Project deleted');
          setProjectList([]);
          refetch();
        }
      })
      .catch((e) => {
        console.error(e);
        toaster.error('Delete project failed, please try again later');
      });
  };

  useEffect(() => {
    getProjectList({
      variables: { page: 0, ...filters },
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && page >= 0) {
      getProjectList({
        variables: {
          page,
          keyWord: filters.keyWord,
        },
      });
    }

    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (data && data.length) {
      setProjectList([...projectList, ...data]);
    }

    if (data && data.length < 25) {
      setCanLoadMore(false);
    }

    //eslint-disable-next-line
  }, [JSON.stringify(data)]);

  return (
    <PageDashboard pageTitle="Projects Management">
      <div className="project-management card">
        {/* Card Head */}
        <div className="card__head">
          <div className="title card__title ">
            Create Projects
            <NavLink
              to="/add-project"
              className="button-circle button-small"
              data-tip="Create Project"
              data-for="tooltip-create-project"
            >
              <Icon className="plus" name="plus" />
            </NavLink>
            <ReactTooltip id="tooltip-create-project" />
          </div>
        </div>

        {/* Card Body */}
        <div className="project-management__wrapper">
          <div className="project-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row is-col-3">
                <InputField
                  propertyName="keyWord"
                  fieldName="keyWord"
                  fieldType="text"
                  fieldLabel="Project Name"
                  state={filters}
                  setState={setFilter}
                />
              </div>
            </div>

            <div className="settings__btns is-end">
              <button className="button button-stroke button-small" onClick={onResetFilter}>
                Reset
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-small" onClick={onClickSearch}>
                Search
                <Icon className="icon-search" name="icon-search" />
              </button>
            </div>
          </div>
          <div className="v-space-40"> </div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S.No.</div>
              <div className="table__col">Project Name</div>
              <div className="table__col--actions ">Actions</div>
            </div>

            {/* Table Content */}
            {projectList.map((project, index) => (
              <div className="table__row">
                <div className="table__col">
                  <div className="table__label">S.No.</div>
                  {index + 1}
                </div>

                <div className="table__col">
                  <div className="table__label">Project Name</div>
                  <NavLink to={`/project-view/${project.id}`}>{`${project.name}`}</NavLink>
                </div>

                <div className="table__col table__col--actions">
                  <div className="table__label">Actions</div>
                  <div className="action-buttons">
                    <button
                      type="button"
                      className="button button-small"
                      onClick={() => onClickEditProject(project.id)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="button button-small settings__button--delete --fit"
                      onClick={() => onClickDeleteProject(project.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {canLoadMore && (
            <div className="table__foot text-center">
              <button
                type="button"
                className="button button-stroke has-loader"
                onClick={() => setPage(page + 1)}
              >
                {loading && <SpinnerLoading />}
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </PageDashboard>
  );
};

export default ProjectsManagement;

import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
// Inner Components
import { useMutation } from '@apollo/react-hooks';
import { INIT_NEW_USER_VALUES } from '../../constants/user';
import { CREATE_PERSON } from '../../queriesAndMutations';
import { toaster } from '../../utils';
import EmployeeInformation from './EmployeeInformation';
import MoreInformation from './MoreInformation/MoreInformation';
import PersonalInformation from './PersonalInformation/PersonalInformation';

const UsersAdd = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollToPersonal = useRef(null);
  const scrollToEmployement = useRef(null);
  const scrollToMore = useRef(null);
  const [values, setValues] = useState(INIT_NEW_USER_VALUES);
  const history = useHistory();
  const [createPerson, createPersonStatus] = useMutation(CREATE_PERSON);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const submitCreatePerson = async () => {
    // const base64 = values.personPhoto ? await convertBase64(values.personPhoto) : ""
    if (!values.projectId) {
      toaster.error('Project ID is required.');
    }
    if (!values.pin) {
      toaster.error('User ID is required.');
    }
    if (!values.userName) {
      toaster.error('User Name is required.');
    }
    if (values.pin && values.projectId) {
      createPerson({
        variables: {
          input: {
            ...values,
            // personPhoto: base64,
            companyId: values.companyId || 0,
            tradeId: values.tradeId || 0,
            accessLevelId:
              values.accessLevelIds && values.accessLevelIds.length > 0
                ? values.accessLevelIds[0]
                : 0,
          },
        },
      })
        .then(({ data: { createPerson: success } }) => {
          if (success) {
            setValues(INIT_NEW_USER_VALUES);
            toaster.success('Person created');
            history.push('/users-management');
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const setFileField =
    (field) =>
    async ({ target: { files } }) => {
      setValues({
        ...values,
        [field]: files[0],
      });
    };

  const handleClick = (x, index) => {
    setActiveIndex(index);
    x.action();
  };

  const navigation = [
    {
      title: 'Personal Information',
      action: () => scrollToPersonal.current.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      title: 'Employement Information',
      action: () => scrollToEmployement.current.scrollIntoView({ behavior: 'smooth' }),
    },
    {
      title: 'More Information',
      action: () => scrollToMore.current.scrollIntoView({ behavior: 'smooth' }),
    },
  ];

  return (
    <PageDashboard pageTitle="Add User">
      <div className="settings">
        <div className="settings__menu">
          {navigation.map((x, index) => (
            <button
              className={cn('settings__link', { active: activeIndex === index })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>

        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToPersonal}>
                {' '}
              </div>

              <div className="title settings__title">Personal Information</div>
              <PersonalInformation
                values={values}
                setFileField={setFileField}
                setValues={setValues}
              />
            </div>
            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToEmployement}>
                {' '}
              </div>
              <div className="title settings__title">Employement Information</div>
              <EmployeeInformation
                values={values}
                setFileField={setFileField}
                setValues={setValues}
              />
            </div>
            <div className="settings__item">
              <div className="settings__anchor" ref={scrollToMore}>
                {' '}
              </div>
              <div className="title settings__title">More Information</div>
              <MoreInformation
                editorContentInit={values.remark}
                onChange={(text) => setValues({ ...values, remark: text })}
              />
            </div>
          </div>

          <button className="button button-small settings__button" onClick={submitCreatePerson}>
            Save
          </button>
        </div>
      </div>
    </PageDashboard>
  );
};

export default UsersAdd;

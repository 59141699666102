import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { get } from 'lodash';
import Icon from '../../components/Icons/Icon';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import { EPSS_REPORTS, GET_PROJECT_LIST } from '../../queriesAndMutations';
import { useLazyQuery } from '@apollo/react-hooks';
import ReactExport from 'react-export-excel';
import Select from 'react-select';
import moment from 'moment';
import { useMe } from '../../myHooks';
import { USER_RIGHT_TYPES } from '../../constants/user';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const EPPSReportsBCA = () => {
  const [startDateSearch, setStartDateSearch] = useState(moment().subtract(1, 'months').toDate());
  const [endDateSearch, setEndDateSearch] = useState(new Date());
  const [epssList, setEpssList] = useState([]);
  const [summaryReport, setSummaryReport] = useState({});
  const [grandTotalAssigned, setGrandTotalAssigned] = useState(0);
  const [grandTotalManDays, setGrandTotalManDays] = useState(0);
  // const [exportedEpssReports, setExportedEpssReports] = useState([]);
  const [exportedPJDetailEpssReports, setExportedPJDetailEpssReports] = useState([]);
  const [pjInfo, setPJInfo] = useState(null);
  const [dropdownProjects, setDropdownProjects] = useState([]);
  const [projectId, setProjectId] = useState(null);

  const {
    data: { me: user },
  } = useMe();

  const [getEPSSReports, { data: { getEPSSReports: data } = {}, refetch, loading }] = useLazyQuery(
    EPSS_REPORTS,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [getProjectList, { data: { getProjectList: projectList } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const formatDate = (date) => {
    return moment(date).format('MM/DD/YYYY');
  };

  const onClickSearch = () => {
    setEpssList([]);
    getEPSSReports({
      variables: {
        dateFrom: formatDate(startDateSearch),
        dateTo: formatDate(endDateSearch),
        projectId,
      },
    });
  };

  const getCellStyle = (col) => {
    // Customize the styling based on your conditions
    return {
      fill: {
        fgColor: col.is_married ? 'yellow' : 'green', // Example: Yellow for married, green for single
      },
      font: {
        color: 'black', // Example: Black font color
      },
    };
  };

  useEffect(() => {
    getProjectList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data.items && data.items.length && data.sum) {
      setEpssList([...data.items]);
      setSummaryReport({ ...data.sum });
    }

    if (data && data.project) {
      setPJInfo(data.project);
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const groupedEpss = {};
    const exportedEpss = [];
    let totalAssigned = 0;
    let totalMandays = 0;
    epssList.forEach((item) => {
      const category = item.category;

      if (!groupedEpss[category]) {
        groupedEpss[category] = [];
      }

      totalAssigned += item.assigned;
      totalMandays += item.manDays;

      groupedEpss[category].push(item);
    });

    setGrandTotalAssigned(totalAssigned);
    setGrandTotalManDays(totalMandays);

    const boldStyleColumn = {
      font: { sz: '11', bold: true },
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    const normalStyleColumn = {
      font: { sz: '11', bold: false },
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    const numberStyleColumn = {
      ...normalStyleColumn,
      numFmt: '0',
      alignment: { horizontal: 'right' },
    };
    const greyBG = { fill: { patternType: 'solid', fgColor: { rgb: 'D3D3D3' } } };
    const yellowBG = { fill: { patternType: 'solid', fgColor: { rgb: 'FFFFE0' } } };

    const keys = Object.keys(groupedEpss);
    if (keys.length) {
      keys.forEach((key) => {
        let totalManDays = 0;
        let totalUniqueWorkers = 0;
        let totalUniqueWorkersWPHs = 0;
        // exportedEpss.push({
        //   name: key,
        //   assigned: '',
        //   manDays: '',
        //   category: '',
        //   totalUniqueWorkersNumber: '',
        //   totalUniqueWorkersWPHs: '',
        // });
        exportedEpss.push([
          { value: key, style: { ...boldStyleColumn, ...greyBG } },
          { value: '', style: { ...numberStyleColumn, ...greyBG } },
          { value: '', style: { ...numberStyleColumn, ...greyBG } },
          { value: '', style: { ...numberStyleColumn, ...greyBG } },
        ]);
        groupedEpss[key].forEach((r) => {
          if (r.totalUniqueWorkersNumber) {
            totalUniqueWorkers += r.totalUniqueWorkersNumber;
          }
          if (r.totalUniqueWorkersWPHs) {
            totalUniqueWorkersWPHs += r.totalUniqueWorkersWPHs;
          }
          totalManDays += r.manDays;
          // exportedEpss.push(r);
          exportedEpss.push([
            { value: r.name, style: normalStyleColumn },
            { value: r.manDays + '', style: numberStyleColumn },
            { value: r.totalUniqueWorkersNumber + '', style: numberStyleColumn },
            { value: r.totalUniqueWorkersWPHs + '', style: numberStyleColumn },
          ]);
        });

        // exportedEpss.push({
        //   name: `SubTotal(${key})`,
        //   assigned: '',
        //   manDays: totalManDays,
        //   category: '',
        //   totalUniqueWorkersNumber: totalUniqueWorkers,
        //   totalUniqueWorkersWPHs: totalUniqueWorkersWPHs,
        // });

        exportedEpss.push([
          { value: `SubTotal(${key})`, style: { ...boldStyleColumn, ...yellowBG } },
          { value: totalManDays + '', style: { ...numberStyleColumn, ...yellowBG } },
          { value: totalUniqueWorkers + '', style: { ...numberStyleColumn, ...yellowBG } },
          { value: totalUniqueWorkersWPHs + '', style: { ...numberStyleColumn, ...yellowBG } },
        ]);

        // exportedEpss.push({
        //   name: '',
        //   assigned: '',
        //   manDays: '',
        //   category: '',
        //   totalUniqueWorkersNumber: '',
        //   totalUniqueWorkersWPHs: '',
        // });
        exportedEpss.push([
          { value: '', style: boldStyleColumn },
          { value: '', style: numberStyleColumn },
          { value: '', style: numberStyleColumn },
          { value: '', style: numberStyleColumn },
        ]);
      });
    }

    // const dataSetEPSSExport = [
    //   {
    //     columns: [
    //       {
    //         value: 'Submission of Monthly Manpower Usage (On-site)',
    //         widthPx: 500,
    //         style: { font: { sz: '16', bold: true, underline: true, color: { rgb: '191970' } } },
    //       },
    //       {
    //         value: '',
    //         widthPx: 300,
    //       },
    //       {
    //         value: '',
    //         widthPx: 400,
    //       },
    //       {
    //         value: '',
    //         widthPx: 400,
    //       },
    //     ],
    //     data: [],
    //   },
    //   {
    //     columns: ['', '', '', ''],
    //     data: [
    //       [
    //         {
    //           value: 'SECTION B - MONTHLY ON-SITE MANPOWER USAGE',
    //           style: { font: { sz: '14', bold: true, underline: true, color: { rgb: '191970' } } },
    //         },
    //       ],
    //       [
    //         { value: 'Trade', style: { ...boldStyleColumn, alignment: { horizontal: 'center' } } },
    //         {
    //           value: 'Manpower used (mandays)',
    //           style: { ...boldStyleColumn, alignment: { horizontal: 'center' } },
    //         },
    //         {
    //           value: 'No. of Unique Workers Deployed (Total Number)',
    //           style: { ...boldStyleColumn, alignment: { horizontal: 'center' } },
    //         },
    //         {
    //           value: 'No. of Unique Workers Deployed (Work Permit Holders only)',
    //           style: { ...boldStyleColumn, alignment: { horizontal: 'center' } },
    //         },
    //       ],
    //       ...exportedEpss,
    //     ],
    //   },
    // ];

    // setExportedEpssReports(dataSetEPSSExport);
    // const pJDetails = [
    //   {
    //     builder: 'Project BP No. (e.g. A1234-12345-2022 or E1234-12345-2022)',
    //     amount: get(pjInfo, 'epss_project_bp', ''),
    //   },
    //   {
    //     builder: 'Project Name ',
    //     amount: get(pjInfo, 'epss_project_name', ''),
    //   },
    //   {
    //     builder: 'Builder Name',
    //     amount: get(pjInfo, 'epss_builder_name', ''),
    //   },
    //   {
    //     builder: 'Month that Data is submitted for (in numerical value only, e.g. April → 4)',
    //     amount: moment(startDateSearch).month() + 1,
    //   },
    //   {
    //     builder: 'Year that Data is submitted for (in numerical value only, e.g. 2022)',
    //     amount: moment(startDateSearch).year(),
    //   },
    //   {
    //     builder: 'Total Mandays usage for the month',
    //     amount: get(summaryReport, 'sumMandays', ''),
    //   },
    //   {
    //     builder: 'Total no. of unique Work Permit Holders (WPH) deployed for the month',
    //     amount: get(summaryReport, 'sumTotalUniqueWorkersByMonth', ''),
    //   },
    //   {
    //     builder: 'Cumulative no. of unique WPH workers deployed to date',
    //     amount: get(summaryReport, 'sumTotalUniqueWorkersNumber', ''),
    //   },
    // ];
    const dataSetExport = [
      {
        columns: [
          {
            value: 'Submission of Monthly Manpower Usage (On-site)',
            widthPx: 500,
            style: { font: { sz: '16', bold: true, underline: true, color: { rgb: '191970' } } },
          },
          {
            value: '',
            widthPx: 300,
          },
          {
            value: '',
            widthPx: 400,
          },
          {
            value: '',
            widthPx: 400,
          },
        ],
        data: [],
      },
      {
        columns: ['', '', '', ''],
        data: [
          [
            {
              value: 'SECTION A - PROJECT DETAILS',
              style: { font: { sz: '14', bold: true, underline: true, color: { rgb: '191970' } } },
            },
          ],
          [
            { value: 'Builder UEN No.', style: boldStyleColumn },
            { value: get(pjInfo, 'epss_builder_uen') || '', style: normalStyleColumn },
          ],
          [
            { value: 'Project BP No.', style: boldStyleColumn },
            { value: get(pjInfo, 'epss_project_bp') || '', style: normalStyleColumn },
          ],
          [
            { value: 'Project Name', style: boldStyleColumn },
            { value: get(pjInfo, 'epss_project_name') || '', style: normalStyleColumn },
          ],
          [
            { value: 'Builder', style: boldStyleColumn },
            { value: get(pjInfo, 'epss_builder_name') || '', style: normalStyleColumn },
          ],
          [
            { value: 'Month that data is submitted for', style: boldStyleColumn },
            { value: moment(startDateSearch).month() + 1 + '', style: numberStyleColumn },
          ],
          [
            { value: 'Year that data is submitted for', style: boldStyleColumn },
            { value: moment(startDateSearch).year() + '', style: numberStyleColumn },
          ],
          [
            { value: 'Total Mandays usage for the month', style: boldStyleColumn },
            { value: get(summaryReport, 'sumMandays', '') + '', style: numberStyleColumn },
          ],
          [
            {
              value: 'Total no. of unique Work Permit Holders (WPH) deployed for the month',
              style: boldStyleColumn,
            },
            {
              value: get(summaryReport, 'sumTotalUniqueWorkersByMonth', '') + '',
              style: numberStyleColumn,
            },
          ],
          [
            {
              value: 'Cumulative no. of unique WPH workers deployed to date',
              style: boldStyleColumn,
            },
            {
              value: get(summaryReport, 'sumTotalUniqueWorkersNumber', '') + '',
              style: numberStyleColumn,
            },
          ],
        ],
      },
      {
        ySteps: 1,
        columns: ['', '', '', ''],
        data: [
          [
            {
              value: 'SECTION B - MONTHLY ON-SITE MANPOWER USAGE',
              style: { font: { sz: '14', bold: true, underline: true, color: { rgb: '191970' } } },
            },
          ],
          [
            { value: 'Trade', style: { ...boldStyleColumn, alignment: { horizontal: 'center' } } },
            {
              value: 'Manpower used (mandays)',
              style: { ...boldStyleColumn, alignment: { horizontal: 'center' } },
            },
            {
              value: 'No. of Unique Workers Deployed (Total Number)',
              style: { ...boldStyleColumn, alignment: { horizontal: 'center' } },
            },
            {
              value: 'No. of Unique Workers Deployed (Work Permit Holders only)',
              style: { ...boldStyleColumn, alignment: { horizontal: 'center' } },
            },
          ],
          ...exportedEpss,
        ],
      },
    ];
    setExportedPJDetailEpssReports(dataSetExport);
  }, [epssList, pjInfo, summaryReport, startDateSearch]);

  useEffect(() => {
    if (projectList) {
      setDropdownProjects(
        projectList.map((item) => ({ value: item.id, label: item.name })).reverse()
      );
      if (!epssList || !epssList.length) {
        getEPSSReports({
          variables: {
            page: 0,
            dateFrom: formatDate(startDateSearch),
            dateTo: formatDate(endDateSearch),
            projectId: projectList[projectList.length - 1].id,
          },
        });
      }
    }
    //eslint-disable-next-line
  }, [projectList]);

  useEffect(() => {
    if (projectId) {
      getEPSSReports({
        variables: {
          page: 0,
          dateFrom: formatDate(startDateSearch),
          dateTo: formatDate(endDateSearch),
          projectId,
        },
      });
    }
    //eslint-disable-next-line
  }, [projectId]);

  return (
    <PageDashboard pageTitle="Reports">
      <div className="report-management card">
        <div className="card__head is-spacebetween">
          <div className="title card__title">
            EPSS Report for BCA
            <ExcelFile
              element={
                <button
                  className="button-circle button-small"
                  data-tip="Export to Excel"
                  data-for="export-to-excel"
                >
                  <Icon className="download" name="download" />{' '}
                </button>
              }
              filename="EPSS_report"
            >
              <ExcelSheet
                dataSet={exportedPJDetailEpssReports}
                name={`EPSS Report ${moment(startDateSearch).format('MMM')}-${moment(
                  startDateSearch
                ).year()}`}
              />
            </ExcelFile>
            <ReactTooltip id="export-to-excel" />
          </div>

          <div className="card__nav has-control">
            {user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
              <div className="field">
                <div className="field__wrap">
                  {dropdownProjects && dropdownProjects.length > 0 && (
                    <Select
                      isSearchable
                      className="basic-single"
                      classNamePrefix="select"
                      name="dropdownProject"
                      placeholder="Select Project"
                      defaultValue={dropdownProjects[0]}
                      options={dropdownProjects}
                      onChange={(option) => setProjectId(option.value)}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="field">
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={startDateSearch}
                  onChange={(date) => setStartDateSearch(date)}
                />
              </div>
            </div>

            <div className="field">
              <div className="field__wrap">
                <DatePicker
                  showIcon
                  selected={endDateSearch}
                  onChange={(date) => setEndDateSearch(date)}
                />
              </div>
            </div>

            <button type="button" className="button" onClick={onClickSearch}>
              Search
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="report-management__wrapper">
          {/* Table */}
          <div className="table__wrapper report-management__table">
            {/* Report */}
            <div className="table__row">
              <div className="table__col">Trade Name</div>
              <div className="table__col">No. of Workers Presently assigned</div>
              <div className="table__col">Man days</div>
            </div>

            {epssList.length ? (
              epssList.map((epss) => (
                <div className="table__row" key={epss.id}>
                  <div className="table__col">
                    <div className="table__label">Trade Name</div>
                    {epss.name}
                  </div>

                  <div className="table__col">
                    <div className="table__label">No. of Workers Presently assigned</div>
                    {epss.assigned}
                  </div>

                  <div className="table__col">
                    <div className="table__label">Man days</div>
                    {epss.manDays}
                  </div>
                </div>
              ))
            ) : (
              <div className="table__row" />
            )}

            <div className="table__row last">
              <div className="table__col">
                <div className="table__label text-color-primary">Trade Name</div>
                <strong className="text-color-primary">Grand Total</strong>
              </div>

              <div className="table__col">
                <div className="table__label text-color-primary">
                  No. of Workers Presently assigned
                </div>
                <strong className="text-color-primary">{grandTotalAssigned}</strong>
              </div>

              <div className="table__col">
                <div className="table__label">Man days</div>
                <strong className="text-color-primary">{grandTotalManDays}</strong>
              </div>
            </div>
          </div>

          {/* Footer */}
          {/* <div className="table__foot">
            <ButtonTheme
              hasSpinnerLoading
              textLabel="Load More"
              classButton="button-small button-stroke has-loader"
            />
          </div> */}
        </div>
      </div>
    </PageDashboard>
  );
};

export default EPPSReportsBCA;

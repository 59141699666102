import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import client from '../../apolloClient';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import ReactExport from 'react-export-excel';
import ReactTooltip from 'react-tooltip';
import { get, find } from 'lodash';
import moment from 'moment';
import Icon from '../../components/Icons/Icon';
import InputField from '../../components/InputField/InputField';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import SelectAsync from '../../components/SelectAsync';
import { DETAIL_ACCESS } from '../../queriesAndMutations/personQuery';
import {
  GET_COMPANY_LIST,
  GET_PERSON_LIST,
  GET_PROJECT_LIST,
  // GET_TRADE_LIST
} from '../../queriesAndMutations';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import SpinnerLoading from '../../components/SpinnerLoading/SpinnerLoading';
import { useMe } from '../../myHooks';
import { USER_RIGHT_TYPES } from '../../constants/user';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const INIT_FILTERS = {
  dateFrom: new Date().setDate(new Date().getDate() - 1),
  dateTo: new Date(),
  pin: '',
  devSN: '',
  eventLog: '',
  verifyType: '',
  inOutStatus: '',
  cardNo: '',
  userName: '',
  companyId: '',
  projectId: 0,
};

const InOutFilterOption = [
  {
    value: 0,
    label: 'In',
  },
  {
    value: 1,
    label: 'Out',
  },
];

const ReportDetailedAccess = () => {
  const availabelResult = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Accept' },
    { id: 3, label: 'Reject' },
    // { id: 4, label: 'Expired' },
  ];

  // Access Date
  const [accessTransactionList, setAccessTransactionList] = useState([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const inOutRef = useRef(null);
  const resultRef = useRef(null);
  const nameRef = useRef(null);
  const accessDateFromRef = useRef(null);
  const accessDateToRef = useRef(null);
  // const verifyRef = useRef(null);
  // const eventRef = useRef(null);
  // const tradeRef = useRef(null);
  const companyRef = useRef(null);
  const projectRef = useRef(null);
  // const [tradeList, setTradeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  // const [personList, setPersonList] = useState([]);
  const [forceReloadSelect, setForceReloadSelect] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState({ ...INIT_FILTERS });
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [exportedTransactionList, setExportedTransactionList] = useState([]);
  const [exportedPage, setExportedPage] = useState(0);
  const [isShowDownload, setIsShowDownload] = useState(false);
  const [getDetailedAccess, { data: { getDetailedAccess: data } = {}, refetch, loading }] =
    useLazyQuery(DETAIL_ACCESS, {
      fetchPolicy: 'network-only',
    });
  const [
    getCompanyList,
    { data: { getCompanyList: companyListData } = {}, loading: loadingCompanyList },
  ] = useLazyQuery(GET_COMPANY_LIST, {
    fetchPolicy: 'network-only',
  });
  // const [
  //   getPersonList,
  //   { data: { getPersonList: personalData } = {}, loading: loadingPersonalData },
  // ] = useLazyQuery(GET_PERSON_LIST, {
  //   fetchPolicy: 'network-only',
  // });
  const [getProjectList, { data: { getProjectList: projectListData } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  // const [getTradeList, { data: { getTradeList: tradeListData } = {} }] = useLazyQuery(
  //   GET_TRADE_LIST,
  //   {
  //     fetchPolicy: 'network-only',
  //   }
  // );
  const {
    data: { me: user },
  } = useMe();

  const handleCloseModalCSV = () => {
    setIsShowDownload(false);
  };

  const handleOpenModalCSV = () => {
    setIsShowDownload(true);
  };

  const formatFilter = (filters) => {
    return Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== '' || filters[key] === undefined || filters[key] === null) {
        if (key === 'dateFrom' || key === 'dateTo') {
          acc[key] = moment(filters[key]).format('MM/DD/YYYY');
        } else {
          acc[key] = filters[key];
        }
      }
      return acc;
    }, {});
  };

  const onClickSearch = () => {
    setAccessTransactionList([]);
    setCanLoadMore(true);
    if (page === 0) {
      getDetailedAccess({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    } else {
      setPage(1);
    }
  };

  const onExportTransactionList = () => {
    setExportedTransactionList([]);
    handleOpenModalCSV();
    setIsLoading(true);
    /*eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]*/
    for (let i = 0; i < totalPages; i++) {
      (function (index) {
        setTimeout(() => {
          getDetailedAccess({
            variables: {
              page: index,
              pageSize: 100,
              ...formatFilter(filters),
            },
          });
          setExportedPage(index);
        }, 2000 * index); // Multiply by index to stagger the timeouts
      })(i);
    }
  };

  const onResetFilters = () => {
    setAccessTransactionList([]);
    refetch();
    setCanLoadMore(true);
    getDetailedAccess({
      variables: {
        page: 0,
        ...formatFilter({ ...INIT_FILTERS }),
      },
    });
    if (inOutRef && inOutRef.current) {
      inOutRef.current.clearValue();
    }
    // if (verifyRef && verifyRef.current) {
    //   verifyRef.current.clearValue();
    // }
    // if (eventRef && eventRef.current) {
    //   eventRef.current.clearValue();
    // }
    // if (tradeRef && tradeRef.current) {
    //   tradeRef.current.clearValue();
    // }
    if (companyRef && companyRef.current) {
      companyRef.current.clearValue();
    }
    if (resultRef && resultRef.current) {
      resultRef.current.clearValue();
    }
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }
    if (projectRef && projectRef.current) {
      projectRef.current.clearValue();
    }

    setFilters({ ...INIT_FILTERS });
  };

  const personListLoadOptions = async (search, loadedOptions, { page }) => {
    if (search) {
      const searchResult = find(loadedOptions, (option) => {
        if (
          option.data.label.toLowerCase().includes(search.toLowerCase()) ||
          option.data.value.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (searchResult) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page,
          },
        };
      }
    }
    const result = await client.query({
      query: GET_PERSON_LIST,
      variables: {
        page: search ? 0 : page,
        pageSize: 20,
        projectId: filters.projectId > 0 ? filters.projectId : null,
        userName: search,
      },
      fetchPolicy: 'network-only',
    });
    const newOptions = get(result, 'data.getPersonList.items', []).map((person) => ({
      value: person.userName,
      label: person.userName,
    }));
    const hasMore = get(result, 'data.getPersonList.totalPages', 0) > page;

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: search ? 1 : page + 1,
      },
    };
  };

  useEffect(() => {
    if (exportedPage === totalPages - 1) {
      setIsLoading(false);
    }
  }, [exportedPage, totalPages, exportedTransactionList]);

  useEffect(() => {
    if (!loading && page) {
      getDetailedAccess({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    }

    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (data && data.items && data.items.length) {
      if (!isShowDownload) {
        setAccessTransactionList([...accessTransactionList, ...data.items]);
      }
      setExportedTransactionList([...exportedTransactionList, ...data.items]);
    }

    if (data && (data.totalPages === 1 || page === data.totalPages - 1)) {
      setCanLoadMore(false);
    }

    if (data && data.totalPages) {
      setTotalPages(data.totalPages);
    }

    //eslint-disable-next-line
  }, [data]);

  // useEffect(() => {
  //   if (tradeListData) {
  //     setTradeList(tradeListData.map((trade) => ({ value: trade.id, label: trade.name })));
  //   }
  // }, [tradeListData]);

  useEffect(() => {
    if (companyListData && companyListData.items && Array.isArray(companyListData.items)) {
      setCompanyList(
        companyListData.items.map((company) => ({ value: company.id, label: company.companyName }))
      );
    }
  }, [companyListData]);

  // useEffect(() => {
  //   if (personalData && personalData.items) {
  //     setPersonList(
  //       personalData.items.map((person) => ({ value: person.userName, label: person.userName }))
  //     );
  //   }
  //   //eslint-disable-next-line
  // }, [personalData]);

  useEffect(() => {
    if (projectListData) {
      setProjectList(
        projectListData.map((project) => ({ value: project.id, label: project.name }))
      );
    }
  }, [projectListData]);

  // useEffect(() => {
  //   if (accessDateFromRef && accessDateFromRef.current) {
  //     try {
  //       accessDateFromRef.current.clear();
  //       accessDateFromRef.current.setSelected(filters.dateFrom);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   if (accessDateToRef && accessDateToRef.current) {
  //     try {
  //       accessDateToRef.current.clear();
  //       accessDateToRef.current.setSelected(filters.dateTo);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.stringify(filters)]);

  useEffect(() => {
    setFilters({ ...filters, userName: '' });
    if (nameRef && nameRef.current) {
      nameRef.current.clearValue();
    }

    setForceReloadSelect(true);

    // getPersonList({
    //   variables: {
    //     page: 0,
    //     pageSize: 10,
    //     projectId: filters.projectId,
    //   },
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.projectId]);

  useEffect(() => {
    if (forceReloadSelect) {
      setTimeout(() => {
        setForceReloadSelect(false);
      }, 1000);
    }
  }, [forceReloadSelect]);

  useEffect(() => {
    if (!accessTransactionList || !accessTransactionList.length) {
      getDetailedAccess({
        variables: {
          page: 0,
          ...formatFilter(filters),
        },
      });
    }

    if (!companyListData && getCompanyList) {
      getCompanyList({
        variables: {
          page: 0,
          keyWord: '',
        },
      });
    }

    // if (!personalData && getPersonList) {
    //   getPersonList({
    //     variables: {
    //       page: 0,
    //       pageSize: 10,
    //     },
    //   });
    // }

    if (!projectListData && getProjectList) {
      getProjectList();
    }

    // if (!tradeListData && getTradeList) {
    //   getTradeList();
    // }

    //eslint-disable-next-line
  }, []);

  return (
    <PageDashboard pageTitle="Detailed Access">
      {/* Modal Add Dropdown */}
      <ModalPopup
        modalClass={`js-popup-syadmin popup-small ${isShowDownload ? 'animation visible' : ''}`}
        modalTitle="Export To CSV"
        onClickClose={handleCloseModalCSV}
      >
        {isLoading ? (
          <div className={classes.circularProgressWrapper}>
            Please do not close this pop up.
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="settings__fieldset">
              <div className="title card__title">
                Your Excel is ready. Click here to download
                <ExcelFile
                  element={
                    <button
                      className="button-circle button-small"
                      data-tip="Export to Excel"
                      data-for="export-to-excel"
                    >
                      <Icon className="download" name="download" />{' '}
                    </button>
                  }
                >
                  <ExcelSheet data={exportedTransactionList} name="Monthly-OnSite>">
                    <ExcelColumn label="User" value="userName" />
                    <ExcelColumn label="Access Type" value="accessType" />
                    <ExcelColumn label="Access Result" value="accessResult" />
                    <ExcelColumn label="Access Date" value="time" />
                    <ExcelColumn label="Company" value="companyName" />
                    <ExcelColumn label="User ID" value="pin" />
                    <ExcelColumn label="Project" value="projectName" />
                  </ExcelSheet>
                </ExcelFile>
                <ReactTooltip id="export-to-excel" />
              </div>
            </div>
            <div className="popup__foot">
              <button className="js-popup-close popup__foot-cancel" onClick={handleCloseModalCSV}>
                Cancel
              </button>
            </div>
          </>
        )}
      </ModalPopup>
      <div className="report-management card">
        <div className="report-management__wrapper">
          <div className="report-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row is-col-3">
                {/* <div className="field">
                  <div className="field__label">Name</div>

                  <div className="field__wrap">
                    <Select
                      ref={nameRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue=""
                      name="userName"
                      onChange={(option) =>
                        setFilters({ ...filters, userName: get(option, 'value', '') })
                      }
                      options={personList}
                    />
                  </div>
                </div> */}

                <div className="field">
                  <div className="field__label">Name</div>

                  <div className="field__wrap">
                    {forceReloadSelect ? (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={true}
                        options={[]}
                      />
                    ) : (
                      <SelectAsync
                        selectRef={nameRef}
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue=""
                        name="userName"
                        onChange={(option) =>
                          setFilters({ ...filters, userName: get(option, 'value', '') })
                        }
                        loadOptions={personListLoadOptions}
                        additional={{
                          page: 0,
                        }}
                        noOptionsMessage="No users"
                      />
                    )}
                  </div>
                </div>

                <InputField
                  propertyName="pin"
                  fieldName="pin"
                  fieldType="text"
                  fieldLabel="User ID"
                  state={filters}
                  setState={setFilters}
                />

                <div className="field">
                  <div className="field__label">Access Type</div>
                  <div className="field__wrap">
                    <Select
                      ref={inOutRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      id="inOutStatus"
                      name="inOutStatus"
                      onChange={(option) => {
                        if (option && option.value !== '') {
                          setFilters({ ...filters, inOutStatus: option.value });
                        } else {
                          setFilters({ ...filters, inOutStatus: '' });
                        }
                      }}
                      options={InOutFilterOption}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Date From</div>
                  <div className="field__wrap">
                    <DatePicker
                      ref={accessDateFromRef}
                      showIcon
                      selected={get(filters, 'dateFrom', new Date())}
                      onChange={(date) => {
                        setFilters({ ...filters, dateFrom: date });
                      }}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Access Date To</div>
                  <div className="field__wrap">
                    <DatePicker
                      ref={accessDateToRef}
                      showIcon
                      selected={get(filters, 'dateTo', new Date())}
                      onChange={(date) => {
                        setFilters({ ...filters, dateTo: date });
                      }}
                    />
                  </div>
                </div>

                {/* <InputField
                  propertyName="devSN"
                  fieldName="devSN"
                  fieldType="text"
                  fieldLabel="Dev SN"
                  state={filters}
                  setState={setFilters}
                />

                <InputField
                  propertyName="cardNo"
                  fieldName="cardNo"
                  fieldType="text"
                  fieldLabel="Card No"
                  state={filters}
                  setState={setFilters}
                /> */}

                {/* <div className="field">
                  <div className="field__label">Trade</div>
                  <div className="field__wrap">
                    <Select
                      ref={tradeRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      name="tradeId"
                      options={tradeList}
                      onChange={(option) =>
                        setFilters({ ...filters, tradeId: option ? option.value : null })
                      }
                    />
                  </div>
                </div> */}

                <div className="field">
                  <div className="field__label">Result</div>
                  <div className="field__wrap">
                    <Select
                      ref={resultRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      id="resultType"
                      name="resultType"
                      onChange={(option) => {
                        if (option && option.value !== '') {
                          setFilters({ ...filters, resultType: option.label });
                        } else {
                          setFilters({ ...filters, resultType: '' });
                        }
                      }}
                      options={availabelResult}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="field__label">Company</div>
                  <div className="field__wrap">
                    <Select
                      ref={companyRef}
                      isSearchable
                      isClearable
                      className="basic-single"
                      classNamePrefix="select"
                      name="companyId"
                      options={companyList}
                      onChange={(option) =>
                        setFilters({ ...filters, companyId: option ? option.value : null })
                      }
                    />
                  </div>
                </div>

                {user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
                  <div className="field">
                    <div className="field__label">Project</div>

                    <div className="field__wrap">
                      <Select
                        ref={projectRef}
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        name="projectId"
                        options={projectList}
                        onChange={(option) =>
                          setFilters({ ...filters, projectId: get(option, 'value', 0) })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="settings__btns is-end">
              <button
                className="button button-stroke button-small"
                onClick={onExportTransactionList}
              >
                Export To Excel
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-stroke button-small" onClick={onResetFilters}>
                Reset
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-small" onClick={onClickSearch}>
                Search
                <Icon className="icon-search" name="icon-search" />
              </button>
            </div>
          </div>

          <div className="v-space-40"> </div>
          <div className="v-space-40 ml-1">
            Page {get(data, 'pageIndex', 0) + 1} of {Math.max(get(data, 'totalPages', 1), 1)} |
            Found total {get(data, 'totalCount', 0)} records
          </div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">S.No</div>
              <div className="table__col">Name</div>
              <div className="table__col">Access Type</div>
              <div className="table__col">Access Result</div>
              <div className="table__col">Access Date</div>
              <div className="table__col">Company</div>
              <div className="table__col">User ID</div>
              <div className="table__col">Project</div>
              {/* <div className="table__col">Actions</div> */}
            </div>

            {/* Table Content Body */}
            {accessTransactionList.map((trans, index) => (
              <div key={index} className="table__row">
                <div className="table__col">
                  <div className="table__label">S.No</div>
                  {index + 1}
                </div>

                <div className="table__col">
                  <div className="table__label">Name</div>
                  {trans.userName ? trans.userName : 'Unknown User'}
                </div>

                <div className="table__col">
                  <div className="table__label">Access Type</div>
                  {trans.accessType ? trans.accessType : 'Unknown Access Type'}
                </div>

                <div className="table__col">
                  <div className="table__label">Access Result</div>
                  {trans.accessResult ? trans.accessResult : 'Unknown Access Result'}
                </div>

                <div className="table__col">
                  <div className="table__label">Access Date</div>
                  {trans && trans.time
                    ? moment(trans.time).format('YYYY-MM-DD HH:mm:ss')
                    : 'Unknown Time'}
                </div>

                <div className="table__col">
                  <div className="table__label">Company</div>
                  {trans.companyName ? trans.companyName : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">User ID</div>
                  {trans.pin ? trans.pin : 'Unknown User ID'}
                </div>

                <div className="table__col">
                  <div className="table__label">Project</div>
                  {trans.projectName ? trans.projectName : ''}
                </div>
              </div>
            ))}
            {canLoadMore && (
              <div className="table__foot table__row empty text-center">
                <div className="table__col">
                  <button
                    type="button"
                    className="button button-stroke has-loader"
                    onClick={() => setPage(page + 1)}
                  >
                    {loading && <SpinnerLoading />}
                    Load More
                  </button>
                </div>
              </div>
            )}
            {accessTransactionList.length === 0 && !loading && (
              <div className="table__row empty">
                <div className="table__col">No Records Available To Show</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageDashboard>
  );
};

export default ReportDetailedAccess;

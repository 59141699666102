import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import ReactTooltip from 'react-tooltip';
import { CircularProgress } from '@material-ui/core';
import ReactExport from 'react-export-excel';
import Select from 'react-select';
import { get } from 'lodash';
import moment from 'moment';
import Icon from '../../components/Icons/Icon';
import PageDashboard from '../../components/PageDashboard/PageDashboard';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import { PASSPORT_EXPIRING_REPORT } from '../../queriesAndMutations/personQuery';
import { GET_COMPANY_LIST, GET_PROJECT_LIST } from '../../queriesAndMutations';
import { useMe } from '../../myHooks';
import { USER_RIGHT_TYPES } from '../../constants/user';
import { findByValue } from '../../utils';
import SpinnerLoading from '../../components/SpinnerLoading/SpinnerLoading';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const INIT_FILTERS = {
  pin: '',
  devSN: '',
  eventLog: '',
  verifyType: '',
  inOutStatus: '',
  cardNo: '',
  userName: '',
  companyId: '',
  projectId: '',
};

const PassportExpiringReport = () => {
  //@TODO: Still waiting to add the passport number field and the date expiry passport in user(userdevice - employee)
  // Access Date
  const [passportExpiredList, setPassportExpiredList] = useState([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState({ ...INIT_FILTERS });
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [exportedPassportExpiredList, setExportedPassportExpiredList] = useState([]);
  const [exportedPage, setExportedPage] = useState(0);
  const [isShowDownload, setIsShowDownload] = useState(false);
  const [
    getPassportExpiringReport,
    { data: { getPassportExpiringReport: data } = {}, refetch, loading },
  ] = useLazyQuery(PASSPORT_EXPIRING_REPORT, {
    fetchPolicy: 'network-only',
  });
  const [
    getCompanyList,
    { data: { getCompanyList: companyList } = {}, loading: loadingCompanyList },
  ] = useLazyQuery(GET_COMPANY_LIST, {
    fetchPolicy: 'network-only',
  });
  const [getProjectList, { data: { getProjectList: projectListData } = {} }] = useLazyQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        page: 0,
        keyWord: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const {
    data: { me: user },
  } = useMe();

  const handleCloseModalCSV = () => {
    setIsShowDownload(false);
  };

  const handleOpenModalCSV = () => {
    setIsShowDownload(true);
  };

  const formatFilter = (filters) => {
    return Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== '' || filters[key] === undefined || filters[key] === null) {
        if (key === 'dateFrom' || key === 'dateTo') {
          acc[key] = moment(filters[key]).format('MM/DD/YYYY');
        } else {
          acc[key] = filters[key];
        }
      }
      return acc;
    }, {});
  };

  const onClickSearch = () => {
    setPassportExpiredList([]);
    setCanLoadMore(true);
    if (page === 0) {
      getPassportExpiringReport({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    } else {
      setPage(1);
    }
  };

  const onExportTransactionList = () => {
    setExportedPassportExpiredList([]);
    handleOpenModalCSV();
    setIsLoading(true);
    /*eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]*/
    for (let i = 0; i < totalPages; i++) {
      (function (index) {
        setTimeout(() => {
          getPassportExpiringReport({
            variables: {
              page: index,
              pageSize: 100,
            },
          });
          setExportedPage(index);
        }, 2000 * index); // Multiply by index to stagger the timeouts
      })(i);
    }
  };

  const onResetFilters = () => {
    setPassportExpiredList([]);
    refetch();
    setCanLoadMore(true);
    setFilters({ ...INIT_FILTERS });
    getPassportExpiringReport({
      variables: {
        page: 0,
      },
    });
  };

  useEffect(() => {
    if (exportedPage === totalPages - 1) {
      setIsLoading(false);
    }
  }, [exportedPage, totalPages, exportedPassportExpiredList]);

  useEffect(() => {
    if (!loading && page) {
      getPassportExpiringReport({
        variables: {
          page,
          ...formatFilter(filters),
        },
      });
    }

    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (data && data.items && data.items.length) {
      if (!isShowDownload) {
        setPassportExpiredList([...passportExpiredList, ...data.items]);
      }
      setExportedPassportExpiredList([...exportedPassportExpiredList, ...data.items]);
    }

    if (data && data.items && data.items.length < 25) {
      setCanLoadMore(false);
    }

    if (data && data.totalPages) {
      setTotalPages(data.totalPages);
    }

    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (projectListData) {
      setProjectList(
        projectListData.map((project) => ({ value: project.id, label: project.name }))
      );
    }
  }, [projectListData]);

  useEffect(() => {
    if (!passportExpiredList || !passportExpiredList.length) {
      getPassportExpiringReport({
        variables: { page: 0 },
      });
    }

    if (!companyList && getCompanyList) {
      getCompanyList({
        variables: {
          page: 0,
          keyWord: '',
        },
      });
    }

    if (!projectListData && getProjectList) {
      getProjectList();
    }

    //eslint-disable-next-line
  }, []);

  return (
    <PageDashboard pageTitle="Passport Expiring Report">
      <ModalPopup
        modalClass={`js-popup-syadmin popup-small ${isShowDownload ? 'animation visible' : ''}`}
        modalTitle="Export To CSV"
        onClickClose={handleCloseModalCSV}
      >
        {isLoading ? (
          <div className={classes.circularProgressWrapper}>
            Please do not close this pop up.
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="settings__fieldset">
              <div className="title card__title">
                Your Excel is ready. Click here to download
                <ExcelFile
                  element={
                    <button
                      className="button-circle button-small"
                      data-tip="Export to Excel"
                      data-for="export-to-excel"
                    >
                      <Icon className="download" name="download" />{' '}
                    </button>
                  }
                >
                  <ExcelSheet data={exportedPassportExpiredList} name="Monthly-OnSite>">
                    <ExcelColumn label="User ID" value="pin" />
                    <ExcelColumn label="Name" value="userName" />
                    <ExcelColumn label="Passport No." value="workPassNumber" />
                    <ExcelColumn label="Passport Exp. Date" value="endTime" />
                  </ExcelSheet>
                </ExcelFile>
                <ReactTooltip id="export-to-excel" />
              </div>
            </div>
            <div className="popup__foot">
              <button className="js-popup-close popup__foot-cancel" onClick={handleCloseModalCSV}>
                Cancel
              </button>
            </div>
          </>
        )}
      </ModalPopup>
      <div className="report-management card">
        <div className="report-management__wrapper">
          <div className="report-management__fieldset">
            <div className="settings__fieldset">
              <div className="settings__row">
                {user.rights === USER_RIGHT_TYPES.GENERAL_ADMIN && (
                  <div className="field">
                    <div className="field__label">Project</div>

                    <div className="field__wrap">
                      <Select
                        isSearchable
                        isClearable
                        className="basic-single"
                        classNamePrefix="select"
                        name="projectId"
                        options={projectList}
                        value={findByValue(projectList, filters.projectId)}
                        onChange={(option) =>
                          setFilters({ ...filters, projectId: get(option, 'value', 0) })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="settings__btns is-end">
              <button
                className="button button-stroke button-small"
                disabled={exportedPassportExpiredList.length === 0}
                onClick={onExportTransactionList}
              >
                Export To Excel
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-stroke button-small" onClick={onResetFilters}>
                Reset
                <Icon className="repeat" name="repeat" />
              </button>
              <button className="button button-small" onClick={onClickSearch}>
                Search
                <Icon className="icon-search" name="icon-search" />
              </button>
            </div>
          </div>

          <div className="v-space-40"> </div>
          <div className="table__wrapper">
            {/* Table Content Header */}
            <div className="table__row">
              <div className="table__col">User ID</div>
              <div className="table__col">Name</div>
              {/* <div className="table__col">Work Permit No.</div> */}
              <div className="table__col">Passport No.</div>
              <div className="table__col">Passport Exp. Date</div>
              {/* <div className="table__col">Employeer</div> */}
            </div>

            {/* Table Content Body */}
            {passportExpiredList.map((employee, index) => (
              <div key={employee.id} className="table__row">
                <div className="table__col">
                  <div className="table__label">User ID</div>
                  {employee.pin ? employee.pin : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Name</div>
                  {employee.userName ? employee.userName : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Passport No.</div>
                  {employee.workPassNumber ? employee.workPassNumber : ''}
                </div>

                <div className="table__col">
                  <div className="table__label">Passport Exp. Date</div>
                  {employee && employee.endTime
                    ? moment(employee.endTime).utc(0).format('YYYY-MM-DD HH:mm:ss')
                    : ''}
                </div>
              </div>
            ))}
            {passportExpiredList &&
            Array.isArray(passportExpiredList) &&
            passportExpiredList.length ? (
              <></>
            ) : (
              <div className="table__row empty">
                <div className="table__col">No Records Available To Show</div>
              </div>
            )}
          </div>
          {canLoadMore && (
            <div className="table__foot text-center">
              <button
                type="button"
                className="button button-stroke has-loader"
                onClick={() => setPage(page + 1)}
              >
                {loading && <SpinnerLoading />}
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </PageDashboard>
  );
};

export default PassportExpiringReport;

import React from 'react'

const OtherHTMLTags = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Other HTML Tags</h1>
      </div>

      <div className="ds__cards">
        <div className="ds__card">
          <div className="ds__class is-purple">All Paragraph</div>
          <div className="ds__card-inner">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">All Links</div>
          <div className="ds__card-inner">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
            <a href="#">Text Links</a>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">All Ordered Lists</div>
          <div className="ds__card-inner">
            <ol>
              <li>Sample text is being used as a placeholder for real text that is normally present.</li>
              <li>Sample text is being used as a placeholder for real text that is normally present.</li>
              <li>Sample text is being used as a placeholder for real text that is normally present.</li>
            </ol>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">All Unordered Lists</div>
          <div className="ds__card-inner">
            <ul>
              <li>Sample text is being used as a placeholder for real text that is normally present.</li>
              <li>Sample text is being used as a placeholder for real text that is normally present.</li>
              <li>Sample text is being used as a placeholder for real text that is normally present.</li>
            </ul>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-purple">All Quotes</div>
          <div className="ds__card-inner">
            <blockquote>Sample text is being used as a placeholder for real text that is normally present. Sample text helps you understand how real text may look on your website.</blockquote>
          </div>
        </div>
      </div>
    </>
  )
}

export default OtherHTMLTags

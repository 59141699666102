import React from 'react'

const HeadingStyles = () => {
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">HTML Heading Styles</h1>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.heading-display__large</div>
        <div className="ds__card-inner">
          <div className="heading-display__large">Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.heading-h1</div>
        <div className="ds__card-inner">
          <div className="heading-h1">Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.heading-h2</div>
        <div className="ds__card-inner">
          <div className="heading-h2">Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.heading-h3</div>
        <div className="ds__card-inner">
          <div className="heading-h3">Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.heading-h4</div>
        <div className="ds__card-inner">
          <div className="heading-h4">Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.heading-h5</div>
        <div className="ds__card-inner">
          <div className="heading-h5">Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</div>
        </div>
      </div>

      <div className="ds__card">
        <div className="ds__class is-blue">.heading-h6</div>
        <div className="ds__card-inner">
          <div className="heading-h6">Sample text helps you understand how real text may look. Sample text is being used as a placeholder.</div>
        </div>
      </div>
    </>
  )
}

export default HeadingStyles

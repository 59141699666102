import React, { useState } from 'react'
import Icon from '../../../components/Icons/Icon'
import ModalPopup from '../../../components/ModalPopup/ModalPopup'

const DSModal = () => {
  const [modalSmallIsOpen, setModalSmallIsOpen] = useState(false)
  const handleModalSmallIsOpen = () => {
    setModalSmallIsOpen(true)
  }
  const handleModalSmallIsClose = () => {
    setModalSmallIsOpen(false)
  }

  const [modalMediumIsOpen, setModalMediumIsOpen] = useState(false)
  const handleModalIsMediumOpen = () => {
    setModalMediumIsOpen(true)
  }
  const handleModalIsMediumClose = () => {
    setModalMediumIsOpen(false)
  }

  const [modalWideIsOpen, setModalWideIsOpen] = useState(false)
  const handleModalIsWideOpen = () => {
    setModalWideIsOpen(true)
  }
  const handleModalIsWideClose = () => {
    setModalWideIsOpen(false)
  }
  return (
    <>
      <div className="ds__card-heading">
        <h1 className="heading-display__large">Modal</h1>
      </div>

      <div className="ds__cards is-col-3">
        <div className="ds__card">
          <div className="ds__class is-blue">Modal Small</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-small" onClick={handleModalSmallIsOpen}>
              Click to show modal
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-blue">Modal Medium</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-small" onClick={handleModalIsMediumOpen}>
              Click to show modal
            </button>
          </div>
        </div>

        <div className="ds__card">
          <div className="ds__class is-blue">Modal Wide</div>
          <div className="ds__card-inner text-align-center">
            <button type="button" className="button button-small" onClick={handleModalIsWideOpen}>
              Click to show modal
            </button>
          </div>
        </div>
      </div>

      <ModalPopup
        modalClass={`popup-small ${modalSmallIsOpen ? "animation visible" : ""}`}
        modalTitle='Title Modal Small'
        onClickClose={handleModalSmallIsClose}
      >
        <div className="popup__text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur iure veniam ad mollitia laboriosam assumenda quo culpa architecto maiores quibusdam quam velit aperiam, perspiciatis unde? A commodi suscipit eum dicta?</div>
        <div className="popup__foot">
          <button type="button" className="button button-small" onClick={handleModalSmallIsClose}>
            Close
            <Icon className="arrow-right" name="arrow-right" />
          </button>
        </div>
      </ModalPopup>

      <ModalPopup
        modalClass={`popup-medium ${modalMediumIsOpen ? "animation visible" : ""}`}
        modalTitle='Title Modal Medium'
        onClickClose={handleModalIsMediumClose}
      >
        <div className="popup__text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur iure veniam ad mollitia laboriosam assumenda quo culpa architecto maiores quibusdam quam velit aperiam, perspiciatis unde? A commodi suscipit eum dicta?</div>
        <div className="popup__foot">
          <button type="button" className="button button-small" onClick={handleModalIsMediumClose}>
            Close
            <Icon className="arrow-right" name="arrow-right" />
          </button>
        </div>
      </ModalPopup>

      <ModalPopup
        modalClass={`popup-wide ${modalWideIsOpen ? "animation visible" : ""}`}
        onClickClose={handleModalIsWideClose}
      >
        <div className="heading-h4 popup-wide__title">Title Modal Wide</div>
        <div className="popup__text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur iure veniam ad mollitia laboriosam assumenda quo culpa architecto maiores quibusdam quam velit aperiam, perspiciatis unde? A commodi suscipit eum dicta?</div>
        <div className="popup__foot">
          <button type="button" className="button button-small" onClick={handleModalIsWideClose}>
            Close
            <Icon className="arrow-right" name="arrow-right" />
          </button>
        </div>
      </ModalPopup>
    </>
  )
}

export default DSModal

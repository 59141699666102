import gql from 'graphql-tag';

export const GET_PROJECT_LIST = gql`
  query getProjectList($page: Int, $keyWord: String) {
    getProjectList(page: $page, keyWord: $keyWord)
  }
`;

export const GET_PROJECT = gql`
  query getProject($id: Int) {
    getProject(id: $id)
  }
`;
